import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components'
import { mainStore, MainStateProvider } from '../store/MainStore'
import styles from '../constants'
import { MdEmail} from 'react-icons/md';
import { FaInstagram} from 'react-icons/fa';

const Container = styled.div`

    position: absolute;
    top: 0px;
    z-index: 17;
    left: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    transition: all 0.5s ease;
    &:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0px;
        left: 0px;
        background: black;
        opacity: 1;
        z-index: -1;
    }
`

const Heading = styled.div`
    height: 25%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
`

const ButtonsContainer = styled.div`
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; 
    color: white;
`

const BottomButton = styled.div`
    display: flex;
    max-width: 50%;
    width: 100px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: white;
    opacity: 0.7;
    &:hover {
        opacity: 1;
    }
`


const Buttons = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 18px;
    align-items: center; 
    opacity: 0.7;
    &:hover {
        opacity: 1;
    }
`



interface propTypes {
    menuSetter: any
    setArtist: any
}


function MenuPage(props: propTypes) {
    const {state, dispatch} = useContext(mainStore)
    const [open, setOpen] = useState(false)

    let timer1:any = null
    useEffect(() => {
        setOpen(state.menuOpen)
    }, [state.menuOpen])

    useEffect(() => {
        return () => {
            clearTimeout(timer1)
      
        }
    }, [])

    return (
        <Container
            style={{
                transform: open ? 'translateY(0%)' : 'translateY(-100%)',
            }}
        >
            <Heading>
                <img 
                    src={`${state.photoUrl}art/logo2.jpg`} 
                    style={{height: 'calc(100% - 40px)'}}
                />
            </Heading>

            <ButtonsContainer
                style={{
                    flexDirection: state.mobileOpen ? 'column' : 'row'

                }}
            >
                <Buttons
                    style={{
                        width: state.mobileOpen ? '20%' : '10%',
                        height: state.mobileOpen ? '20%' : 'auto',

                    }}
                    onClick={homeClicked}
                >
                    HOME
                </Buttons>

                <Buttons
                    style={{
                        width: state.mobileOpen ? '20%' : '10%',
                        height: state.mobileOpen ? '20%' : 'auto',

                    }}
                    onClick={() => openArtistType('jewelry')}

                >
                    JD
                </Buttons>
           
                <Buttons
                    style={{
                        width: state.mobileOpen ? '20%' : '10%',
                        height: state.mobileOpen ? '20%' : 'auto',

                    }}
                    onClick={() => openArtistType('fine')}
                > 
                    FA
                </Buttons>
            
                <Buttons
                    style={{
                        width: state.mobileOpen ? '20%' : '10%',
                        height: state.mobileOpen ? '20%' : 'auto',

                    }}
                    onClick={() => openArtistType('visual')}
                >
                    VCD
                </Buttons>

            </ButtonsContainer>

            <Heading>
                <BottomButton
                    onClick={emailOpener}
                >
                    <MdEmail />

                </BottomButton>
                <BottomButton
                    onClick={instaOpener}
                >
                    <FaInstagram />
                    
                </BottomButton>
            </Heading>

            
        </Container>
    );

    function emailOpener() {
        window.location.assign('mailto:'+state.email);
    }

    function instaOpener() {
        window.open(state.instaUrl);
    }

    function openArtistType(type: string) {
        props.setArtist('', type)
        closeMenu()
    }

    function homeClicked() {
        props.setArtist('', '')
        closeMenu()
    }

    function closeMenu() {
        timer1 = setTimeout(() => {
            props.menuSetter(false)
        }, 100)
    }
}

export default MenuPage;