import React, { useEffect, useLayoutEffect, useRef, useState, useContext } from 'react';
import styled from 'styled-components'
import { mainStore, MainStateProvider } from '../store/MainStore'
import styles from '../constants'
import Padding from '../components/Padding'
import MainContent from './MainContent'



import ArtistsContainer  from '../components/ArtistsContainer'
import ArtistsInfoContainer  from '../components/ArtistsInfoContainer'

import BigPhotoPage from './BigPhotoPage';
import MenuPage from './MenuPage';

const Container = styled.div`
    position: relative;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    overflow: hidden;

`

const ContentContainer = styled.div`
    position: relative;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    overflow: hidden;
`


//    scroll-snap-type: y mandatory;

const height  = window.innerHeight
const width  = window.innerWidth

interface propTypes {

}


function MainPage(props: propTypes) {
    const {state, dispatch} = useContext(mainStore)
    const [mouseX, setMouseX] = useState(0)
    const [scrollPerc, setScrollPerc] = useState(0)

    const [mouseY, setMouseY] = useState(0)
    const [contentScroll, setContentScroll] = useState(0)
    const [oldY, setOldY] = useState(0)

    const [width, setWidth] = useState(0)

    useLayoutEffect(() => {
        window.addEventListener('resize', resizeItem);
        resizeItem()


        return(() => {
            window.removeEventListener('resize', resizeItem)
        })
    }, [])

    const onContentScroll = (scroll: number): void => {
        setContentScroll(scroll)
        recalculate()
    }

    return (
        <Container
            id="container"
            onMouseMove={calculateMousePerc}
        >
            

             

            
            
            <Padding 
                menuSetter={setMenuOpen}
                setArtist={setArtist}
                goToTop={goToTop}
            >
               <ContentContainer>
                   
                    <MainContent 
                        width={width}
                        mouseX={mouseX}
                        scrollPerc={scrollPerc}
                        mouseY={mouseY}
                        setArtist={setArtist}
                        onContentScroll={onContentScroll}
                        contentScroll={contentScroll}
                    />
                    
                    <ArtistsContainer 
                        setArtist={setArtist}
                        width={width}
                    />
                    
                    <ArtistsInfoContainer 
                    
                    />
                    
                    <MenuPage 
                        menuSetter={setMenuOpen}
                        setArtist={setArtist}
                    /> 
                   
                </ContentContainer>
                
            </Padding>

            <BigPhotoPage />

        </Container>
    );

    function resizeItem() {
        const element = document.getElementById("container");
        if(element) {
            setWidth(element.clientWidth)
        }
    }

    function recalculate() {
        if(!state.artistOpen && !state.artistType && contentScroll < (state.height * 1.1)) {

            let newScroll = ((contentScroll)/height) * 100

            setScrollPerc( newScroll )
        }
    }

    function calculateMousePerc(e:any) {
        if(!state.artistOpen && !state.artistType && contentScroll < (state.height * 1.1) ) {

            let newX = ((e.clientX)/width) * 100

            let newY = ((e.clientY)/height) * 100
         
            if(Math.abs(mouseX - newX) > 1 )    {
                setMouseX( newX)
            }
            if(Math.abs(mouseY - newY) > 1 )    {
                setMouseY( newY)
            }
        }
    }
    

    

    function goToTop() {
        //console.log('hello')
        let content = document.getElementById('scrollElement')
        //console.log(content)
        if(content) {
            content.scrollTo({top: 0, left: 0, behavior: 'smooth' });
            onContentScroll(0)
            setArtist('', '')
        }
    }

    function setArtist(artist: string, artistType: string) {
        dispatch({
            type: 'setArtist',
            artistOpen: artist,
            artistType: artistType
        })
    }

    function setMenuOpen(val: boolean) {
        dispatch({
            type: 'setMenu',
            menuOpen: val
        })
    }
}

export default MainPage;