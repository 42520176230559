import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components'
import { mainStore, MainStateProvider } from '../store/MainStore'
import Grid from '../components/Grid';
import styles from '../constants';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`

const Selector = styled.div`
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    fontWeight: regular;
`

const InfoContainerContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    align-items: flex-start;
    transition: all 0.3s ease;
`

const SelectorButton = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: 18px;
`

const ViewAllContainer = styled.div`
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
`

const ViewAllButton = styled.div`
    padding: 0px 10px;
    font-size: 18px;
    cursor: pointer;
`

interface propTypes {
    setArtist: any
    width: number
}
function AboutPage(props: propTypes) {
    const {state, dispatch} = useContext(mainStore)
    const [selected, setSelected] = useState("all")

    const [selectedPhotos, setSelectedPhotos] = useState<any[]>([])

    const [visibleItems, setVisibleItems] = useState<number>(0)
    const [viewAll, setViewAll] = useState<boolean>(false)

    const [timer, setTimer] = useState<any>(null)
    const [blinking, setBlinking] = useState(false)



    useEffect(() => {
        selectPictures('all')

        return () => {
            if(timer) {
                clearTimeout(timer)
            }
        }
    }, [])



    return (
        <Container
            style={{
                height: 'auto'
            }}
        >
            <Selector
                style={{
                }}
            >
                <SelectorButton
                    style={{
                        color: selected === 'all' ? styles.pink : styles.black,
                        width: state.mobileOpen ? '25%' : '10%',
                    }}
                    onClick={() => selectPictures('all')}
                >
                    ALL
                </SelectorButton>

                <SelectorButton
                    style={{
                        color: selected === 'jewelry' ? styles.pink : styles.black,
                        width: state.mobileOpen ? '25%' : '10%',
                    }}
                    onClick={() => selectPictures('jewelry')}
                >
                    JD
                </SelectorButton>

                <SelectorButton
                    style={{
                        color: selected === 'fine' ? styles.pink : styles.black,
                        width: state.mobileOpen ? '25%' : '10%',
                    }}
                    onClick={() => selectPictures('fine')}
                >
                    FA
                </SelectorButton>

                <SelectorButton
                    style={{
                        color: selected === 'visual' ? styles.pink : styles.black,
                        width: state.mobileOpen ? '25%' : '10%',
                    }}
                    onClick={() => selectPictures('visual')}
                >
                    VCD
                </SelectorButton>
            </Selector>

      

            
            <InfoContainerContainer
                style={{
                    position: 'relative',
                    width: '100%',
                    height: viewAll ? (state.mobileOpen ? props.width/2 * Math.ceil(visibleItems/2) : props.width/4 * Math.ceil(visibleItems/4) ) : (state.mobileOpen ? props.width/2 * 4 : props.width/4 * 2)
                }}
            >
                
                <Grid 
                    width={props.width}
                    profile={false}
                    columns={state.mobileOpen ? 2 : 4}
                    clicked={gridClicked}
                    elements={selectedPhotos}
                />

                
                
            </InfoContainerContainer>
            <ViewAllContainer>
                <ViewAllButton onClick={() => setViewAll(!viewAll)}>{viewAll ? "LESS" : "MORE"}</ViewAllButton>
            </ViewAllContainer>
            
        </Container>
    );

    function selectPictures(sel:string) {
        let newSelectedPhotos:any[] = []

        let blank:any[] = []
        let newPreview:any[] = blank.concat(state.leanArtists)
        //console.log(state.previewPhotos)
        newPreview.forEach((prop:any) => { 
            let newProp = Object.assign({}, prop)
            newProp["noDisplay"] = false
            if(sel === "all")
                newSelectedPhotos.push(newProp) 
            else if(sel === newProp.type)  {
                newSelectedPhotos.push(newProp)
            }
            else {
                newProp.noDisplay = true
                newSelectedPhotos.push(newProp) 
            }

        })
        if(sel !== selected) {
            setBlinking(true)
            let timer = setTimeout( ()=> {
                setBlinking(false)
            }, 300 )

            setTimer(timer)
        }

        setSelected(sel)
        setSelectedPhotos(newSelectedPhotos)
        let visible = 0
        newSelectedPhotos.forEach((prop:any, index:number) => {
            if(!prop.noDisplay)
                visible++
        })
        setVisibleItems(visible)
    }

    function gridClicked(artist: any) {
        props.setArtist(artist.name, '')
    }
}

export default AboutPage;