import React, {createContext, useReducer, Dispatch} from 'react';


interface State {
    photoUrl: string
    localUrl: string
    apiUrl: string

    height: number
    email: string
    instaUrl: string

    mobileOpen: boolean
    menuOpen: boolean

    fullPadding: number
    mobilePadding: number
    browser: string

    artistType: string
    artistOpen: string

    previewPhotos: any[]
    leanArtists: any[]
    allArtists: any[]

    artistBigIndex: number
    photoBigIndex: number
    
}

interface StateContextType {
    state: State,
    dispatch: Dispatch<any>
}

const photoUrl = 'https://gradex2021.s3.af-south-1.amazonaws.com/'
const localUrl = process.env.PUBLIC_URL

const initialState = {
    photoUrl: photoUrl,
    localUrl: localUrl,

    apiUrl: 'http://localhost:8000',
    height: window.innerHeight,
    email: 'erindanjohn@gmail.com',
    instaUrl: 'https://www.instagram.com/gradex_2021/',

    mobileOpen: false,
    menuOpen: false,

    fullPadding: 40,
    mobilePadding: 20,
    browser: '',

    artistType: '',
    artistOpen: '',

    previewPhotos: [
        {
            profileUrl: photoUrl + 'art/test1.jpg',
            name: 'Allingham Emily',
            type: 'jewelry',
            fixHeight: true
        },
        {
            profileUrl: photoUrl + 'art/test2.jpg',
            name: 'Allingham Emily',
            type: 'jewelry',
            fixHeight: true
        },
        {
            profileUrl: photoUrl + 'art/test3.jpg',
            name: 'Allingham Emily',
            type: 'jewelry',
            fixHeight: false
        },
        {
            profileUrl: photoUrl + 'art/test4.jpg',
            name: 'Allingham Emily',
            type: 'jewelry',
            fixHeight: true
        },
        {
            profileUrl: photoUrl + 'art/test5.jpg',
            name: 'Allingham Emily',
            type: 'jewelry',
            fixHeight: true
        },
        {
            profileUrl: photoUrl + 'art/test6.jpg',
            name: 'Allingham Emily',
            type: 'fine',
            fixHeight: true
        },
        {
            profileUrl: photoUrl + 'art/test7.jpg',
            name: 'Allingham Emily',
            type: 'fine',
            fixHeight: false
        },
        {
            profileUrl: photoUrl + 'art/test8.jpg',
            name: 'Allingham Emily',
            type: 'visual',
            fixHeight: false
        },
        {
            profileUrl: photoUrl + 'art/test1.jpg',
            name: 'Allingham Emily',
            type: 'jewelry',
            fixHeight: true
        },
        {
            profileUrl: photoUrl + 'art/test2.jpg',
            name: 'Allingham Emily',
            type: 'jewelry',
            fixHeight: true
        },
        {
            profileUrl: photoUrl + 'art/test3.jpg',
            name: 'Allingham Emily',
            type: 'jewelry',
            fixHeight: false
        },
        {
            profileUrl: photoUrl + 'art/test4.jpg',
            name: 'Allingham Emily',
            type: 'jewelry',
            fixHeight: true
        },
        {
            profileUrl: photoUrl + 'art/test5.jpg',
            name: 'Allingham Emily',
            type: 'jewelry',
            fixHeight: true
        },
        {
            profileUrl: photoUrl + 'art/test6.jpg',
            name: 'Allingham Emily',
            type: 'fine',
            fixHeight: true
        },
        {
            profileUrl: photoUrl + 'art/test7.jpg',
            name: 'Allingham Emily',
            type: 'fine',
            fixHeight: false
        },
        {
            profileUrl: photoUrl + 'art/test8.jpg',
            name: 'Allingham Emily',
            type: 'visual',
            fixHeight: false
        },
    ],
    leanArtists: [],

    allArtists: [],

    artistBigIndex: -1,
    photoBigIndex: -1,

};


const mainStore = createContext<StateContextType>({state: initialState, dispatch: () => {}});
const { Provider } = mainStore;

const MainStateProvider = ( { children }: any ) => {

    const [state, dispatch] = useReducer((state: State, action: any) => {
        switch(action.type) {
        case 'setHeight':
            return {
                ...state,
                height: action.height
            };
        case 'setBigPhoto':
            return {
                ...state,
                artistBigIndex: action.artistBigIndex,
                photoBigIndex: action.photoBigIndex,
            };
        case 'setArtists':
            return {
                ...state,
                allArtists: action.allArtists,
                leanArtists: action.leanArtists
            };
        case 'setPreviewPhotos':
            return {
                ...state,
                previewPhotos: action.previewPhotos
            };
        case 'setMenu':
            
            return {
                ...state,
                menuOpen: action.menuOpen
            };
        case 'setBrowser':
        
            return {
                ...state,
                browser: action.browser
            };

        case 'setMobileOpen':
            return {
                ...state,
                mobileOpen: action.mobileOpen
            };

        case 'setArtist':
        
            return {
                ...state,
                artistOpen: action.artistOpen,
                artistType: action.artistType,
            };
        default:
            throw new Error();
        };
    }, initialState);

    const value = { state, dispatch };

    return <Provider value={value}>{children}</Provider>;
};

export { mainStore, MainStateProvider, initialState }