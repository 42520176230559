import React, { useEffect, useContext, useState, useLayoutEffect } from 'react';
import styled from 'styled-components'
import { mainStore, MainStateProvider } from '../store/MainStore'
import Eyes from '../components/Eyes';
import styles from '../constants'
import SlideShow from '../components/SlideShow'
const Container = styled.div`
    width: 100%;
    scroll-snap-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Content = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: white;
    background: #ffffff;
   
`

/*
     &:before {
        content: "";
        position: absolute;
        top: 0px;
        height: 100%;
        width: 100%;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0.76;
        background-image: url(https://gradex2021.s3.af-south-1.amazonaws.com/art/slideshowtemp.jpeg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    };
*/

const TimerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-family: Arial;
    color: ${styles.headingColor};
`

const TimerComponent = styled.div`
    height: 100%;
    width: 25%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const TimerNumber = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 223;
`

const TimerText = styled.div`
    height: 50px;
    font-size: 20px;
    font-weight: normal;
`

const Photos = styled.img`
    height: 100%;
    width: 100%;
    display: inline-block;
    object-fit: cover;
`


interface propTypes {
    mouseY: any
    mouseX: any
    scroll: number
    scrollPerc: number
}



function FirstPage(props: propTypes) {
    const {state, dispatch} = useContext(mainStore)
    const [slideIndex, setSlideIndex] = useState(0)

    let photos:any[] = [
        state.localUrl + "art/" + 'FA_Allingham_3 - Emily Phila.jpg',
        state.localUrl + "art/" + 'FA_Scholtz_2 - Nina Scholtz.jpg',
        state.localUrl + "art/" + 'FINEART_Hwang_2 - Shinae Hwang.jpg',

        state.localUrl + "art/" + 'VCD_DREYER_2 - Jordy Dreyer.jpg',
        state.localUrl + "art/" + 'VCD_Johnston_8 - Edge Graphics.jpg',
        state.localUrl + "art/" + 'VCD_Verster_4 - Mix Verster.jpg',

        state.localUrl + "art/" + 'JD_vanderMerwe_7 - Lee van der Merwe.jpg',
        state.localUrl + "art/" + 'JD_Willemien Bruwer_8 - miekie.jpg',
        state.localUrl + "art/" + 'JD_Kaplan_10 - Tor Kaplan.jpg'


    ]
    let timer1:any = null

    useEffect(() => {
        timer1 = setTimeout(() => changeSlide(), 3000)
        return () => {
            clearInterval(timer1)
        }
    }, [slideIndex])

    const changeSlide = () => { 
        if(slideIndex === (photos.length - 1)) {
            setSlideIndex(0)
        }
        else {
            let newIndex = slideIndex + 1
            setSlideIndex(newIndex)
        }
       
    }
    

    return (
        <Container
            style={{
                height: '100%',
            }}
        >


            <Content
                style={{
                    fontSize: state.mobileOpen ? '40px' : '120px',
                }}
            >
                <SlideShow
                    photos={
                        photos.map((prop:any, key: number) => { 
                            return <Photos key={key} src={prop} />
                        })
                    }
                    index={slideIndex}
                    browser={state.browser}
                />



                

                <div
                    style={{
                        position: 'absolute',
                        bottom: '-50px',
                        zIndex: 10
                    }}
                >
                    { props.scroll < (state.height * 1.1) &&
                    <Eyes 
                        mouseX={props.mouseX}
                        mouseY={props.mouseY}
                        height={state.height}
                        browser={state.browser}
                        mobileOpen={state.mobileOpen}
                        scrollPerc={props.scrollPerc}
                    />
                    }
                </div>

                

                
                {/*
                <div>STELLENBOSCH</div>
                <div>GRADEX '21</div>
                */}
            </Content>

        </Container>
    );
}

export default FirstPage;