import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components'

const Slide = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
`

interface propTypes {
    openIndex: number
    previousIndex: number
    index: number
    slidesLength: number
    open: boolean
    children: any
    menuOpen: boolean
    browser: string
}

type pos = "left" | 'center' | "right"
function SlideContainer(props: propTypes) {
  
   // const [position, setPosition] = useState<pos>('left')
    //const [transitioning, setTransitioning] = useState(false)
    //const [zIndexHigh, setZIndexHigh] = useState(false)

    let position = 'left'
    let transitioning = false
    let zIndexHigh = false

        if(props.open && props.openIndex === props.index || props.index ===  props.openIndex - 1 || (props.openIndex === 0 && props.index === (props.slidesLength-1) )  || props.index ===  props.openIndex + 1 || (props.openIndex === (props.slidesLength - 1) && props.index === 0 )) 
            transitioning = true
        else
            transitioning = (false)

        if(props.previousIndex === props.index) {
            zIndexHigh = (true)
        }
        
        

        if(props.openIndex === props.index) {
            position = ('center')
        }
        else if( !(props.openIndex === (props.slidesLength-1) && props.index === 0 ) && (props.index < props.openIndex || (props.openIndex === 0 && props.index === (props.slidesLength-1) ) ) )  {
            position = ('left')
        }
        else {
            position = ('right')
        }

   

    return (

        <Slide
            style={{ 
                transition: transitioning && !props.menuOpen  ? 'transform 0.7s ease' : '0s',
                transform: position === 'center' ? 'translateX(0%)' :  position === 'left' ? 'translateX(-100%)' : 'translateX(100%)',
                zIndex: position === 'center' ? 4 :  transitioning ?  zIndexHigh ? 3 : 2 : 1,
                opacity: transitioning ? 1 : 0,

            }}
        >
            {props.children}
        </Slide>
                

    );
}

export default SlideContainer;