import React, { useEffect, useRef, useState, useContext } from 'react';
import styled from 'styled-components'
import { mainStore, MainStateProvider } from '../store/MainStore'
import styles from '../constants'
import MenuIcon from '../components/MenuIcon';
import logo from '../elements/logo.png'
import { MdEmail, MdClose} from 'react-icons/md';
import { FaInstagram} from 'react-icons/fa';

const Container = styled.div`
    flex-grow: 0;
    height: 100%;
    width: 100%;
    display: grid;
    height: 100%;
    width: 100%;
    overflow: hidden;
    grid-template-areas:
        'left top right'
        'left content right'
        'left bottom right';
    grid-template-rows: 40px auto 40px;

`
const Top = styled.div`
    grid-area: top;
    position: relative;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
`

const Bottom = styled.div`
    grid-area: bottom;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
`

const Left = styled.div`
    grid-area: left;
    background: white;
    fontSize: 15px;
    justify-ontent: center;
    flex-grow: 1;
    align-items: center;
    writing-mode: vertical-lr;
    text-orientation: mixed;
    transition: all 0.2s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
`

const Right = styled.div`
    grid-area: right;
    background: white;
    fontSize: 15px;
    justify-ontent: center;
    flex-grow: 1;
    align-items: center;
    writing-mode: vertical-lr;
    text-orientation: mixed;
    transition: all 0.2s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
`

const Content = styled.div`
    grid-area: content;
    background: ${styles.background};
    overflow: hidden;
`

interface propTypes {
    menuSetter: any
    setArtist: any
    goToTop: any
    children: any
}

function Padding(props: propTypes) {
    const {state, dispatch} = useContext(mainStore)
    const [debounceTime, setDebounceTime] = useState(0)


    return (
        <Container
            style={{

                gridTemplateColumns: state.mobileOpen ? '40px calc(100% - 80px) 40px' : '40px calc(100% - 80px) 40px'
            }}

        >

            <Top>
                
                   
                        
                        
                        <div style={{
                            height: '100%', 
                            display: 'flex',
                            width: '50%',
                            alignItems: 'center',
                            opacity: !state.menuOpen && (state.artistOpen || state.artistType) ? 0 : 1,
                            transition: 'all 0.3s ease',
                        }}>
                            <img 
                                src={logo} 
                                style={{
                                    height: '70%',
                                }}
                            />

                            
                        </div>

                        <div style={{
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%', 
                                left: '0px',
                                top: '0px',
                                opacity: !state.menuOpen && (state.artistOpen || state.artistType) ? 1 : 0,
                                cursor: !state.menuOpen && (state.artistOpen || state.artistType) ? 'pointer' : 'default',
                                transition: 'all 0.3s ease',
                        }}
                            onClick={goBackArtist}
                        >
                                <MdClose 
                                    style={{fontSize: '30px'}}
                                />
                        </div>
                
                

                <div 
                    style={{
                        width: '40px',
                        height: '100%', 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}

                    onClick={() => props.menuSetter(!state.menuOpen)}
                    >
                    <MenuIcon
                        mobileOpen={state.mobileOpen} open={state.menuOpen} color={styles.headingColor}
                    />
                </div>
            </Top>

            <Bottom>
                <div 
                    style={{
                        width: '33.33%',
                        cursor: 'default',
                        height: '100%',
                        display: 'flex',
                    }}
                >
                    <div 
                        style={{
                            height: '100%', 
                            display: 'flex', 
                            justifyContent: 'flex-start', 
                            alignItems: 'center',
                            cursor: 'pointer',
                            fontSize: '1.5em',
                            flex: 0,
                        }}

                        onClick={emailOpener}
                    >
                        <MdEmail />
                    </div>

                    <div 
                        style={{
                            height: '100%', 
                            display: 'flex', 
                            justifyContent: 'flex-start', 
                            alignItems: 'center',
                            cursor: 'pointer',
                            fontSize: '1.5em',
                            flex: 0,
                            marginLeft: '10px'
                        }}

                        onClick={instaOpener}
                    >
                        <FaInstagram />
                    </div>


                </div>

                <div 
                    style={{
                        height: '100%', 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        cursor: 'pointer',
                        fontSize: '1em'
                    }}
                    onClick={props.goToTop}
                >
                    TO TOP
                </div>

                <div 
                    style={{
                        width: '33.33%',
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >

                    <div 
                        style={{
                            cursor: 'default',
                            fontSize: state.mobileOpen ? '0.4em' : '0.7em'
                        }}
                    >
                        Copyright © 2021 GradeX
                    </div>
                </div>

            </Bottom>



            <Left
                onClick={() => previousArtist()}
                style={{
                    cursor: !state.menuOpen && (state.artistOpen || state.artistType) ? 'pointer' : 'default',
                    opacity: !state.menuOpen && (state.artistOpen || state.artistType) ? 1 : 0,
                }}
            >
                previous
            </Left>

            <Right
                onClick={() => nextArtist()}
                style={{
                    cursor: !state.menuOpen && (state.artistOpen || state.artistType) ? 'pointer' : 'default',
                    opacity: !state.menuOpen && (state.artistOpen || state.artistType) ? 1 : 0,
                }}
            >
                next
            </Right>

            <Content>
                {
                    props.children
                }
            </Content>


        </Container>
    );

    function emailOpener() {
        window.location.assign('mailto:'+state.email);
    }

    function instaOpener() {
        window.open(state.instaUrl);
    }

    function goBackArtist() {
        if(state.artistOpen === '') {
            props.setArtist('', '')
        }

        else {
            props.setArtist('', state.artistType)
        }
    }

    function previousArtist() {
        let time = new Date().getTime()
        let debounce = time - debounceTime

        let debounceing = 1000
        if(state.browser === 'saf')
            debounceing = 1000
        if(state.artistOpen === '') {

            if(debounce > debounceing) {
                if(state.artistType === 'jewelry')  
                    props.setArtist('', 'visual')

                if(state.artistType === 'fine')  
                    props.setArtist('', 'jewelry')

                if(state.artistType === 'visual')  
                    props.setArtist('', 'fine')

                setDebounceTime(time)

            }

        }

        else {
            console.log(debounce)
            if(debounce > debounceing) {

                state.leanArtists.forEach((el:any, index: number) => {
                    if(el.name === state.artistOpen) {
                        let newIndex = index - 1
                        if(index === 0 ) {
                            newIndex = state.leanArtists.length - 1
                        }

                        props.setArtist(state.leanArtists[newIndex].name, state.artistType)
                        setDebounceTime(time)
                    }
                })
                
            }
        }
    }

    function nextArtist() {
        let time = new Date().getTime()
        let debounce = time - debounceTime

        let debounceing = 1000
        if(state.browser === 'saf')
            debounceing = 1000
        if(state.artistOpen === '') {

            if(debounce > debounceing) {
                if(state.artistType === 'jewelry')  
                    props.setArtist('', 'fine')

                if(state.artistType === 'fine')  
                    props.setArtist('', 'visual')

                if(state.artistType === 'visual')  
                    props.setArtist('', 'jewelry')

                setDebounceTime(time)

            }

        }

        else {
            console.log(debounce)
            if(debounce > debounceing) {

                state.leanArtists.forEach((el:any, index: number) => {
                    if(el.name === state.artistOpen) {
                        let newIndex = index + 1
                        if(index >= (state.allArtists.length - 1) ) {
                            newIndex = 0
                        }

                        props.setArtist(state.leanArtists[newIndex].name, state.artistType)
                        setDebounceTime(time)
                    }
                })
                
            }
        }
    }

}

export default Padding;