import React, {Suspense, useEffect, useState, useContext} from 'react';
import logo from './logo.svg';
import './App.css';
import styled from 'styled-components'
import { mainStore, MainStateProvider } from './store/MainStore'
import artists from './info/artistArray_ErinEdited'
import styles from './constants';
import MainPage from './pages/MainPage'

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const CoverPage = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 20;
  transition: all 0.3s ease;
`

function App() {

  return (
    <MainStateProvider>


          <AppWrapper />

    </MainStateProvider >
  );
}

export default App;


function AppWrapper() {
  
  const {state, dispatch} = useContext(mainStore)
  const [artistsProcessed, setArtistsProcessed] = useState(false)

  const [loading, setLoading] = useState(true)
  const [loadingAnimated, setLoadingAnimated] = useState(true)

  useEffect( ()  => {
    let timer1:any = null
    let timer2:any = null

    timer1 = setTimeout( () => setLoading(false), 3000 )
    timer2 = setTimeout( () => setLoadingAnimated(false), 3300 )

    processArtists()

    resizeFunction()

    window.addEventListener('resize', resizeFunction);

    function setBrowser(type: any) {
      dispatch({
        type: 'setBrowser',
        browser: type
      })

    }

    if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
      setBrowser('op')
    }
    else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
      setBrowser('chr')
    }
    else if(navigator.userAgent.indexOf("Safari") != -1) {
      setBrowser('saf')
    }
    else if(navigator.userAgent.indexOf("Firefox") != -1 ) {
      setBrowser('fir')
    }
    else if((navigator.userAgent.indexOf("MSIE") != -1 )) {
      setBrowser('ie')
    }  


    return () => {
      clearTimeout(timer1)
      clearTimeout(timer2)

      window.removeEventListener("resize", resizeFunction);
    }


  }, [])

  return (
    <Container>

      {
        artistsProcessed &&
          <MainPage 

          />  
      }
      {
        loadingAnimated &&
        <CoverPage
          style={{
            opacity: loading ? 1 : 0
          }}
        >
          <img src="logo.png" alt="loader" className="img-loader" />
          <h1>loading</h1>
        </CoverPage>
      } 
           

    </Container>
  );

  function resizeFunction() {
    // change width from the state object
    if (window.innerWidth >= 960) {
      dispatch({type: 'setMobileOpen', mobileOpen: false});
      
    }
    else {
      dispatch({type: 'setMobileOpen', mobileOpen: true});
      
    }

    let newHeight = window.innerHeight
    if(state.height !== newHeight) {
      dispatch({
        type: 'setHeight',
        height: newHeight
      })
    }
  }

  async function processArtists() {
    let leanArtists:any[] = []
    let allArtists:any[] = []

    for(let i = 0; i < artists.length; i++) {
    
      let prop = artists[i]

      prop.profileUrl = state.localUrl + "profile/" + prop.profileUrl

      prop.previewedUrl = state.localUrl + "art/" + prop.previewedUrl

      let newPhotos:any = []

      prop.photos.forEach((ph, index) => {
        let isVideo = false
        let url = state.localUrl + "art/" + ph.photoUrl
        if(ph.hasOwnProperty('isVideo')) {
          if(ph.isVideo) {
            url = ph.photoUrl
          }
          isVideo = ph.isVideo || false
        }
        let newPhoto = {
          photoUrl: url,
          caption: ph.caption,
          price: ph.price,
          isVideo: isVideo
        }
        newPhotos.push(newPhoto)
      })

      prop.photos = newPhotos

      if(prop.color === 'pink')  
        prop.color = styles.pink
      else
        prop.color = styles.red
      

            
      let newLean = {
        name: prop.name,
        type: prop.type,
        color: prop.color,
        problem: prop.problem,
        profileUrl: prop.profileUrl,
        tempProfileUrl: prop.tempProfileUrl,

        previewedUrl: prop.previewedUrl,
        tempPreviewUrl: prop.tempPreviewedUrl
      }
            
      allArtists.push(prop)
      leanArtists.push(newLean)
      
    }

    dispatch({
      type: 'setArtists',
      allArtists: allArtists,
      leanArtists: leanArtists
    })
    setArtistsProcessed(true)
  }
}

