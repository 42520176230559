import { lchown } from 'fs';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components'
import styles from '../constants';

const Container = styled.div`
    display: flex;
    align-items: center;
`

const EyeContainer = styled.div`
    position: relative;
    height: 100%;
    width: calc(100% / 5);
    background: ${styles.background};
    overflow: hidden;
    -webkit-overflow: hidden;

    -webkit-transform: translate3d(0, 0, 0);
    moz-transform: scale(1); 
    -o-transform: scale(1);
    -ms-transform: scale(1); 

`

const EyeBalls = styled.div`
    position: absolute;
    background: black;
    transition: all 0.1s ease;
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    -ms-transition: all 0.1s ease;
`

const EyeContainerPadding = styled.div`
    width: calc(100% / 5);
    background: transparent;
`

interface propTypes {
    mouseX: number
    mouseY: number
    height: number
    browser: string
    mobileOpen: boolean
    scrollPerc: number
}
function Eyes(props: propTypes) {

    const ContainerHeight = 100
    const ContainerWidth = 500

    const EyeBallSize = ContainerHeight * 2/3

    const EyeContainerRef = useRef<HTMLDivElement>(null)

    const [containerMiddleX, setContainerMiddleX] = useState(0)
    const [containerMiddleY, setContainerMiddleY] = useState(0)

    const [eyesX, setEyesX] = useState(0)
    const [eyesY, setEyesY] = useState(0)
    const [squint, setSquint] = useState(0)

    useEffect(() => {
        let eyeRef = EyeContainerRef.current
        if(eyeRef) {
            let rect = eyeRef.getBoundingClientRect()
            setContainerMiddleX(rect.x + (ContainerHeight/2) )
            setContainerMiddleY(( rect.y / props.height * 100 ) / 2)

        }
    }, [])

    useEffect(() => {
        if(props.mobileOpen) {
            setEyesX( (ContainerHeight * 0/5)  - (EyeBallSize/2))
            setEyesY( (ContainerHeight * 0.5) -  (EyeBallSize/2))
            setSquint(0)
        }
        else {
            
            let weight = 0
            let x = Math.abs(props.mouseX - 50)
            let y = Math.abs((props.mouseY + props.scrollPerc) - 90)

            y = y / 2
            
            let dist = Math.sqrt( ( x * x ) + ( y * y ) )

            if(dist < 10)
                weight += 10 - dist

            setSquint(weight)

            
            setEyesX( (ContainerHeight * props.mouseX/100)  - (EyeBallSize/2))

            setEyesY( (ContainerHeight/2 * (props.mouseY/100 + props.scrollPerc/100) ) -  (EyeBallSize/2))
        }

    }, [props.mouseX, props.mouseY, props.scrollPerc])

    return (
        <Container
            style={{
                height: ContainerHeight,
                width: ContainerWidth
            }}
            ref={EyeContainerRef}
        >
            <EyeContainerPadding />

            <EyeContainer
                style={{
                    borderRadius: `${ContainerWidth/2}px`
                }}
            >
                <EyeBalls 
                    style={{
                        left: '0px',
                        top: '0px',
                        transform: `translate(${eyesX + (5*squint)}px, ${eyesY}px)`, //x, y

                        height: `${EyeBallSize}px`,
                        width: `${EyeBallSize}px`,
                        borderRadius: `${EyeBallSize/2}px`,
                        willChange: 'transform'
                    }}
                />
            </EyeContainer>


            <EyeContainerPadding />

            <EyeContainer
                style={{
                    borderRadius: `${ContainerWidth/2}px`
                }}
            >
                <EyeBalls 
                    style={{
                        left: '0px',
                        top: '0px',
                        transform: `translate(${eyesX - (5*squint)}px, ${eyesY}px)`, //x, y
                        height: `${EyeBallSize}px`,
                        width: `${EyeBallSize}px`,
                        borderRadius: `${EyeBallSize/2}px`,
                        willChange: 'transform'

                    }}
                />
            </EyeContainer>

            <EyeContainerPadding />

        </Container>
    );
}

export default Eyes;