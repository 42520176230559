import React, { useEffect, useRef, useState, useContext } from 'react';
import styled from 'styled-components'
import styles from '../constants';
import { mainStore } from '../store/MainStore';
import Grid from '../components/Grid';

const Container = styled.div`
    height: 100%;
    top: 0px;
    width: 100%;
    top: 0px;
    background: ${styles.background};
    overflow-y: auto;
    flex-shrink: 0;
`

const Heading = styled.div`
    height: 250px;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing:5px;
    font-weight: bold;
    color: ${styles.black}
`

const GridStart = styled.div`
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    color: ${styles.black}
`
const GridStartText = styled.div`
    height: 100%;
    width: calc(100% - 40px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
`


interface propTypes {
    width: number
}
function VisualArtists(props: propTypes) {
    const {state, dispatch} = useContext(mainStore)
    const [artists, setArtists] = useState<any[]>([])

    useEffect(() => {
        let newArtists:any[] = []
        state.leanArtists.forEach((prop:any) => { 
            if(prop.type==="visual")
                newArtists.push(prop)
        })

        setArtists(newArtists)
        //console.log(newArtists)
    },[state.leanArtists])
    
    return (
        <Container
            style={{
    
            }}
        >
            <Heading
                style={{
                    fontSize: state.mobileOpen ? '1em': '2.5em'
                }}
            >
                Visual Communication Design
            </Heading>
           
            <Grid
                customStart={
                    <GridStart
                    >

                        <GridStartText
                            style={{
                                fontSize: state.mobileOpen ? '0.5em' : '0.7em',
                            }}
                        >
                            Throughout the course of 2021, each Visual Communication Design graduate developed an interactive communication design platform addressing a specific social, cultural, environmental, technological, and/or economic issue identified by them in their current local context. Their work demonstrates critical and innovative multimodal problem-solving and has already had positive societal impact. We are proud to send these graduates into the world and will follow their careers with interest.
                        </GridStartText>
                    </GridStart>
                }
                width={props.width}
                profile={true}
                columns={state.mobileOpen ? 2 : 4}
                elements={artists}
                clicked={artistClicked}
            />
           

        </Container>
    );

    function artistClicked(artist:any) {
        dispatch({
            type: 'setArtist',
            artistOpen: artist.name,
            artistType: state.artistType
        })
    }

}

export default VisualArtists;