import React, { useEffect, useRef, useState, useContext } from 'react';
import styled from 'styled-components'
import styles from '../constants';
import { mainStore } from '../store/MainStore';

import { MdEmail, MdPhone, MdClose, MdBook, MdLanguage } from 'react-icons/md';
import { FaInstagram} from 'react-icons/fa';
import ReactMarkdown from 'react-markdown'
import { LazyLoadImage, trackWindowScroll} from 'react-lazy-load-image-component';
import "./ArtistInfoPage.css"

import Vimeo from '@u-wave/react-vimeo'

const Container = styled.div`
    height: 100%;
    width: 100%;
    top: 0px;
    background: ${styles.background};
    overflow-y: auto;
    overflow-x: hidden;
    flex-shrink: 0;

`

const InfoContainer = styled.div`
    width: 100%;
    top: 0px;
    display: flex;
    flex-shrink: 0;
`

const ProfileImageContainer = styled.div`
    position: relative;
    top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
`
const ProfileImage = styled.img`
    height: 100%;
    top: 0px;
    display: flex;
    flex-shrink: 0;
    border: 0px;
`

const DetailsContainer = styled.div`
    width: 50%;
    top: 0px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
`

const NameContainer = styled.h2`
    width: calc(100% - 20px);
    height: 25%;
    top: 0px;

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
    flex-shrink: 0;
    font-size: 40px;
    color: white;
`

const ProblemContainer = styled.div`
    width: calc(100% - 20px);
    height: 15%;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    font-size: 1.5em;
    color: white;
    opacity: 0.6;
    font-weight: bold;
`

const SocialsContainer = styled.div`
    width: calc(100% - 20px);
    height: 15%;
    top: 0px;
    display: flex;
    flex-shrink: 0;
    font-size: 25px;
    color: white;
    opacity: 0.6;
    font-weight: bold;
`

const SocialsButton = styled.div`
    margin-right: 20px;
    cursor: pointer;
    
`

const DescriptionContainer = styled.div`
    width: calc(100% - 40px);
    top: 0px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    font-size: 0.9em;
    color: white;
    line-height: 1.8em;
`


const ArtContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`

const DoubleContainer = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

const PriceContainer = styled.div`
    position: absolute;
    top: 5px;
    right: 0px;
    padding: 5px 10px;
    background: black;
    border-radius: 5px 0px 0px 5px;
    font-size: 0.8em;
    z-index: 2;
    color: white;
    opacity: 0;
    transition: all 0.3s ease;
`

const Content = styled.div`
    position: relative;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    &:hover ${PriceContainer} {
        opacity: 1;
    }
`


const PhotoBottom = styled.div`
    width: calc(100% - 20px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7em;
    margin-top: 5px;
    margin-bottom: 10px;
`





interface propTypes {
    artist: any
    open: boolean
    index: number
}
const ArtistInfo = (props: propTypes) => {
    const {state, dispatch} = useContext(mainStore)
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {

        let timer:any = null;
        if(props.open && !isOpen) {
            timer = setTimeout(() => {
                setIsOpen(true)
            }, 500)
        }

        if(!props.open && isOpen) {
            setIsOpen(false)
            setPhotoBigIndex(-1)
        }

        return () => {
            clearTimeout(timer)
            timer = null
        }

    }, [props.open])

    return (
        <Container
            style={{

            }}
        >
            <InfoContainer
                style={{
                    background: props.artist.color,
                    height: state.mobileOpen ? 'auto' : '100%',
                    flexDirection: state.mobileOpen ? 'column' : 'row'
                }}
            >
            {
                    !state.mobileOpen &&
                        <ProfileImageContainer
                            style={{
                                height:'100%',
                                width: '50%',
                                justifyContent: 'center',
                            }}
                        >
                    

                            <img
                                //crossOrigin="" 
                                //effect="blur"
                                src={props.artist.profileUrl} 
                                style={{
                                    position: 'relative',
                                    height:  '100%',
                                    width:  '100%',
                                    objectFit: 'contain',
                                    objectPosition: 'bottom',
                                    flexGrow: 0,
                                }}
                            />
                    
                    
                    
                    
                    </ProfileImageContainer>
                }

                <DetailsContainer
                    style={{
                        height: state.mobileOpen ? 'auto' : '100%',
                        width: state.mobileOpen ? '100%' : '50%',
                        justifyContent: state.mobileOpen ? 'flex-start' : 'center',
                        alignItems: state.mobileOpen ? 'center' : 'flex-start',
                        textAlign: state.mobileOpen ? 'center' : 'left'
                    }}
                >
                    
                    {
                        state.mobileOpen &&
                            <div style={{height: '15%'}} />
                    }
                    <NameContainer
                        style={{
                            fontSize: '1.7em',
                            height:  state.mobileOpen ? '120px' : '10%',
                            justifyContent: state.mobileOpen ? 'center' : 'flex-start',
                        }}
                    >
                        {props.artist.name}
                    </NameContainer>
                    
                    <ProblemContainer
                        style={{
                            height: state.mobileOpen ? '50px' : '10%',
                            justifyContent: state.mobileOpen ? 'center' : 'flex-start',
                            alignItems: state.mobileOpen ? 'center' : 'flex-start',
                            textAlign: state.mobileOpen ? 'center' : 'left'
                        }}
                    >
                        {props.artist.problem}
                    </ProblemContainer>

                    <SocialsContainer
                        style={{
                            height: state.mobileOpen ? '50px' : '10%',
                            justifyContent: state.mobileOpen ? 'center' : 'flex-start',
                            alignItems: state.mobileOpen ? 'center' : 'flex-start',
                            textAlign: state.mobileOpen ? 'center' : 'left'
                        }}
                    >
                        {
                            props.artist.email && props.artist.email !== '' &&
                                <SocialsButton
                                    onClick={() => emailOpener(props.artist.email)}
                                >
                                    <MdEmail />
                                </SocialsButton>
                        }
                        {
                            props.artist.insta && props.artist.insta !== '' &&
                                <SocialsButton
                                    onClick={() => openWebsite(props.artist.insta)}
                                >
                                    <FaInstagram />
                                </SocialsButton>
                        }
                        {
                            props.artist.phone && props.artist.phone !== '' &&
                                <SocialsButton
                                    onClick={() => openWebsite('tel:'+props.artist.phone)}
                                >
                                    <MdPhone />
                                </SocialsButton>
                        }
                        

                        {
                            props.artist.thesisLink && props.artist.thesisLink !== '' &&
                                <SocialsButton
                                    onClick={() => openWebsite(props.artist.thesisLink)}
                                >
                                    <MdBook />
                                </SocialsButton>
                        }
                        {
                            props.artist.portfolio && props.artist.portfolio !== '' &&
                                <SocialsButton
                                    onClick={() => openWebsite(props.artist.portfolio)}
                                >
                                    <MdLanguage />
                                </SocialsButton>
                        }

                    </SocialsContainer>

                    <DescriptionContainer
                        style={{
                            height: state.mobileOpen ? 'auto' : '50%',
                            flexGrow: state.mobileOpen ? 1 : 0
                        }}
                    >
                        <ReactMarkdown children={props.artist.description} />
                    </DescriptionContainer>
                </DetailsContainer>

                {
                    state.mobileOpen &&
                        <ProfileImageContainer
                            style={{
                                height: '30%',
                                width: state.mobileOpen ? '100%' : '50%',
                                justifyContent: 'flex-start',
                            }}
                        >
                        

                            <img
                                //crossOrigin="" 
                                //effect="blur"
                                src={props.artist.profileUrl} 
                                style={{
                                    position: 'relative',
                                    height:  '100%',
                                    width:  '100%',
                                    objectFit: 'contain',

                                    flexGrow: 0,
                                }}
                            />

                        </ProfileImageContainer>
                }
            </InfoContainer>
                        {true &&

                <ArtContainer
                    style={{
                        flexDirection: state.mobileOpen ? 'column' : 'row',
                        opacity: isOpen ? 1 : 0,
                    }}
                >
                    

                    <DoubleContainer
                        style={{
                            width: state.mobileOpen ? '100%' : 'calc(50% - 10px)',
                        }}
                    >
                        {
                            props.artist.photos.map((prop:any, key:number) => 
                                key % 2 === 0 &&
                                    <Content
                                        style={{
                                        }}
                                        key={key}
                                    >   
                                        {  
                                            prop.hasOwnProperty('isVideo') && prop.isVideo ?
                                                <div>
                                                    <Vimeo
                                                        className="embed-container"
                                                        video={prop.photoUrl}
                                                        autoplay={false}
                                                        responsive
                                                        onError={() => {}}
                                                    />   
                                                    <PhotoBottom>
                                                        {
                                                            prop.caption && prop.caption !== '' &&
                                                                prop.caption
                                                        }
                                                    </PhotoBottom>
                                                </div>
                                                :
                                                <div>
                                                    {
                                                        prop.price && prop.price !== '' && prop.price !== 'Rx' &&
                                                            <PriceContainer 
                                                                style={{
                                                                
                                                                }}
                                                            >
                                                                {prop.price}   
                                                            </PriceContainer>
                                                    }
                                                

                                                    <img
                                                        //scrollPosition={props.scrollPosition}
                                                        //useIntersectionObserver
                                                        crossOrigin="" 
                                                        //effect="opacity"
                                                        src={prop.photoUrl} 
                                                        style={{
                                                            width: '100%',
                                                            position: 'relative',
                                                            flexGrow: 0,
                                                        }}
                                                        onClick={() => setPhotoBigIndex(key)}
                                                    />
                                                    
                                                    
                                                    <PhotoBottom>
                                                        {
                                                            prop.caption && prop.caption !== '' &&
                                                                <ReactMarkdown children={prop.caption} />
                                                        }
                                                    </PhotoBottom>
                                                </div>
                                        }
                                    </Content>
                            )
                        }
                        

                    </DoubleContainer>

                    <DoubleContainer
                        style={{
                            width: state.mobileOpen ? '100%' : 'calc(50% - 10px)',
                        }}
                    >
                        {
                            props.artist.photos.map((prop:any, key:number) => 
                                key % 2 !== 0 &&
                                    <Content
                                        style={{
                                        }}
                                        key={key}
                                    >
                                        {  
                                            prop.hasOwnProperty('isVideo') && prop.isVideo ?
                                                <div>
                                                    <Vimeo
                                                        className="embed-container"
                                                        video={prop.photoUrl}
                                                        autoplay={false}
                                                        responsive
                                                        onError={() => {}}
                                                    />   
                                                    <PhotoBottom>
                                                        {
                                                            prop.caption && prop.caption !== '' &&
                                                                <ReactMarkdown children={prop.caption} />
                                                        }
                                                    </PhotoBottom>
                                                </div>
                                                :
                                                <div>
                                                    {
                                                        prop.price && prop.price !== '' && prop.price !== 'Rx' &&
                                                            <PriceContainer 
                                                                style={{
                                                                
                                                                }}
                                                            >
                                                                {prop.price}   
                                                            </PriceContainer>
                                                    }
                                                    

                                                    <img
                                                        //scrollPosition={props.scrollPosition}
                                                        //crossOrigin="" 
                                                        //effect="blur"
                                                        src={prop.photoUrl} 
                                                        style={{
                                                            position: 'relative',
                                                            width: '100%',
                                                            flexGrow: 0,
                                                        }}
                                                        onClick={() => setPhotoBigIndex(key)}
                                                    />
                                                    
                                                    <PhotoBottom>
                                                        {   
                                                            prop.caption && prop.caption !== '' &&
                                                                <ReactMarkdown children={prop.caption} />
                                                        }
                                                    </PhotoBottom>
                                                </div>
                                        }
                                    </Content>
                            )
                        }
                        
                    </DoubleContainer>

                    

                </ArtContainer>
}

        </Container>
    );

    function emailOpener(email: string) {
        window.location.assign('mailto:'+email);
    }

    function openWebsite(url: string) {
        window.open(url);
    }

    function setPhotoBigIndex(photoBigIndex: number) {
        dispatch({
            type: 'setBigPhoto',
            photoBigIndex: photoBigIndex,
            artistBigIndex: props.index
        })
    }
}

export default ArtistInfo;