import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components'
import { mainStore, MainStateProvider } from '../store/MainStore'
import styles from '../constants'
import SlideContainer from './SlideContainer';
import JewelryArtists from '../pages/JewelryArtists';
import VisualArtists from '../pages/VisualArtists';
import FineArtists from '../pages/FineArtists';

const Container = styled.div`
    position: absolute;
    z-index: 12;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    will-change: transform;

`

interface propTypes {
    setArtist: any
    width: number
}


function ArtistContainer(props: propTypes) {
    const {state, dispatch} = useContext(mainStore)
    const [open, setOpen] = useState(false)


    const [artistTypeIndex, setArtistTypeIndex] = useState(0)


    useEffect(() => {
        if(state.artistType !== '') {
            if(state.artistType === 'jewelry')
                setArtistTypeIndex(0)
            if(state.artistType === 'fine')
                setArtistTypeIndex(1)
            if(state.artistType === 'visual')
                setArtistTypeIndex(2)
        }

        if(state.artistType === '')
            setOpen(false)

        else
            setOpen(true)
        

    }, [state.artistType, state.artistOpen])

    return (
        <Container
            style={{
                transition: !state.menuOpen ? 'transform 0.5s ease' : 'transform 0s',
                transform: open ? 'translateY(0%)' : 'translateY(100%)',
                willChange: 'transform'
            }}
        >


                <SlideContainer
                    slides={[<JewelryArtists width={props.width} />, <FineArtists width={props.width} />, <VisualArtists width={props.width} />]}
                    open={open}
                    index={artistTypeIndex}
                    menuOpen={state.menuOpen}
                    browser={state.browser}
                />
            
        </Container>
    );
}

export default ArtistContainer;