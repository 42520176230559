import React, { useContext, useRef, useState } from 'react';
import styled from 'styled-components'
import { mainStore } from '../store/MainStore';
import styles from '../constants';

const Container = styled.div`
    width: 100%;
    scroll-snap-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    background: ${styles.pink}
`

const ContentContainer = styled.div`
    width: calc(100% - 40px);
    flex-grow: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
`

const HeadingContainer = styled.h2`
    width: 50%;
    display: flex;
    flex-shrink: 0
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 100px;
    line-height: 110px;
`

const ContactsContainer = styled.div`
    width: 50%;
    
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`

const IndividualContact = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
`

const ContactHeading = styled.div`
    font-weight: bold;
`
const ContactInfo = styled.div`
`

interface propTypes {

}
function ContactPage(props: propTypes) {
    const {state, dispatch} = useContext(mainStore)


    return (
        <Container
            style={{
                height: state.height
            }}
        >
            <ContentContainer
                style={{
                    flexDirection: state.mobileOpen ? 'column' : 'row'
                }}
            >
                <HeadingContainer
                    style={{
                        width: state.mobileOpen ? '100%' : '50%',
                        fontSize: state.mobileOpen ? '1em' : '2em',
                        lineHeight: state.mobileOpen ? '50px' : '110px'
                    }}
                >
                    <div>Pop us a</div> 
                    <div>message</div>
                </HeadingContainer>

                <ContactsContainer
                    style={{
                        fontFamily: 'Montserrat',
                        width: state.mobileOpen ? '100%' : '50%',
                        fontSize: state.mobileOpen ? '0.4em' : '0.5em',
                    }}
                >
                    <IndividualContact>
                        <ContactHeading>
                            Ledelle Moe | Fine Arts Coordinator
                        </ContactHeading>
                        <ContactInfo>
                            ledelle@sun.ac.za | +27 21 808 3594
                        </ContactInfo>
                    </IndividualContact>

                    <IndividualContact>
                        <ContactHeading>
                            Carine Terblanche | Jewelery Design Coordinator
                        </ContactHeading>
                        <ContactInfo>
                            ct@sun.ac.za | +27 21 808 3047
                        </ContactInfo>
                    </IndividualContact>

                    <IndividualContact>
                        <ContactHeading>
                            Karolien Perold-Bull | Visual Communication Design Coordinator
                        </ContactHeading>
                        <ContactInfo>
                            karolien@sun.ac.za | +27 21 808 3046
                        </ContactInfo>
                    </IndividualContact>

                    <IndividualContact>
                        <ContactHeading>
                            Visual Arts Department Website 
                        </ContactHeading>
                        <ContactInfo>
                            https://www0.sun.ac.za/visualarts/
                        </ContactInfo>
                    </IndividualContact>


                </ContactsContainer>


            </ContentContainer>

        </Container>
    );
}

export default ContactPage;