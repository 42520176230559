import React, { useEffect, useRef, useState, useContext } from 'react';
import styled from 'styled-components'
import styles from '../constants';
import { mainStore } from '../store/MainStore';
import Grid from '../components/Grid';

const Container = styled.div`
    height: 100%;
    top: 0px;
    width: 100%;
    top: 0px;
    background: ${styles.background};
    overflow-y: auto;
    flex-shrink: 0;
`

const Heading = styled.div`
    height: 250px;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing:5px;
    font-weight: bold;
    color: ${styles.black}
`

const GridStart = styled.div`
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    color: ${styles.black}
`

const GridStartText = styled.div`
    height: 100%;
    width: calc(100% - 40px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
`


interface propTypes {
    width: number
}
function FineArtists(props: propTypes) {
    const {state, dispatch} = useContext(mainStore)
    const [artists, setArtists] = useState<any[]>([])

    useEffect(() => {
        let newArtists:any[] = []
        state.leanArtists.forEach((prop:any) => { 
            if(prop.type==="fine")
                newArtists.push(prop)
        })

        setArtists(newArtists)
        //console.log(newArtists)
    },[state.leanArtists])
    
    return (
        <Container
            style={{
    
            }}
        >
            <Heading
                style={{
                    fontSize: state.mobileOpen ? '1.5em': '3em'
                }}
            >
                Fine Arts
            </Heading>
           
            <Grid
                customStart={
                    <GridStart>


                        <GridStartText
                            style={{
                                fontSize: state.mobileOpen ? '0.4em' : '0.7em',
                            }}
                        >
                            Specialising in Fine Art at Stellenbosch University is to critically engage with orientations. Here, art-making is a knowledge system, and it produces the rich and complex findings of embodied, practice-driven and theoretically informed research. What appears here are deeply personal investments offered up as arrangements of materiality, space, light and other sensory affects, mediating our sense of both the familiar and the strange. These young artists understand that making art is above all, an act of claiming personal agency and time; both critical and valuable resources that are easily claimed by others. To make art is to make space, for oneself and for others. - The Fine Arts team, 2021
                        </GridStartText>
                    </GridStart>
                }
                width={props.width}
                profile={true}
                columns={state.mobileOpen ? 2 : 4}
                elements={artists}
                clicked={artistClicked}
            />
           

        </Container>
    );

    function artistClicked(artist:any) {
        dispatch({
            type: 'setArtist',
            artistOpen: artist.name,
            artistType: state.artistType
        })
    }

}

export default FineArtists;