import React, { useEffect, useRef, useState, useContext, useLayoutEffect } from 'react';
import styled from 'styled-components'
import { mainStore, MainStateProvider } from '../store/MainStore'
import styles from '../constants'
import Grid from '../components/Grid'

import FirstPage from './FirstPage';
import AboutPage from './AboutPage';
import MeetUsPage from './MeetUsPage';
import ContactPage from './ContactPage';
import MatchGame from '../components/MatchGame';
const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden
`


interface propTypes {
    mouseX: any
    mouseY: any
    setArtist: any
    onContentScroll: any
    contentScroll: number
    width: number
    scrollPerc: number
}

function FakeContent(props: propTypes) {
    const {state, dispatch} = useContext(mainStore)

    

    return (
        <Container
            id="scrollElement"
            onScroll={scrollEvent}
        >
            <FirstPage 
                mouseX={props.mouseX}
                mouseY={props.mouseY}
                scroll={props.contentScroll}
                scrollPerc={props.scrollPerc}
            />

            <AboutPage 
                setArtist={props.setArtist}
            />
            <MeetUsPage 
                width={props.width}
                setArtist={props.setArtist}
            />

            <ContactPage />

            <MatchGame 
                width={props.width}
            />

            {/*}
            <Grid
                width={props.width}
                profile={true}
                columns={state.mobileOpen ? 2 : 4}
                elements={state.leanArtists}
                clicked={test}
            />
    */}
           
        </Container>
    );

    

    function test() {
        
    }

    function scrollEvent(e: any) {

        let scrollOff = e.target.scrollTop
        props.onContentScroll(scrollOff)
        
    }

}

export default FakeContent;