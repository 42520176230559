import React, { useEffect, useRef, useState, useContext } from 'react';
import styled from 'styled-components'
import { mainStore } from '../store/MainStore';

import { MdClose } from 'react-icons/md';
import { LazyLoadImage, trackWindowScroll} from 'react-lazy-load-image-component';


const BigPhoto = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    z-index: 200;
`

const BigPhotoBackground = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background: black;
    opacity: 0.65;
    cursor: pointer;
    z-index: 0;
`

const BigPhotoContainer = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: 3;
`

interface propTypes {
    
}
const BigPhotoPage = (props: propTypes) => {
    const {state, dispatch} = useContext(mainStore)

    return (

        state.photoBigIndex !== -1 && state.artistBigIndex !== -1 && state.allArtists.length > state.artistBigIndex && state.allArtists[state.artistBigIndex].photos.length > state.photoBigIndex ?
            <BigPhoto>
                <BigPhotoBackground 
                    onClick={() => setPhotoBigIndex(-1, -1)}
                />
                <BigPhotoContainer>
                    <div 
                        style={{
                            height: '40px', 
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            fontSize: '30px',
                            color: 'white',
                            zIndex: 2,
                            flexShrink: 0
                        }} 
                    >
                        <MdClose style={{marginRight: '40px'}}/>
                    </div>
                    <img
                        crossOrigin="" 
                        height="100%"
                        width="100%"
                        src={state.allArtists[state.artistBigIndex].photos[state.photoBigIndex].photoUrl} 
                        style={{
                            height: "calc(100% - 80px)",
                            objectFit: 'contain',
                        }}
                    />
                    <div style={{height: '40px', flexShrink: 0}} />

                </BigPhotoContainer>
            </BigPhoto>
            :
            null
        );

    function setPhotoBigIndex(photoBigIndex: number, artistBigIndex: number) {
        dispatch({
            type: 'setBigPhoto',
            photoBigIndex: photoBigIndex,
            artistBigIndex: artistBigIndex
        })
    }
}

export default BigPhotoPage;