import {Artist} from './artist'
const artists: Artist[] = [
   {
   	name: 'Emily Allingham',
   	type: 'fine', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Navigation Signals',
   	description: 'Navigating an open sea is always dependent on visibility conditions, the manoeuvrability of one’s vessel and the given climate. In the case of a Seaman, navigational signals act as guides or markers to steer vessels effectively from point A to B. Through colour, shapes, numbers and light, information is passed between individuals to aid in communication and organization. My work investigates the role of navigational signals. Using Nautical signals, I navigate the open sea of post-apartheid South Africa.', //max characters 620
   	email: '',
   	insta: 'https://www.instagram.com/emilyepa/',
   	phone: '0718779487',
   	thesisLink: 'https://drive.google.com/file/d/1hjHQBtt32vJA-KTJ-pBP3sJaKa0OeXac/view?usp=sharing',
   	portfolio: 'https://www.behance.net/emilyallingham',
   	profileUrl: 'Allingham_Emily_Trans.png', // file_name ( must start with  )
   	color: 'red', // color of the background of someones profile image
   	previewedUrl: 'FA_Allingham_1 - Emily Phila.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'FA_Allingham_1 - Emily Phila.jpg',
           	caption: 'Emily Allingham, *Man Overboard (Part 1)*. 2021. Spray Paint on Plastic. Old Navy Harbor. 3 x 3m.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'FA_Allingham_2 - Emily Phila.jpg',
           	caption: 'Emily Allingham, *Untitled*. 2021. Digital Collage. Dimensions Vary. ',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Allingham_3 - Emily Phila.jpg',
           	caption: 'Emily Allingham, *Man Overboard*. Spray Paint on Plastic. Old Navy Harbor. 3 x 3m.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Allingham_4 - Emily Phila.jpg',
           	caption: 'Emily Allingham, *Man Overboard (Part 2)*. 2021. Spray Paint on Plastic. Old Navy Harbor. Dimensions Vary. ',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Allingham_5 - Emily Phila.jpg',
           	caption: 'Emily Allingham, *Untitled*. 2021. Paint on Plywood. Dimensions Vary.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Allingham_6 - Emily Phila.jpg',
           	caption: 'Emily Allingham, *Untitled*. 2021. Process work. ',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Allingham_7 - Emily Phila.jpg',
           	caption: 'Emily Allingham, *Man Overboard*. 2021. Spray Paint on Plastic. Old Navy Harbor. 3 x 3m.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Allingham_8 - Emily Phila.jpg',
           	caption: 'Emily Allingham, *Untitled*. 2021. Digital Collage. Dimensions Vary.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Allingham_9 - Emily Phila.jpg',
           	caption: 'Emily Allingham, *Man Overboard (Part 2)*. 2021. Dimensions Vary.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Allingham_10 - Emily Phila.jpg',
           	caption: 'Emily Allingham, *Man Overboard (Part 3)*. 2021. Dimensions Vary.',
           	price: ''
       	},
   	]
   },

   {
   	name: 'Cayla Basson',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Ocean Conservation',
   	description: `The ocean and its underwater forests are in trouble, as its delicate ecosystems are under threat and human beings are more disconnected from it than ever before. Khelp aims to improve inter-species relationships. By raising awareness, we hope to inspire humans to advocate for the delicate ecosystem's protection. The initiative will achieve this goal by utilizing storytelling as a conscience raising tool in terms of the Great African Seaforest and ocean. Through situated knowledge and participant storytellers, Khelp acts as translator to give a voice to the unheard non-human beings.`, //max characters 620
   	email: 'caylafbasson@gmail.com',
   	insta: '',
   	phone: '(+27)82 415 1700',
   	thesisLink: 'https://drive.google.com/file/d/1VFRTSvaI3dO5hikUnILMC-tt8UCND1Tu/view?usp=sharing',
   	portfolio: 'https://www.behance.net/caylabasson',
   	profileUrl: 'Basson_Cayla_Trans.png', // file_name ( must start with  )
   	color: 'red', // color of the background of someones profile image
   	previewedUrl: 'VCD_Basson_1 - Cayla Basson.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'VCD_Basson_1 - Cayla Basson.jpg',
           	caption: 'Cayla Basson, *Underwater*. 2021. Photograph.', //max characters 280
           	price: 'Contact artist if interested (no set price).',
       	},
       	{
           	photoUrl: 'VCD_Basson_2 - Cayla Basson.jpg',
           	caption: 'Cayla Basson, *Diver*. 2021. Photograph.',
           	price: 'Contact artist if interested (no set price).'
       	},
       	{
           	photoUrl: 'VCD_Basson_3 - Cayla Basson.jpg',
           	caption: 'Cayla Basson, *Kelp*. 2021. Photograph.',
           	price: 'Contact artist if interested (no set price).'
       	},
       	{
           	photoUrl: 'VCD_Basson_4 - Cayla Basson.jpg',
           	caption: 'Cayla Basson, *Darkroom Painting*. 2021. Scan of photograph.',
           	price: 'Contact artist if interested (no set price).'
       	},
       	{
           	photoUrl: 'VCD_Basson_5 - Cayla Basson.jpg',
           	caption: 'Cayla Basson, *Choose To Sea*. 2021. Boundary object made from recycled materials and seaweed.',
           	price: 'Contact artist if interested (no set price).'
       	},
       	{
           	photoUrl: 'VCD_Basson_6 - Cayla Basson.jpg',
           	caption: 'Cayla Basson, *Morphed*. 2021. Photograph.',
           	price: 'Contact artist if interested (no set price).'
       	},
       	{
           	photoUrl: 'VCD_Basson_7 - Cayla Basson.jpg',
           	caption: 'Cayla Basson, *Experienced*. 2021. Photograph.',
           	price: 'Contact artist if interested (no set price).'
       	},
{
           	photoUrl: 'https://player.vimeo.com/video/653280862?h=39bdc31b10',
           	caption: 'Cayla Basson, *Kelp in Motion*. 2021. Video.',
           	price: '',
	isVideo: true
       	},
{
           	photoUrl: 'https://player.vimeo.com/video/653280942?h=f8d04bf9de',
           	caption: 'Cayla Basson, *Kelp in Motion*. 2021. Video.',
           	price: '',
	isVideo: true
       	}
   	]
   },

   {
   	name: 'Marijke Bastiaanse',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Sustainable Sex',
   	description: 'Thousands of tons of waste are created by single-use products such as condoms, tampons, pads and more. Additionally, there is a lack of awareness of this and so, I sought to raise awareness and educate the students on the Stellenbosch University campus to these unseen aspects to their sexual behaviour.', //max characters 620
   	email: 'bastiaansemarijke@gmail.com',
   	insta: '',
   	phone: '0605268523',
   	thesisLink: 'https://drive.google.com/file/d/1eczzkO_wDFxl-U2Hw9zj86I5_ZXzjKvd/view?usp=sharing',
   	portfolio: 'https://bastiaansemarijke.wixsite.com/mysite',
   	profileUrl: 'Bastiaanse_Marijke_2_Trans.png', // file_name ( must start with  )
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'VCD_Bastiaanse_3 - Marijke Bastiaanse.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'VCD_Bastiaanse_1 - Marijke Bastiaanse.jpg',
           	caption: 'Marijke Bastiaanse, *Dori lied*. 2021. Poster, 42 x 59,4 cm.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'VCD_Bastiaanse_2 - Marijke Bastiaanse.jpg',
           	caption: 'Marijke Bastiaanse, *Tampon & condom infographic*. 2021. Poster, 42 x 100 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Bastiaanse_3 - Marijke Bastiaanse.jpg',
           	caption: 'Marijke Bastiaanse, *Decomposition*. 2021. Photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Bastiaanse_4 - Marijke Bastiaanse.jpg',
           	caption: `Marijke Bastiaanse, *Don't be a dick, give a shit*. 2021. Poster, 42 x 59,4 cm.`,
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Bastiaanse_5 - Marijke Bastiaanse.jpg',
           	caption: 'Marijke Bastiaanse, *Catalogue design*. 2021.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Bastiaanse_6 - Marijke Bastiaanse.jpg',
           	caption: 'Marijke Bastiaanse, *Participatory infographic*. 2021. Poster, 42 x 59,4 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Bastiaanse_7 - Marijke Bastiaanse.jpg',
           	caption: 'Marijke Bastiaanse, *Finish your plate*. 2021. Photograph.',
           	price: ''
       	},
{
           	photoUrl: 'https://player.vimeo.com/video/653284829?h=56bc2ab8a7',
           	caption: 'Marijke Bastiaanse, *Don’t be a dick, give a shit*. 2021. Video.',
           	price: '',
	isVideo: true
       	},
{
           	photoUrl: 'https://player.vimeo.com/video/653284852?h=bb14d3e4c0',
           	caption: 'Marijke Bastiaanse, *Visual Excavation*. 2021. Video.',
           	price: '',
	isVideo: true
       	},
{
           	photoUrl: 'https://player.vimeo.com/video/653284889?h=e8db77dc95',
           	caption: 'Marijke Bastiaanse, *Brand Guide*. 2021. Epub.',
           	price: '',
	isVideo: true
       	}
   	]
   },

   {
   	name: 'Alexandra Bester',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Sustainable Food',
   	description: `Having started as hunter-gatherers, humans have had a very direct and reliant relationship with the natural environment for food. Over time we settled and learned how to cultivate land. Our ways of food became more complex and, as a result, the impacts thereof more detrimental. With such problematic processes and perspectives, and faced with a collapsing ecosystem, there is a need to reevaluate our ways of food. Inspired by my own experiences, my initiative *Foly* is focused on sharing knowledge and facilitating experiences and interactions surrounding ‘sustainable ways of food’.`, //max characters 620
   	email: 'alexandrabester.p@gmail.com',
   	insta: 'https://www.instagram.com/alexandra_bester/',
   	phone: '',
   	thesisLink: 'https://drive.google.com/file/d/1jjqDHj0btUUphZxtupsOXgY0voZdlA38/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Bester_Alexander_Trans.png', // file_name ( must start with  )
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'VCD_Bester_3 - Alexandra Bester.jpg', //file_name
   	fixHeight: true, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'https://player.vimeo.com/video/653284456?h=800bc7f33c',
           	caption: 'Alexandra Bester, *Sustainable ways of kos*. 2021. Digital collage.', //max characters 280
           	price: '',
	isVideo: true
       	},
       	{
           	photoUrl: 'VCD_Bester_2 - Alexandra Bester.jpg',
           	caption: 'Alexandra Bester, *Sustainable ways of kos*. 2021. Digital collage.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Bester_3 - Alexandra Bester.jpg',
           	caption: 'Alexandra Bester, *Front and pages of research article*. 2021. Thinners and digital collage.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Bester_ 4 - Alexandra Bester.jpg',
           	caption: 'Alexandra Bester, *Front and end pages of research article*. 2021. Thinners and digital collage.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Bester_5 - Alexandra Bester.jpg',
           	caption: 'Alexandra Bester, *Food for thought*. 2021. Photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Bester_6 - Alexandra Bester.jpg',
           	caption: 'Alexandra Bester, *Food for thought*. 2021. Photograph.',
           	price: ''
       	},
   	]
   },

   {
   	name: 'Carolyn Boyes',
   	type: 'fine', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Skin and cosmetics',
   	description: '*Reveal and Conceal* investigates the desire that people must hide and cover up their inscribed skin - skin that is marked by scars, blemishes or even skin disorders. Make-up is the medium through which I express my interest in revealing and concealing the skin. My body of work unpacks how individuals, from a very young age, have this idea imprinted in their minds that they need to use products such as make-up to cover their inscribed skin to create an improved version of themselves. My curiosity with skin has grown and continues to grow throughout the process of researching and creating.', //max characters 620
   	email: '',
   	insta: 'https://www.instagram.com/caro_boyes/',
   	phone: '0718634790',
   	thesisLink: 'https://drive.google.com/file/d/1V2E9qeSJ9F5N7tH9kO8KfBUnca430e9j/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Boyes_Carolyn_Trans.png', // file_name ( must start with  )
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'FA_Boyes_5 - Carolyn Boyes.jpg', //file_name
   	fixHeight: true, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'FA_Boyes_1 - Carolyn Boyes.jpg',
           	caption: 'Carolyn Boyes, *Dewy*. 2021. Video still. 4 minutes long.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'FA_Boyes_2 - Carolyn Boyes.jpg',
           	caption: 'Carolyn Boyes, *Dewy*. 2021. Video still. 4 minutes long.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Boyes_3 - Carolyn Boyes.jpg',
           	caption: 'Carolyn Boyes, *Dewy*. 2021. Video still. 4 minutes long.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Boyes_4 - Carolyn Boyes.jpg',
           	caption: 'Carolyn Boyes, *Playing with Preserved Pigments*. 2021. Foundation and glass. 25 x 25cm. Series.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Boyes_5 - Carolyn Boyes.jpg',
           	caption: 'Carolyn Boyes, *Playing with Preserved Pigments*. 2021. Foundation and glass. 25 x 25cm. Series.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Boyes_6 - Carolyn Boyes.jpg',
           	caption: 'Carolyn Boyes, *Falling Folds*. 2021. Foundation and ink on paper. Series of 60 monotype prints. 18x18cm each.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Boyes_7 - Carolyn Boyes.jpg',
           	caption: 'Carolyn Boyes, *Falling Folds*. 2021. Foundation and ink on paper. Series of 60 monotype prints. 18x18cm each.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Boyes_8 - Carolyn Boyes.jpg',
           	caption: 'Carolyn Boyes, *Made-up Maiden*. 2021. Make-up wipes, tule and boning.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Boyes_9 - Carolyn Boyes.jpg',
           	caption: 'Carolyn Boyes, *Made-up Maiden*. 2021. Make-up wipes, tule and boning.',
           	price: ''
       	},
{
           	photoUrl: 'https://player.vimeo.com/video/653285201?h=1fa977f550',
           	caption: 'Carolyn Boyes, *Pretty Princess*. 2021. Video. 22 Seconds long.',
           	price: '', 
	isVideo: true
       	},
   	]
   },

   {
   	name: 'Joseph Bracken',
   	type: 'fine', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Destruction',
   	description: 'My work centres around the constant need to move forward and create within artistic practice and, within broader terms, existence as a whole. Often the things that we do cause us to become stuck, making it difficult to move forward, or pursue change. In my own practice, I challenge this notion, utilizing destruction in order to force my own work into a new existence. Destruction is a powerful, often overlooked, tool in the making of art and shows us how nothing is truly stuck or finished, but rather lives in a transient state..', //max characters 620
   	email: '',
   	insta: 'https://www.instagram.com/joseph_bracken/',
   	phone: '0827301624',
   	thesisLink: 'https://drive.google.com/file/d/1S-jJwVekNSMOH6WmPu5_VSnpp3suK3OK/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Bracken_Joseph_Trans.png', // file_name ( must start with  )
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'FA_BRACKEN_1 - Joe B.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than wider, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'FA_BRACKEN_1 - Joe B.jpg',
           	caption: 'Joseph Bracken, *Machine 1*. 2021.Steel, 20 x 60 cm.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'FA_BRACKEN_2 - Joe B.jpg',
           	caption: 'Joseph Bracken, *Machine 1*. 2021.Steel, 20x60 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_BRACKEN_3 - Joe B.jpg',
           	caption: 'Joseph Bracken, *Brass Plate 1 Close-Up*. 2021. Brass plate, 29 x 42 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_BRACKEN_4 - Joe B.jpg',
           	caption: 'Joseph Bracken, *Brass Plate 2 Close-Up*. 2021.Brass plate, 29 x 42 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_BRACKEN_5 - Joe B.jpg',
           	caption: 'Joseph Bracken, *Plate Prints*. 2021. Cotton and ink, 2800 x 50 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_BRACKEN_6 - Joe B.jpg',
           	caption: 'Joseph Bracken, *Plate Prints Close-Up*. 2021. Cotton and ink, 2800 x 50 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_BRACKEN_7 - Joe B.jpg',
           	caption: 'Joseph Bracken, *Plate Prints Detail*. 2021. Cotton and ink, 2800 x 50 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_BRACKEN_8 - Joe B.jpg',
           	caption: 'Joseph Bracken, *Battery Acid Residue*. 2021. Metal plate and battery acid, 59.4 x 84.1 cm .',
           	price: ''
       	},
{
           	photoUrl: 'FA_BRACKEN_9 - Joe B.jpg',
           	caption: 'Joseph Bracken, *Island 1*. 2021. Resin and Paper, 15 x 13 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'https://player.vimeo.com/video/653281571?h=46ed286469',
           	caption: 'Joseph Bracken, *Machine Video*. 2021. Video.',
           	price: '',
	isVideo: true
       	}
   	]
   },

   {
   	name: 'Jacques Bruwer',
   	type: 'fine', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Spatial relations',
   	description: 'In my practice, I explore my environment and the structures of space that surround me to better understand these spaces. Through this exploration, I attempt to better understand the structures of my environment. I am particularly drawn to the shadows, negative spaces and movement connected to my environment.', //max characters 620
   	email: 'jacquesbruwer1998@gmail.com',
   	insta: 'https://www.instagram.com/jacques_bruwer/',
   	phone: '',
   	thesisLink: 'https://drive.google.com/file/d/1CeRlwjuL59WYE9OFBSBdm6Mo1eoCPj9k/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Bruwer_Jacques_2_Trans.png', // file_name ( must start with  )
   	color: 'red', // color of the background of someones profile image
   	previewedUrl: 'FA_BRUWER_7.jpg', //file_name
   	fixHeight: true, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'FA_BRUWER_1.jpg',
           	caption: 'Jacques Bruwer, *Walks: A Process of Understanding no. 142*. 2021. Printed on archival paper, 15 x 20cm.', //max characters 280
           	price: 'R200',
       	},
       	{
           	photoUrl: 'FA_BRUWER_2.jpg',
           	caption: 'Jacques Bruwer, *Walks: A Process of Understanding no. 143*. 2021. Printed on archival paper, 15 x 15cm.',
           	price: 'R200'
       	},
       	{
           	photoUrl: 'FA_BRUWER_3.jpg',
           	caption: 'Jacques Bruwer, *Walks: A Process of Understanding no. 144*. 2021. Printed on archival paper, 15 x 45cm.',
           	price: 'R400'
       	},
       	{
           	photoUrl: 'FA_BRUWER_4.jpg',
           	caption: 'Jacques Bruwer, *Walks: A Process of Understanding no. 103*. 2021. Printed on archival paper, 20 x 30cm.',
           	price: 'R300'
       	},
       	{
           	photoUrl: 'FA_BRUWER_5.jpg',
           	caption: 'Jacques Bruwer, *Walks: A Process of Understanding no. 108*. 2021. Printed on archival paper, 20 x 30cm.',
           	price: 'R300'
       	},
       	{
           	photoUrl: 'FA_BRUWER_6.jpg',
           	caption: 'Jacques Bruwer, *Untitled Spatial Exploration*. 2021. Found Metal and wood, 700 x 240 x 30 cm.',
           	price: 'R1000'
       	},
       	{
           	photoUrl: 'FA_BRUWER_7.jpg',
           	caption: 'Jacques Bruwer, *Container Walls*. 2021. Container sides and found metal, 270 x 220 x 100 cm.',
           	price: 'R1500.00'
       	},
       	{
           	photoUrl: 'FA_BRUWER_8.jpg',
           	caption: 'Jacques Bruwer, *Untitled, 2021*. 2021.  Acrylic on cotton based paper, 11 x 15cm each.',
           	price: 'R50 each, R2000 for all'
       	},
       	{
           	photoUrl: 'FA_BRUWER_9.jpg',
           	caption: 'Jacques Bruwer, *Untitled Metal Form* c.2021. 15cm x 5cm x 5cm. Metal ',
           	price: 'R300.00'
       	},
       	{
           	photoUrl: 'FA_BRUWER_10.jpg',
           	caption: 'Jacques Bruwer, *Walks: A Process of Understanding no. 140*. 2021. Printed on archival paper, 30 x 45 cm.',
           	price: 'R300'
       	},
   	]
   },

   {
   	name: 'Willemien Bruwer',
   	type: 'jewelry', // One of these three ONLY: jewelry, fine, visual
   	problem: 'The Anthropocene',
   	description: 'The ant is part of a beautifully intricate and delicate community that is connected to their entire ecosphere. The human is detached from Nature, and thus creative practices and social theories regarding the ant colony can be utilized to cultivate activism for consciousness of the Anthropocene. I will use human disconnection to Nature to explain the social behavioural similarities between that of the human and ant. The childlike exploration between the blend of ants and humans can create a positive environment that questions what it means to be a social creature in an Anthropocentric environment.', //max characters 620
   	email: 'wsbruwer@gmail.com',
   	insta: 'https://www.instagram.com/krieke_grond/',
   	phone: '0823185415',
   	thesisLink: 'https://drive.google.com/file/d/1V_2o-WxTwZI0MY4udY2XHFlJwHicb5SV/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Bruwer_Lemi_2_Trans.png', // file_name ( must start with  )
   	color: 'red', // color of the background of someones profile image
   	previewedUrl: 'JD_Willemien Bruwer_9 - miekie.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than wider, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'JD_Willemien Bruwer_1 - miekie.jpg',
           	caption: 'Willemien S. Bruwer, *Underground Bodies*, 2021. Hand-carved walnut wood.', //max characters 280
           	price: 'Contact Artist',
       	},
       	{
           	photoUrl: 'JD_Willemien Bruwer_2 - miekie.jpg',
           	caption: 'Willemien S. Bruwer, *Freki*. 2021. 3D printed PETG plastic, ceramics, vegan leather string.',
           	price: 'Contact Artist'
       	},
       	{
           	photoUrl: 'JD_Willemien Bruwer_3 - miekie.jpg',
           	caption: 'Willemien S. Bruwer, *Brooch I & II*. 2021. Hand carved wood, silver.',
           	price: 'Contact Artist'
       	},
       	{
           	photoUrl: 'JD_Willemien Bruwer_4 - miekie.jpg',
           	caption: 'Willemien S. Bruwer, *Ruby from a Bite*. 2021. 3D printed PLA plastic, vegan leather string.',
           	price: 'Contact Artist'
       	},
       	{
           	photoUrl: 'JD_Willemien Bruwer_5 - miekie.jpg',
           	caption: 'Willemien S. Bruwer, *Dissociate*. 2021. Sterling silver, mesh fabric, cotton cord, cotton thread, cubic zirconia, perspex.',
           	price: 'Contact Artist'
       	},
       	{
           	photoUrl: 'JD_Willemien Bruwer_6 - miekie.jpg',
           	caption: 'Willemien S. Bruwer, *Gogga in n Jassie*. 2021. Fabric, sterling silver, glass beads, cotton thread, perspex.',
           	price: 'Contact Artist'
       	},
       	{
           	photoUrl: 'JD_Willemien Bruwer_7 - miekie.jpg',
           	caption: 'Willemien S. Bruwer, *It*. 2021. Fabric, sterling silver, cotton thread, plastic thread, perspex.',
           	price: 'Contact Artist'
       	},
       	{
           	photoUrl: 'JD_Willemien Bruwer_8 - miekie.jpg',
           	caption: 'Willemien S. Bruwer, *Bite Me Quickly*. 2021. 3D printed PLA plastic, vegan leather string.',
           	price: 'Contact Artist'
       	},
       	{
           	photoUrl: 'JD_Willemien Bruwer_9 - miekie.jpg',
           	caption: 'Willemien S. Bruwer, *Zoe*. 2021. Hand-carved walnut wood, sterling silver, cubic zirconia, resin, pearls.',
           	price: 'Contact Artist'
       	},
       	{
           	photoUrl: 'JD_Willemien Bruwer_10 - miekie.jpg',
           	caption: 'Willemien S. Bruwer, *Untitled*. 2021. Fabric, cotton thread, recycled plastic, plastic stuffing.',
           	price: 'Contact Artist'
       	},
   	]
   },

   {
   	name: 'Lara Clucas',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Sustainable Design',
   	description: 'The project *Matter* attempts to investigate the human-nature relationship and how this functions in a material world. It used biomaterial to explore humans’ interaction with nature in a tactile way so as to impart an ethics of care through a tangible experience. *Matter* focused on the sensory impact of touch as an emotional stimulus. Innovative methods are needed to not simply replace the detrimental practices upon which we have become dependent, but to disrupt the knowledge which has established the foundation for the reproduction of unsustainable relationships between humans and nature.', //max characters 620
   	email: 'laraclucas99@gmail.com',
   	insta: 'https://www.instagram.com/lara_clucas/ ',
   	phone: '',
   	thesisLink: 'https://drive.google.com/file/d/1heJTN79s3rIc3tWIp_bxGUqQDnWNkDw_/view?usp=sharing',
   	portfolio: 'https://www.behance.net/laraclucas2',
   	profileUrl: 'Clucas_Lara_Trans.png', // file_name ( must start with  )
   	color: 'red', // color of the background of someones profile image
   	previewedUrl: 'VCD_Clucas_1 - Lara Clucas.jpg', //file_name
   	fixHeight: true, //if preview photo is taller than wider, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'VCD_Clucas_1 - Lara Clucas.jpg',
           	caption: 'Lara Clucas, *Composite of Biomaterial Experiments*. 2021. ', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'VCD_Clucas_2 - Lara Clucas.jpg',
           	caption: 'Lara Clucas, *Poster Series – Animated Poster I*. 2021. Poster.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Clucas_3 - Lara Clucas.jpg',
           	caption: 'Lara Clucas, *Poster Series – Animated Poster II*. 2021. Poster.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Clucas_4 - Lara Clucas.jpg',
           	caption: 'Lara Clucas, *Poster Series – Animated Poster III*. 2021. Poster.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Clucas_5 - Lara Clucas.jpg',
           	caption: 'Lara Clucas, *Matter Brand Guide*. 2021. Digital Publication.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Clucas_6 - Lara Clucas.jpg',
           	caption: 'Lara Clucas, *Sensory Station - Matter Web*. 2021.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Clucas_7 - Lara Clucas.jpg',
           	caption: 'Lara Clucas, *Mindful Matter Book*. 2021. Book.',
           	price: ''
       	},
       	{
           	photoUrl: 'https://player.vimeo.com/video/653274384?h=e95fceff6e',
           	caption: 'Lara Clucas, *Submerge in the situated connectivity*. 2021. Video.',
           	price: '',
	isVideo: true
       	},
       	{
           	photoUrl: 'https://player.vimeo.com/video/653274423?h=cced715b9a',
           	caption: 'Lara Clucas, *Sensory Station - Matter Web *. 2021. Video.',
           	price: '',
	isVideo: true
       	}
   	]
   },

   {
   	name: 'Bianca Coetzee',
   	type: 'fine', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Mental health',
   	description: 'My creative process attempts to make sense of my grandfather’s diagnosis of dementia. It serves a broader purpose – to raise awareness and prompt affective solidarity. To achieve this, I return to the ocean. My work takes the form of paintings, installations, printmaking, and film in which I focus on passing time, disintegration, and growth.', //max characters 620
   	email: 'biancacoetzee15@gmail.com',
   	insta: 'instagram.com/sicilancart/',
   	phone: '',
   	thesisLink: 'https://drive.google.com/file/d/186tJ1ePJ062e-9piFuao4R-fBY-QHpnn/view?usp=sharing',
   	portfolio: 'https://sicilanca.wixsite.com/mysite',
   	profileUrl: 'Coetzee_Bianca_Trans.png', // file_name ( must st with  )
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'FA_Coetzee_1 - Bianca Coetzee.jpg', ///file_name
   	fixHeight: false, //if preview photo is taller than wider, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'FA_Coetzee_1 - Bianca Coetzee.jpg',
           	caption: 'Bianca Coetzee, *Disintegration*. 2021. Photograph.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'FA_Coetzee_2 - Bianca Coetzee.jpg',
           	caption: 'Bianca Coetzee, *Obscure the narrative*. 2021. Oil painting on canvas, 1,2 x 1,5m.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Coetzee_3 - Bianca Coetzee.jpg',
           	caption: 'Bianca Coetzee, *Everywhere at the End of Time*. 2021. Oil Painting, 50 x 60cm.',
           	price: 'R1500.00'
       	},
       	{
           	photoUrl: 'FA_Coetzee_4 - Bianca Coetzee.jpg',
           	caption: 'Bianca Coetzee, Close up of *Obscure the narrative*. 2021. Oil painting on canvas, 1,2 x 1,5m.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Coetzee_5 - Bianca Coetzee.jpg',
           	caption: 'Bianca Coetzee, *The Blue Armchair*. 2021. Oil on canvas. 42.0 x 59.4cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Coetzee_6 - Bianca Coetzee.jpg',
           	caption: 'Bianca Coetzee, *Ocean Trajectory*. 2021. Oil on canvas. 58 x 83cm.',
           	price: 'R5000'
       	},
	{
           	photoUrl: 'FA_Coetzee_7 - Bianca Coetzee.jpg',
           	caption: 'Bianca Coetzee, *Untitled*. 2021. Installation view.',
           	price: ''
       	},
{
           	photoUrl: 'https://player.vimeo.com/video/653274542?h=3a5d058ee9',
           	caption: 'Bianca Coetzee, *Obscure the narrative*. 2021. Film.',
           	price: '',
	isVideo: true
       	},
       	{
           	photoUrl: 'https://player.vimeo.com/video/653274629?h=5b827b1acd',
           	caption: 'Bianca Coetzee, *Organic brain*. 2021. Film.',
           	price: '',
	isVideo: true
       	}
   	]
   },

   {
   	name: 'Jané Coetzee',
   	type: 'fine', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Art as communication',
   	description: `We constantly have unconscious and unprocessed thoughts that can overwhelm us if it is not released in some way. A need to better understand my own internal landscape led me to creative practices that allowed my internal experiences to materialise through making. I find the way in which my mind, body and spirit engage through making, distinctly different from verbally articulating something. My work is the manifestation of my attempts to connect and converse with an internal force. It’s an exploration of an internal dimension.`, //max characters 620
   	email: 'jane.jmcoetzee@gmail.com',
   	insta: '',
   	phone: '',
   	thesisLink: 'https://drive.google.com/file/d/1JocyvB1R6S6ZA8ucHD4zM8zFMS_zS_S1/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Coetzee_Jane_Trans.png', // file_name ( must start with  )
   	color: 'red', // color of the background of someones profile image
   	previewedUrl: 'FA_Coetzee_1 - jane coetzee.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'FA_Coetzee_1 - jane coetzee.jpg',
           	caption: 'Jané Coetzee, *Untitled*. 2021. Charcoal on fabric, 280 x 500 cm.', //max characters 280
           	price: '',
       	},
{
           	photoUrl: 'FA_Coetzee_2 - jane coetzee.jpg',
           	caption: 'Jané Coetzee, *Drawing the blind*. 2021. Charcoal on fabric, varying sizes.',
           	price: ''
       	},
{
           	photoUrl: 'FA_Coetzee_3 - jane coetzee.jpg',
           	caption: 'Jané Coetzee, *Drawing the blind*. 2021. Charcoal on fabric, varying sizes.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Coetzee_4 - jane coetzee.jpg',
           	caption: 'Jané Coetzee, *Drawing the blind*. 2021. Charcoal on fabric, varying sizes.',
           	price: ''
       	},
{
           	photoUrl: 'FA_Coetzee_5 - jane coetzee.jpg',
           	caption: 'Jané Coetzee, *Drawing the blind*. 2021. Charcoal on fabric, varying sizes.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Coetzee_6 - jane coetzee.jpg',
           	caption: 'Jané Coetzee, *Drawing the blind*. 2021. Charcoal on fabric, varying sizes.',
           	price: ''
       	},
{
           	photoUrl: 'https://player.vimeo.com/video/653276957?h=ce6b7b1928',
           	caption: 'Jané Coetzee, *Drawing the blind*. 2021. Video.',
           	price: '',
	isVideo: true
       	}
   	]
   },

   {
   	name: 'Zanne Cronjé',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Waste-Sorting',
   	description: `My overall focus for this year was how to encourage the correct sorting of waste by Visual Arts students at Stellenbosch University. The theoretical aspect of my work comprised research into the basic design elements used to improve sustainability and then applying this research to propose a new design of Stellenbosch University’s current three-bin waste disposal system. The practical aspect of my work follows the process of creating this newly proposed design, and also provides Visual Arts students with resources pertaining to various ways in which to reuse, and therefore upcycle, correctly sorted waste.`, //max characters 620
   	email: 'zanne.cronje@gmail.com',
   	insta: 'https://www.instagram.com/zanne_designs/',
   	phone: '076 655 9676',
   	thesisLink: 'https://drive.google.com/file/d/16txJjGDfwVAujOCZf9C7NhJr4LuyCsmE/view?usp=sharing',
   	portfolio: 'https://www.linkedin.com/in/zanne-cronj%C3%A9-66b4b4223/',
   	profileUrl: 'Cronje_Zanne_Trans.png', // note that Zanne legit put the code of the image I edited lol so no change
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'VCD_Cronjé_4 - Zanne Cronje.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than wider, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'VCD_Cronje_1 - Zanne Cronje.jpg',
           	caption: 'Zanne Cronjé, *Waste/Art Guide Book Cover Page*. 2021. Printed book, 20.35 x 20.35 cm.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'VCD_Cronjé_2 - Zanne Cronje.jpg',
           	caption: 'Zanne Cronjé, *Black & White Waste Photography*. 2021. Digital photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Cronjé_3 - Zanne Cronje.jpg',
           	caption: 'Zanne Cronjé, *Technicolour Waste Photography*. 2021. Digital photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Cronjé_4 - Zanne Cronje.jpg',
           	caption: 'Zanne Cronjé, *Non-Recyclable Waste*. 2021. Digital collage, 31.7 x 24.24 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Cronjé_5 - Zanne Cronje.jpg',
           	caption: 'Zanne Cronjé, *Compostable Waste*. 2021. Digital collage, 31.7 x 24.24 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Cronjé_6 - Zanne Cronje.jpg',
           	caption: 'Zanne Cronjé, *Recyclable Waste*. 2021. Digital collage, 31.7 x 24.24 cm.',
           	price: ''
       	}
   	]
   },

   {
   	name: 'Anicka de Lange',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Speculative Fiction',
   	description: 'The initiative utilises dark ecology and speculative fiction to narrate the tale of Zooglea. Zooglea is a fictional post-apocalyptic South Africa that banned plastic. In Zooglea bacterial cellulose reshapes communities, culture, and commodities. Instead of applying activism as a way of assessing our relationship to nonhumans, the initiative puts forward a form of thinkivism. In this way, humans can rather think or form ideas out of the current ecological crisis and into a fictional future to evaluate the way we have been existing. The question *“What should be?”* is replaced with *“What if?”*.', //max characters 620
   	email: 'delangeanicka@gmail.com',
   	insta: 'https://www.instagram.com/__groa__/',
   	phone: '0823341168',
   	thesisLink: 'https://drive.google.com/file/d/1J32v1SSrY4r_RqWaTTnk-kU18sUSDwWL/view?usp=sharing',
   	portfolio: 'https://www.behance.net/anickadelange',
   	profileUrl: 'de Lange_Anicka_Trans.png', // file_name ( must start with  )
   	color: 'red', // color of the background of someones profile image
   	previewedUrl: 'VCD_deLange_2 - Anicka de Lange.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'VCD_deLange_1 - Anicka de Lange.jpg',
           	caption: 'Anicka de lange, *Zooglea: Scoby Healing*. 2021. Digital Photograph.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'VCD_deLange_2 - Anicka de Lange.jpg',
           	caption: 'Anicka de Lange, *Zooglea: Scoby Edible Bra*. 2021. Digital Photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_deLange_3 - Anicka de Lange.jpg',
           	caption: 'Anicka de Lange, *Zooglea: Non/human Intimacy *. 2021. Digital Photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_deLange_4 - Anicka de Lange.jpg',
           	caption: 'Anicka de Lange, *Zooglea: Living Skin*. 2021. Digital Photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_deLange_6 - Anicka de Lange.jpg',
           	caption: 'Anicka de Lange, *Zooglea: Scoby=Vital Organ*. 2021.Digital Photograph.',
           	price: ''
       	},
{
           	photoUrl: 'VCD_deLange_7 - Anicka de Lange.jpg',
           	caption: 'Anicka de Lange, *Zooglea: Scoby Clothing*. 2021. Digital Photograph.',
           	price: ''
       	},
{
           	photoUrl: 'VCD_deLange_8 - Anicka de Lange.jpg',
           	caption: 'Anicka de Lange, *Zooglea: Scoby Harvest Bag*. 2021. Digital Photograph.',
           	price: ''
       	},
{
           	photoUrl: 'https://player.vimeo.com/video/653282018?h=c5725308d8',
           	caption: 'Anicka de Lange, *Playing Becoming-With-Scoby*. 2021. Stop Motion Video.',
           	price: '',
	isVideo: true
       	}
   	]
   },

   {
   	name: 'Lereze de Scande',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Education',
   	description: `My Fourth year work focuses mainly on the learning aspect of Early Childhood Development (ECD) within South Africa. According to the *South Africa’s 2018 General Household survey* (STATS SA, 2018:12), only 3,1% of children over the age of 5 attend pre-school. Thus, my initiative *dlala* focusses on improving access to quality early learning opportunities by means of creating an accessible digital learning resource, called *Let's dlala!*. This resource will provide low-income South African preschool learners with the opportunity to improve skills needed to start formal schooling.`, //max characters 620
   	email: 'descandelereze@gmail.com',
   	insta: 'https://www.instagram.com/lerezedescande/',
   	phone: '',
   	thesisLink: 'https://drive.google.com/file/d/1nyoKldPjB6exxOEJq4xH7U6ZbnK7e8bg/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'De Scande_Lereze_2_Trans.png', // file_name ( must start with  )
   	color: 'red', // color of the background of someones profile image
   	previewedUrl: 'VCD_DeScande_1 - Lereze de Scande.jpg', //file_name
   	fixHeight: true, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'VCD_DeScande_1 - Lereze de Scande.jpg',
           	caption: 'Lereze de Scande, *Let’s dlala!: Mobile Game Marketing Poster 1*. 2021.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'VCD_DeScande_2 - Lereze de Scande.jpg',
           	caption: 'Lereze de Scande, *Let’s dlala!: Mobile Game Marketing Poster 2*. 2021.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_DeScande_3 - Lereze de Scande.jpg',
           	caption: 'Lereze de Scande, *Let’s dlala!: Mobile Game Mockup 1*. 2021.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_DeScande_4 - Lereze de Scande.jpg',
           	caption: ' Lereze de Scande, *Let’s dlala!: Mobile Game Mockup 2*. 2021.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_DeScande_5 - Lereze de Scande.jpg',
           	caption: 'Lereze de Scande, *Let’s dlala!: Mobile Game Mockup, Numeracy Skill Level 1*. 2021.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_DeScande_6 - Lereze de Scande.jpg',
           	caption: 'Lereze de Scande, *Let’s dlala!: Mobile Game Mockup, Numeracy Skill Level 2*. 2021.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_DeScande_7 - Lereze de Scande.jpg',
           	caption: 'Lereze de Scande, *Let’s dlala!: Mobile Game Character Creating*. 2021.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_DeScande_8 - Lereze de Scande.jpg',
           	caption: 'Lereze de Scande, *Let’s dlala!* , Learn ABC’s Book Cover. 2021.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_DeScande_9 - Lereze de Scande.jpg',
           	caption: 'Lereze de Scande, *Let’s dlala!: ’C’ Spread 1 from Learn ABC’s*. 2021.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_DeScande_10 - Lereze de Scande.jpg',
           	caption: 'Lereze de Scande, *Let’s dlala!: ’C’ Spread 2 from Learn ABC’s. 2021.',
           	price: ''
       	},
   	]
   },

   {
   	name: 'Jordyn Dreyer',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Diabetes Stigma',
   	description: 'My name is Jordyn Dreyer and I am an introverted activist, dystopian dreamer, croissant enthusiast and Type 1 Diabetic. Through a design practise aimed at challenging the stigma facing the Type 1 Diabetic community, I employ an interdisciplinary approach to design, founded in the fusion of art and science, to address this problem. I explore reshaping public perceptions through activations such as abstract zines, Instagram filters, websites, and wearable advocacy. Additionally, through interactive and collaborative projects inviting public involvement, I aim to encourage education, advocacy, and building community.', //max characters 620
   	email: 'jordydreyer@gmail.com',
   	insta: 'https://www.instagram.com/jordsdre/',
   	phone: '',
   	thesisLink: 'https://drive.google.com/file/d/17yLwBKqhk63jJMx9nwOuqXV_hpu9k_ut/view?usp=sharing',
   	portfolio: 'https://jordyndreyerdesign.wixsite.com/mysite',
   	profileUrl: 'Dreyer_Jordyn_Trans.png', // file_name ( must start with  )
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'VCD_DREYER_3 - Jordy Dreyer.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'VCD_DREYER_1 - Jordy Dreyer.jpg',
           	caption: 'Jordyn Dreyer, *Element 6*. 2021. Brand Manifesto ', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'VCD_DREYER_2 - Jordy Dreyer.jpg',
           	caption: 'Jordyn Dreyer, *How Much=Health Cost?*. 2021. Abstract Zine',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_DREYER_3 - Jordy Dreyer.jpg',
           	caption: 'Jordyn Dreyer, *Liv*. 2021. Website Home Page',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_DREYER_4 - Jordy Dreyer.jpg',
           	caption: 'Jordyn Dreyer, *Liv Merchandise*. 2021. Liv Merchandise',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_DREYER_5 - Jordy Dreyer.jpg',
           	caption: 'Jordyn Dreyer, *Advocacy on Your Arm*. 2021. Dexcom Overpatches',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_DREYER_6 - Jordy Dreyer.jpg',
           	caption: 'Jordyn Dreyer, *Liv Find Your Fandom*. 2021. Immersive Poster Design.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_DREYER_7 - Jordy Dreyer.jpg',
           	caption: 'Jordyn Dreyer, *What I wish I Knew*. 2021. Collaborative Project.',
           	price: ''
       	},
{
           	photoUrl: 'VCD_DREYER_8 - Jordy Dreyer.jpg',
           	caption: 'Jordyn Dreyer, *Cute and Chronic instagram filters*. 2021. Instagram screenshots.',
           	price: ''
       	},
{
           	photoUrl: 'https://player.vimeo.com/video/653277836?h=4396a2dc4c',
           	caption: 'Jordyn Dreyer, *Liv Filters*. 2021. Instagram Screenshots.',
           	price: '',
	isVideo: true
       	},
       	{
           	photoUrl: 'https://player.vimeo.com/video/653277858?h=a763724c21',
           	caption: 'Jordyn Dreyer, *Element 6*. 2021. ',
           	price: '',
	isVideo: true
       	}
   	]
   },

   {
   	name: 'Jessica Falconer',
   	type: 'fine', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Feminism & witchcraft',
   	description: 'My work aims to carve out space for the unique knowledge of women and highlight the power a sisterhood of shared experiences holds. My exploration into the home remedies passed through the maternal line of my family furthermore emphasises the connection between humans and the natural world. These works are a collection of memories and conversations with women that are woven together through printmaking, sewing, folding and painting. They are made using organic and unprocessed materials such as plant pigments and vegetable emulsions creating works that are subject to the passing of time and decay.', //max characters 620
   	email: '',
   	insta: 'https://www.instagram.com/jessfalconer/',
   	phone: '0718723999',
   	thesisLink: 'https://drive.google.com/file/d/1UkGxUdUdTMSgO0442z7ihpJ-51VRrs5u/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Falconer_Jessica_Trans.png', // file_name ( must start with  )
   	color: 'red', // color of the background of someones profile image
   	previewedUrl: 'FA_Falconer_1 - Jessica Falconer.jpg', //file_name
   	fixHeight: true, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'FA_Falconer_1 - Jessica Falconer.jpg',
           	caption: `Jessica Falconer, *Bev's Quilt*. 2021. Eco-print, 220 x 220cm.`, //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'FA_Falconer_2 - Jessica Falconer.jpg',
           	caption: 'Jessica Falconer, *Mother Vessel*. 2021. Felt, charcoal, glass beads, clay, embroidery thread, 30 x 20cm. ',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Falconer_3 - Jessica Falconer.jpg',
           	caption: 'Jessica Falconer, *Healing Shroud*. 2021. Felt, charcoal, embroidery thread, clay, herbs, hair, saliva, soil, tears, 250 x 150 cm. ',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Falconer_4 - Jessica Falconer.jpg',
           	caption: 'Jessica Falconer, *Gathering by the river*. 2021. 35mm film photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Falconer_5 - Jessica Falconer.jpg',
           	caption: 'Jessica Falconer, *Marcelle as a moth*. 2021. Felt, charcoal, embroidery thread, clay, glass beads, scrim. 80 x 20cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Falconer_6 - Jessica Falconer.jpg',
           	caption: 'Jessica Falconer, *Herbal Bandages*. 2021. Cotton fabric, beetroot juice, turmeric, eco-printing, embroidery thread, clay, charcoal. 800 x 30cm. ',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Falconer_7 - Jessica Falconer.jpg',
           	caption: 'Jessica Falconer, *Teabags*. 2021. Scrim, dried herbs, embroidery thread. 10 x 10 cm (x10).',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Falconer_8 - Jessica Falconer.jpg',
           	caption: 'Jessica Falconer, *Herbal Bandages*. 2021. Cotton fabric, beetroot juice, turmeric, eco-printing, embroidery thread, clay, charcoal. 800 x 30cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Falconer_9 - Jessica Falconer.jpg',
           	caption: 'Jessica Falconer, *Maternal Remedies*. 2021. Video Project',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Falconer_10 - Jessica Falconer.jpg',
           	caption: 'Jessica Falconer, *Healing Shroud*. 2021.  Felt, charcoal, embroidery thread, clay, herbs, hair, saliva, soil, tears, 250 x 150 cm (detail).',
           	price: ''
       	},
   	]
   },

   {
   	name: 'Joshua Finlay',
   	type: 'fine', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Plasticity',
   	description: 'The term *plasticity* is understood as the designation for an artwork or sculpture that can be changed, adapted or moulded, as well as a technical artistic process of giving and receiving of form. My work focuses on the use of the term *plasticity* in visual art, as both a concept and a method with which to produce work. It also investigates the concept itself, focussing on material’s ability to change physically due to its plasticity.', //max characters 620
   	email: '',
   	insta: 'https://www.instagram.com/bohs_art/',
   	phone: '0768109861',
   	thesisLink: 'https://drive.google.com/file/d/1yXP3FM1oAJzl7FY2RnSR21KtznJ4YbiE/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Finlay_Joshua_Trans.png', // file_name ( must start with  )
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'FA_Finlay_1 - Joshua Finlay.jpg', //file_name
   	fixHeight: true, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'FA_Finlay_1 - Joshua Finlay.jpg',
           	caption: 'Joshua Finlay, *Beyond the Blues no. one*. 2021. Copper plate, sulphuric acid, salt,16.2 x 24.6 cm. (left) *Beyond the Blues no. three*. 2021. Copper plate, sulphuric acid, salt,16.2 x 24.6 cm. (right)', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'FA_Finlay_2 - Joshua Finlay.jpg',
           	caption: 'Joshua Finlay, *Submerged no. one*. 2021. Copper plate, sulphuric acid, salt, 30 x 45.2 cm.',
           	price: 'R500'
       	},
       	{
           	photoUrl: 'FA_Finlay_3 - Joshua Finlay.jpg',
           	caption: 'Joshua Finlay, *Algae and Fungi no. two*. 2021. Copper plate, sulphuric acid, salt, 23 x 30.3 cm. (left) *Algae and Fungi no.one*. 2021. Copper plate, sulphuric acid, salt, 30 x 45.2 cm. (right)',
           	price: 'R500'
       	},
       	{
           	photoUrl: 'FA_Finlay_4 - Joshua Finlay.jpg',
           	caption: 'Joshua Finlay, *Algae and Fungi no. four*. 2021. Copper plate, sulphuric acid, salt, 26 x 27.5 cm.',
           	price: 'R400'
       	},
       	{
           	photoUrl: 'FA_Finlay_5 - Joshua Finlay.jpg',
           	caption: 'Joshua Finlay, *Effulge no. 9*. 2021. Digital collage, 42 x 59.4 cm.',
           	price: 'R500'
       	},
       	{
           	photoUrl: 'FA_Finlay_6 - Joshua Finlay.jpg',
           	caption: 'Joshua Finlay, *Effulge no. 11*. 2021. Digital collage, 42 x 59.4 cm.',
           	price: 'R500'
       	},
       	{
           	photoUrl: 'FA_Finlay_7 - Joshua Finlay.jpg',
           	caption: 'Joshua Finlay ,*Effulge no.12*. 2021. Digital collage, 42 x 59.4 cm.',
           	price: 'R350'
       	},
{
           	photoUrl: 'FA_Finlay_8 - Joshua Finlay.jpg',
           	caption: 'Joshua Finlay, *Effulge no. 15*. 2021. Digital collage, 42 x 59.4 cm.',
           	price: 'R350'
       	},
{
           	photoUrl: 'https://player.vimeo.com/video/653280627?h=1c32793226',
           	caption: 'Joshua Finlay, *Effulge no. 1*. 2021. Video, 29.7 x 42cm..',
           	price: 'R350',
isVideo: true
       	},
       	{
           	photoUrl: 'https://player.vimeo.com/video/653280639?h=951bca9b5b',
           	caption: 'Joshua Finlay, *Effulge no. 3*. 2021. Video, 29.7 x 42cm.',
           	price: '',
isVideo: true
       	}
   	]
   },

   {
   	name: 'Nienke Geldenhuys',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Care and curiosity',
   	description: 'I relay a personal, in-depth knowledge of native species of kelp and seaweeds, which constitutes my own creative practice. I look at these untouched and uncharted landscapes that slow us down, that force us to notice the marks and traces left within its matter. These worlds are in our reach, so close and real, yet it needs us to take up our responsibility as explorers, harvesters, creators, and artisans. This land is in us, it forms part of our veins, it floods our souls and yet it cries out to be seen, to be heard and to be held.', //max characters 620
   	email: '',
   	insta: 'https://www.instagram.com/geldenhuysnienke/',
   	phone: '0786794321',
   	thesisLink: 'https://drive.google.com/file/d/1eZcKAQduQ9xxJrZnIGzh6dtQIxmQtIjf/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Geldenhuys_Nienke_2_Trans.png', // file_name ( must start with  )
   	color: 'red', // color of the background of someones profile image
   	previewedUrl: 'VCD_Geldenhuys_1 - Nienke Geldenhuys.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'VCD_Geldenhuys_1 - Nienke Geldenhuys.jpg',
           	caption: 'Nienke Geldenhuys, *Die gemors en die mooi*. 2021.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'VCD_Geldenhuys_2 - Nienke Geldenhuys.jpg',
           	caption: 'Nienke Geldenhuys, *Spoel*. 2021. Photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Geldenhuys_3 - Nienke Geldenhuys.jpg',
           	caption: 'Nienke Geldenhuys, *Spoel*. 2021. Photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Geldenhuys_4 - Nienke Geldenhuys.jpg',
           	caption: 'Nienke Geldenhuys, *Spoel*. 2021. Photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Geldenhuys_5 - Nienke Geldenhuys.jpg',
           	caption: 'Nienke Geldenhuys, *Spoel*. 2021. Photograph.',
           	price: ''
       	},
{
           	photoUrl: 'VCD_Geldenhuys_6 - Nienke Geldenhuys.jpg',
           	caption: 'Nienke Geldenhuys, *Spoel*. 2021. Photograph.',
           	price: ''
       	},
{
           	photoUrl: 'VCD_Geldenhuys_7 - Nienke Geldenhuys.jpg',
           	caption: 'Nienke Geldenhuys, *Spoel*. 2021.',
           	price: ''
       	},
{
           	photoUrl: 'VCD_Geldenhuys_9 - Nienke Geldenhuys.jpg',
           	caption: 'Nienke Geldenhuys, *Spoel*. 2021. Photograph',
           	price: ''
       	},
{
           	photoUrl: 'VCD_Geldenhuys_10 - Nienke Geldenhuys.jpg',
           	caption: 'Nienke Geldenhuys, *Spoel*. 2021.',
           	price: ''
       	},
{
           	photoUrl: 'https://player.vimeo.com/video/653282143?h=5c50ea97e4',
           	caption: 'Nienke Geldenhuys, *Spoel*. 2021. Animated poster.',
           	price: '',
	isVideo: true
       	}
   	]
   },

   {
   	name: 'Amber Goliath',
   	type: 'fine', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Post Apartheid',
   	description: `Returning as an outsider… By looking at what spaces are being occupied in the community, the viewer gets a sense of the livelihoods through my photographs. Many of its residents were forcefully relocated during Apartheid to Amstelhof, my family as well. I have used photography to capture the raw and immediate moments, looking at the resident’s daily life. It has its hardships but my photographs also focus on its beauty, and its energetic ambience that can be immediately felt once you step foot in Amstelhof. It is a celebration of a community..`, //max characters 620
   	email: 'ambergoliath@gmail.com',
   	insta: 'https://www.instagram.com/amber_rose_goliath/ ',
   	phone: '',
   	thesisLink: 'https://drive.google.com/file/d/1biWQ6eBgYuAkLi4O0p-wcEJK_pheBWnQ/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Goliath_Amber_2_Trans.png', // file_name ( must start with  )
   	color: 'red', // color of the background of someones profile image
   	previewedUrl: 'FA_Goliath_1 - Amber Rose.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'FA_Goliath_1 - Amber Rose.jpg',
           	caption: 'Amber Goliath, *Amstelhof Park*. 2021. Photograph.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'FA_Goliath_2 - Amber Rose.jpg',
           	caption: 'Amber Goliath, *Piet-My-Vrou se Hond*. 2021. Photograph',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Goliath_3 - Amber Rose.jpg',
           	caption: `Amber Goliath, *Willy's Kiosk*. 2021. Photograph.`,
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Goliath_4 - Amber Rose.jpg',
           	caption: 'Amber Goliath, *Tinktinkie Straat*. 2021. Photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Goliath_5 - Amber Rose.jpg',
           	caption: 'Amber Goliath, *Block L4*. 2021. Photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Goliath_6 - Amber Rose.jpg',
           	caption: 'Amber Goliath, *Die vuur brand*. 2021. Photograph.',
           	price: ''
       	}
   	]
   },

   {
   	name: 'Renate Graupe',
   	type: 'fine', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Distorted Memory',
   	description: '*Memories of Lost Objects* works with the change of a physical object to a memory of the object, and this memory becomes distorted. This distorted memory is visually shown in this work. *Thoughts on Lost Objects* are drawings where I dwell on the memories, trying to reach out to the original memories before the distortion, trying to find the original comfort that has been lost. *Altering Associations* is a revisiting of my childhood home, and how the space has become other. Through this work I build an understanding behind the change, creating a space that feels calming, bringing back the originally associated feelings.', //max characters 620
   	email: 'renate.natalia@gmail.com',
   	insta: 'https://www.instagram.com/renateart/',
   	phone: '',
   	thesisLink: 'https://drive.google.com/file/d/1RVd2mitSFEvr9dCCUPzDQx1hopwe4ruM/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Graupe_Renate_Trans.png', // file_name ( must start with  )
   	color: 'pink', // color of the background of someone's profile image
   	previewedUrl: 'FA_Graupe_2 - Renate Graupe.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than wider, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'FA_Graupe_1 - Renate Graupe.jpg',
           	caption: 'Renate Graupe, *Altering Associations Installation*. 2021. Light, Shadow, Mild Steel, Nails, Rope.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'FA_Graupe_2 - Renate Graupe.jpg',
           	caption: 'Renate Graupe, *Thoughts on Lost Objects*. 2021. Grease, Metal Filings, Paper.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Graupe_3 - Renate Graupe.jpg',
           	caption: 'Renate Graupe, *Memories of Lost Objects*. 2021. Light, Shadow.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Graupe_4 - Renate Graupe.jpg',
           	caption: 'Renate Graupe, *Memories of Lost Objects*. 2021. Light, Shadow.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Graupe_5 - Renate Graupe.jpg',
           	caption: 'Renate Graupe, *Lost Object #2*. 2021. Nails, Glue.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Graupe_6 - Renate Graupe.jpg',
           	caption: 'Renate Graupe, *Lost Object #1*. 2021. Nails, Glue.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Graupe_7 - Renate Graupe.jpg',
           	caption: 'Renate Graupe, *Lost Object #3*. 2021. Nails, Glue.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Graupe_8 - Renate Graupe.jpg',
           	caption: ' Renate Graupe, *Altering Associations Installation*. 2021. Light, Shadow, Mild Steel, Nails, Rope..',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Graupe_9 - Renate Graupe.jpg',
           	caption: 'Renate Graupe, *Altering Associations Installation*. 2021. Light, Shadow, Mild Steel, Nails, Rope.',
           	price: ''
       	},
        {
           	photoUrl: 'https://player.vimeo.com/video/653282959?h=bc460a3f21',
           	caption: 'Renate Graupe, *Lost Objects*. 2021. FIlm',
           	price: '',
	        isVideo: true
       	}
   	]
   },

   {
   	name: 'Shannon Grobler',
   	type: 'jewelry', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Capitalism',
   	description: 'My contemporary jewellery practise is an exploration of the consumer aesthetics which have been perpetuated throughout commercial jewellery. I critique the overuse of polished surfaces and manipulative marketing that targets the human desire for reflective surfaces as it mimics clean water. The visual inspiration for my work is the Browns Protea Solitaire Diamond Ring and the King Protea itself as both are widely recognised in South Africa. My work is intended to create a space for the wearer to engage with their own taste and become mindful of the sameness that the commercial jewellery industry provides.', //max characters 620
   	email: 'sweettpea.designs@gmail.com',
   	insta: ' https://www.instagram.com/sweetpea_jewellery/',
   	phone: '',
   	thesisLink: 'https://drive.google.com/file/d/1LbCTFDlXwynBv6I4SQ9fQZBfHSJr-oY7/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Grobler_Shannon_Trans.png', // file_name ( must start with  )
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'JD_Grobler_8 - Shan G.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'JD_Grobler_1 - Shan G.jpg',
           	caption: 'Shannon Grobler, *Breaking Convention Broach*. 2021. Porcelain, Sterling Silver, steel.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'JD_Grobler_2 - Shan G.jpg',
           	caption: 'Shannon Grobler, *Breaking Convention Earrings*. 2021. Porcelain, Sterling Silver, Resin.',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_Grobler_3 - Shan G.jpg',
           	caption: 'Shannon Grobler, *Internal*. 2021. Plastic, Glass, Sterling silver, Chord.',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_Grobler_4 - Shan G.jpg',
           	caption: 'Shannon Grobler, *Breaking Convention Necklace*. 2021. Porcelain, Sterling Silver, Cubic Zirconia, Chord, resin.',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_Grobler_5 - Shan G.jpg',
           	caption: 'Shannon Grobler, *Breaking Convention Necklace*. 2021. Porcelain, Sterling Silver, Cubic Zirconia, Chord, resin.',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_Grobler_6 - Shan G.jpg',
           	caption: 'Shannon Grobler, *Distorted*. 2021. Plastic, Sterling Silver, Cubic Zirconia, chord, Magnets.',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_Grobler_7 - Shan G.jpg',
           	caption: 'Shannon Grobler, *Yellow Gold*. 2021. Porcelain, Sterling Silver, Cubic Zirconia, Chord.',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_Grobler_8 - Shan G.jpg',
           	caption: 'Shannon Grobler, *Yellow Gold*. 2021. Porcelain, Sterling Silver, Cubic Zirconia, Chord.',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_Grobler_9 - Shan G.jpg',
           	caption: 'Shannon Grobler, *Strung Along*.2021. Plastic, Chord, Sterling Silver.',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_Grobler_10 - Shan G.jpg',
           	caption: 'Shannon Grobler, *Strung along*. 2021. Porcelain, Sterling Silver, Silk Thread, Tourmaline.',
           	price: ''
       	},
   	]
   },

   {
   	name: 'Kirsten Hector',
   	type: 'fine', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Metaphors & identity',
   	description: '*Interwoven* explores the multiple intertwined threads and layers of textiles as a metaphor medium. The tangible object of textiles and the medical science discipline of histology forms this metaphorical relationship, whereby histology is the microanatomy study of biological tissue, with “tissue” derived and translating to “woven” in French. Investigations into the interconnected relationships between cultural significances and identity, personal associations, time and themes of femininity through the usage of particular textiles, colours and processes considering nurturing and delicate qualities are observed.', //max characters 620
   	email: 'kirstenhector27@gmail.com',
   	insta: ' https://www.instagram.com/kirsten_hector/',
   	phone: '',
   	thesisLink: 'https://drive.google.com/file/d/1ej1XcnP_us-aM10D6pT07XqKcehliHdl/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Hector_Kirsten_Trans.png', // file_name ( must start with  )
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'FA_Hector_2 - Kirsten Hector.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'FA_Hector_1 - Kirsten Hector.jpg',
           	caption: 'Kirsten Hector, *Bloom*. 2021. Artificial flowers, thread and beads, 240cm x 122cm x 6,5cm.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'FA_Hector_2 - Kirsten Hector.jpg',
           	caption: 'Kirsten Hector, *Bloom* (detail). 2021. Artificial flowers, thread and beads',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Hector_3 - Kirsten Hector.jpg',
           	caption: 'Kirsten Hector, *Bloom* (detail). 2021. Artificial flowers, thread and beads.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Hector_4 - Kirsten Hector.jpg',
           	caption: 'Kirsten Hector, *Home*. 2021. Artificial flowers, chicken wire and pantyhose, 80cm x 36cm x 69cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Hector_5 - Kirsten Hector.jpg',
           	caption: 'Kirsten Hector, *Skin*. 2021. Pantyhose, wool, copper wire, embroidery thread, cotton fabric, 100cm x 79cm x 29cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Hector_6 - Kirsten Hector.jpg',
           	caption: 'Kirsten Hector, *Untitled (bold printed flowers 2)*. 2021. Ink on nylon fabric, 56cm x 50cm',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Hector_7 - Kirsten Hector.jpg',
           	caption: 'Kirsten Hector, *Scintilla*. 2021. Wool, beads, ribbon and cotton fabric, 26cm x 20cm x 1,5cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Hector_8 - Kirsten Hector.jpg',
           	caption: 'Kirsten Hector, *Micro observation 2*. 2021. Ink on paper, 21cm x 21cm',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Hector_9 - Kirsten Hector.jpg',
           	caption: 'Kirsten Hector, *Woven flowers*. 2021.Wool, artificial flowers, silk ribbon and cotton fabric, 37cm x 28cm x 3cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Hector_10 - Kirsten Hector.jpg',
           	caption: 'Kirsten Hector, *Entwine*. 2021. Pantyhose, cotton fabric and wool, 30cm x 26cm x 1,5cm.',
           	price: ''
       	},
   	]
   },

   {
   	name: 'Shinae Hwang',
   	type: 'fine', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Sustainability',
   	description: 'My work explores the human-made layer of the Anthropocene by documenting the negative trace of humanity on the earth. The planet has become increasingly impoverished as a function of man’s technological development and industrialisation. My personal artistic interpretation of the Anthropocene will also be conducted by investigating the new material, Plastiglomerate, which is intensely layered.', //max characters 620
   	email: 'shinae9710@gmail.com',
   	insta: 'https://www.instagram.com/heidnodne/?utm_medium=copy_link',
   	phone: '',
   	thesisLink: 'https://drive.google.com/file/d/1N54XiZi98NQoRT-cUXQP20fiCryCz2aw/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Hwang_Shinae_Trans.png', // file_name ( must start with  )
   	color: 'red', // color of the background of someones profile image
   	previewedUrl: 'FINEART_Hwang_3 - Shinae Hwang.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'FINEART_Hwang_1 - Shinae Hwang.jpg',
           	caption: 'Shinae Hwang, *The Food in 3021*. 2021.Documentation of waste the artist created during a day. ', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'FINEART_Hwang_2 - Shinae Hwang.jpg',
           	caption: 'Shinae Hwang, *Untitled*. 2021. Digital collage.',
           	price: ''
       	},
       	{
           	photoUrl: 'FINEART_Hwang_3 - Shinae Hwang.jpg',
           	caption: 'Shinae Hwang, *Untitled*. 2021. Digital collage.',
           	price: ''
       	},
       	{
           	photoUrl: 'FINEART_Hwang_4 - Shinae Hwang.jpg',
           	caption: 'Shinae Hwang, *The Trash World*. 2021. Digital collage.',
           	price: ''
       	},
       	{
           	photoUrl: 'FINEART_Hwang_5 - Shinae Hwang.jpg',
           	caption: 'Shinae Hwang, *The Earth 3021*. 2021. Digital collage.',
           	price: ''
       	},
       	{
           	photoUrl: 'FINEART_Hwang_6 - Shinae Hwang.jpg',
           	caption: 'Shinae Hwang, *SEOUL 3021 {2}*. 2021. Digital collage.',
           	price: ''
       	},
       	{
           	photoUrl: 'FINEART_Hwang_7 - Shinae Hwang.jpg',
           	caption: 'Shinae Hwang, *SEOUL 3021 {1}*. 2021. Digital collage.',
           	price: ''
       	},
       	{
           	photoUrl: 'FINEART_Hwang_8 - Shinae Hwang.jpg',
           	caption: 'Shinae Hwang, *A Digestive Tube*. 2021. A flexible sculpture made with a voile and worn-out clothes, Digital collage.',
           	price: ''
       	},
       	{
           	photoUrl: 'FINEART_Hwang_9 - Shinae Hwang.jpg',
           	caption: 'Shinae Hwang, *Obesity*. 2021. Performance. Screenshot by author.',
           	price: ''
       	},
{
           	photoUrl: 'https://player.vimeo.com/video/653285587?h=c18b519ca5',
           	caption: 'Shinae Hwang, *Untitled*. 2021. Video.',
           	price: '',
	isVideo: true
       	}
   	]
   },

 {
   	name: 'Erin Johnston',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Magic',
   	description: 'For centuries, our sense of reality has been governed and limited by European ontologies such as science. Everything that fell outside this ideological regime was labelled as ‘magic’, a contrast term to the ‘real’. As a result, magic has been dismissed as fiction and dangerously irrational. My practice this year has been exploring spellcasting as a design practice, as something that manifests the Other within us. Under my initiative *Juji*, I asked participants to create strange creatures to reinvent their relationship with the non-human, creating costumes out of their ideas and then photographing them.', //max characters 620
   	email: 'erindanjohn@gmail.com',
   	insta: 'https://www.instagram.com/erin_johnston_/',
   	phone: '061 351 9188',
   	thesisLink: 'https://drive.google.com/file/d/1nDinexjdBdZ6uec5VNVAWQhOShsBtwp2/view?usp=sharing',
   	portfolio: 'https://www.behance.net/erinjohnston',
   	profileUrl: 'Johnston_Erin_2_Trans.png', // file_name ( must start with  )
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'VCD_Johnston_5 - Edge Graphics.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'VCD_Johnston_1 - Edge Graphics.jpg',
           	caption: 'Erin Johnston, *Juji Brand Grimoire*. 2021. 21 x 21cm. ', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'VCD_Johnston_2 - Edge Graphics.jpg',
           	caption: 'Erin Johnston, *Personal Branding: Edge Graphics*. 2021. Photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Johnston_3 - Edge Graphics.jpg',
           	caption: 'Erin Johnston, *Design as Magic*. 2021.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Johnston_4 - Edge Graphics.jpg',
           	caption: 'Erin Johnston, *Poster*. 2021. Digital collage.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Johnston_5 - Edge Graphics.jpg',
           	caption: 'Erin Johnston, *Red and Blue*. 2021. Photograph, 29,7 x 42 xm.',
           	price: 'R200'
       	},
       	{
           	photoUrl: 'VCD_Johnston_6 - Edge Graphics.jpg',
           	caption: 'Erin Johnston, *Neppy*. 2021. Photograph, 29,7 x 42 xm.',
           	price: 'R200'
       	},
       	{
           	photoUrl: 'VCD_Johnston_7 - Edge Graphics.jpg',
           	caption: 'Erin Johnston, *Darliphie*. 2021. Photograph, 29,7 x 42 xm.',
           	price: 'R200'
       	},
       	{
           	photoUrl: 'VCD_Johnston_8 - Edge Graphics.jpg',
           	caption: 'Erin Johnston, *The Brain*. 2021. Photograph, 29,7 x 42 xm.',
           	price: 'R200'
       	},
       	{
           	photoUrl: 'VCD_Johnston_9 - Edge Graphics.jpg',
           	caption: 'Erin Johnston, *Mudayqa*. 2021. Photograph, 29,7 x 42 xm.',
           	price: 'R200'
       	},
        {
           	photoUrl: 'https://player.vimeo.com/video/653277079?h=eb2b17ca19',
           	caption: 'Erin Johnston, *Website Design*. 2021.',
           	price: '',
	        isVideo: true
       	},
   	]
   },

   {
   	name: 'Alexa Jooste',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Greenwashing',
   	description: 'This year my design practice has been centred around ideas relating to environmentally sustainable design within the grocery retail sector and in particular greenwashing. Greenwashing is an umbrella term that refers to a variety of marketing tactics used by companies and corporations to appear green and or environmentally friendly when in fact they are not. Through design, I have explored many different avenues of greenwashing, from educating consumers on the topic to holding companies accountable for their misinformation.', //max characters 620
   	email: 'jooste.alexa@gmail.com',
   	insta: '',
   	phone: '0797855513',
   	thesisLink: 'https://drive.google.com/file/d/1dm1dy-d3W4bL6-cp6k0HQx-CDqY2W_57/view?usp=sharing',
   	portfolio: 'https://indd.adobe.com/view/e3a01c6c-aa98-4557-8ad8-7c4502900b37',
   	profileUrl: 'Jooste_Alexa_Trans.png', // file_name ( must start with  )
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'VCD_Jooste_2 - Alexa Jooste.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'VCD_Jooste_1 - Alexa Jooste.jpg',
           	caption: 'Alexa Jooste, *Greenwashing Do’s and Don`ts 1*. 2021. Photograph.',
           	price: '',
       	},
       	{
           	photoUrl: 'VCD_Jooste_2 - Alexa Jooste.jpg',
           	caption: 'Alexa Jooste, *Come Greenwash With Us 1*. 2021. Photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Jooste_3 - Alexa Jooste.jpg',
           	caption: 'Alexa Jooste, *Come Greenwash With Us 2*. 2021. Photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Jooste_4 - Alexa Jooste.jpg',
           	caption: 'Alexa Jooste, *Come Greenwash With Us 3*. 2021. Photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Jooste_6 - Alexa Jooste.jpg',
           	caption: 'Alexa Jooste, *Where Do Your Clothes Come From?*. Collage. 37.7 x 28.3 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Jooste_7 - Alexa Jooste.jpg',
           	caption: 'Alexa Jooste, *Where Does Your Petrol Come From?*. Collage. 37.7 x 28.3 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Jooste_8 - Alexa Jooste.jpg',
           	caption: 'Alexa Jooste, *Where Does Your Favourite Chocolate Come From?*. Collage. 37.7 x 28.3 cm.',
           	price: ''
       	},
        {
           	photoUrl: 'https://player.vimeo.com/video/653277306?h=b189df51db',
           	caption: 'Alexa Jooste, *Greenwashing Decoded*. 2021. Animation.',
           	price: '',
	        isVideo: true
       	},
       	{
           	photoUrl: 'https://player.vimeo.com/video/653277375?h=de732c92e5',
           	caption: 'Alexa Jooste, *Come Greenwash With Us*. 2021. Video.',
           	price: '',
	        isVideo: true
       	}
   	]
   },

   {
   	name: 'Tor Kaplan',
   	type: 'jewelry', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Adults need to play.',
   	description: `My body of work is a practical investigation as to how I can encourage adults to play once again using jewellery and craft as a medium. My design process is inspired by children’s toys and their ability to play freely with them. I have taken to basic objects and materials that are commonly used in children's toys and crafts and modified them to be incorporated into my creative research for my body of work as a jeweller. The materials I have chosen to complement silver are wood, Perspex, porcelain, glass beads and cotton pearl silk.`, //max characters 620
   	email: 'torykap@gmail.com',
   	insta: 'https://www.instagram.com/tor_not_thor/ ',
   	phone: '',
   	thesisLink: 'https://drive.google.com/file/d/1DrKNLbPC_cXbFJxAyvmW7RDrtyvDTkkv/view?usp=sharing',
   	portfolio: 'https://www.behance.net/torjayniekaplan11/',
   	profileUrl: 'Kaplan_Tor_Trans.png', // file_name ( must start with  )
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'JD_Kaplan_4 - Tor Kaplan.jpg', //file_name
   	fixHeight: true, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'JD_Kaplan_1 - Tor Kaplan.jpg',
           	caption: 'Tor Kaplan, *blocks*. 2021 10x10 cm', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'JD_Kaplan_2 - Tor Kaplan.jpg',
           	caption: 'Tor Kaplan, *wooden brooch*. 2021 10x15cm',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_Kaplan_3 - Tor Kaplan.jpg',
           	caption: 'Tor Kaplan, *wooden brooch*. 2021 10x15cm',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_Kaplan_4 - Tor Kaplan.jpg',
           	caption: 'Tor Kaplan, *stackable rings*. 2021 3x2x0.3 cm',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_Kaplan_5 - Tor Kaplan.jpg',
           	caption: 'Tor Kaplan, *creature earrings*. 2021 6x4 cm',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_Kaplan_6 - Tor Kaplan.jpg',
           	caption: 'Tor Kaplan, *conceptual lego*. 2021 8x10cm',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_Kaplan_7 - Tor Kaplan.jpg',
           	caption: 'Tor Kaplan, *noodle nckpiece*. 2021 10x10cm',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_Kaplan_8 - Tor Kaplan.jpg',
           	caption: 'Tor Kaplan, *hugger 1*. 2021 5x3cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_Kaplan_9 - Tor Kaplan.jpg',
           	caption: 'Tor Kaplan, *hugger 2*. 2021 6x4cm',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_Kaplan_10 - Tor Kaplan.jpg',
           	caption: 'Tor Kaplan, *ceramic work*. 2021',
           	price: ''
       	},
   	]
   },

   {
   	name: 'Roxanne Keeping',
   	type: 'fine', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Place Attachment',
   	description: '*The Blue* - "the sea, or the unknown" I have a tendency to develop attachments to spaces. Some of these spaces become places that I visit and revisit repeatedly. The ocean is a place that I am drawn to time and time again. I feel a strong pull towards the blue - the water, the waves, the salt, and the sand. I experience seascapes as an extension of my emotional self. I consider its many parts, its moods, to be reiterations of fractions of me. *The Blue* is a phantasmagoria of blue and an ode to the ocean which serves as a documentation of time, place and attachment.', //max characters 620
   	email: 'roxykeeping@gmail.com',
   	insta: 'https://www.instagram.com/roxykeeping1/',
   	phone: '',
   	thesisLink: 'https://drive.google.com/file/d/1zizEbJ_1EGgKGgOPoD2oS_-9ItvrBPpy/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Keeping_Roxanne_Trans.png', // file_name ( must start with  )
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'FA_Keeping_1.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than wider, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'FA_Keeping_1.jpg',
           	caption: 'Roxanne Keeping, *Blue Dream*. 2021. Cyanotype prints on board. 178.2  ×  253.2 cm', //max characters 280
           	price: '',
       	}, 
{
           	photoUrl: 'FA_Keeping_2.jpg',
           	caption: 'Roxanne Keeping, *Water* (Triptych). 2021. Cyanotype print on 100% cotton fabric, wooden rods. 59.4 x 84.1 cm.',
           	price: 'R1500'
       	},
{
           	photoUrl: 'FA_Keeping_3.jpg',
           	caption: 'Roxanne Keeping, *Salt* (Triptych). 2021. Cyanotype print on paper. 59.4 x 84.1 cm..',
           	price: 'R1000'
       	},
{
           	photoUrl: 'FA_Keeping_4.jpg',
           	caption: 'Roxanne Keeping, *Water* (Triptych). 2021. Cyanotype print on 100% cotton fabric, wooden rods. 59.4 x 84.1 cm.',
           	price: 'R1500'
       	},
{
           	photoUrl: 'FA_Keeping_5.jpg',
           	caption: 'Roxanne Keeping, *Water* (Triptych). 2021. Cyanotype print on 100% cotton fabric, wooden rods. 59.4 x 84.1 cm.',
           	price: 'R1500'
       	},
{
           	photoUrl: 'FA_Keeping_6.jpg',
           	caption: 'Roxanne Keeping, *Salt* (Triptych). 2021. Cyanotype print on paper. 59.4 x 84.1 cm.',
           	price: 'R1000'
       	},
{
           	photoUrl: 'FA_Keeping_7.jpg',
           	caption: 'Roxanne Keeping, *Blue Dream* (details). 2021. Cyanotype prints on board.178.2  ×  253.2 cm',
           	price: 'Prices of Individual prints available on request'
       	},
{
           	photoUrl: 'FA_Keeping_8.jpg',
           	caption: 'Roxanne Keeping, *Salt* (Triptych). 2021. Cyanotype print on paper. 59.4 x 84.1 cm.',
           	price: 'R1000'
       	},
{
           	photoUrl: 'https://player.vimeo.com/video/653282724?h=cd38099f45',
           	caption: 'Roxanne Keeping, *Adrift*. 2021. Video.',
           	price: '',
	isVideo: true
       	},
{
           	photoUrl: 'https://player.vimeo.com/video/653282768?h=b3789e4433',
           	caption: 'Roxanne Keeping, *The Blue*. 2021. Artist Video.',
           	price: '',
	isVideo: true
       	}
   	]
   },

   {
   	name: 'Robynne Lewis',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Mental Health',
   	description: `My works focus on exploring and bringing awareness to how COVID-19 has affected the mental well-being of South African students with my year initiative Peep and has aimed to use art and design as a form of both therapy and communication in order to express one’s true, raw and authentic emotions and self. The initiative does not aim to cure the issues being dealt with but has strived to encourage the practice of art as a form of catharsis and alternative method of communication.`, //max characters 620
   	email: 'robynnea.lewis@gmail.com',
   	insta: 'https://www.instagram.com/its_me_bobbert/',
   	phone: '',
   	thesisLink: 'https://drive.google.com/file/d/1K7bBUZBG2vRGeFBvQ5XCCiGcH1yVL5NO/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Lewis_Robynne_Trans.png', // file_name ( must start with  )
   	color: 'red', // color of the background of someones profile image
   	previewedUrl: 'VCD_Lewis_14 - Rob Lewis.jpg', //file_name
   	fixHeight: true, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'VCD_Lewis_11 - Rob Lewis.jpg',
           	caption: 'Robynne Lewis, *Augmented Reality mockup infographic Cape Town*. 2021. Information Design.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'VCD_Lewis_12 - Rob Lewis.jpg',
           	caption: 'Robynne Lewis, *Lustre*. 2021.Personal Brand.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Lewis_14 - Rob Lewis.jpg',
           	caption: 'Robynne Lewis, *A tomographic scan of emotional well- being*. 2021. Information Design.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Lewis_16 - Rob Lewis .jpg',
           	caption: 'Robynne Lewis, *Exploring Artistic Expression of South African Students under COVID- 19 as a form of art therapy*. 2021. Book Design.',
           	price: ''
       	},
        {
           	photoUrl: 'https://player.vimeo.com/video/653276519?h=bbff2e510f',
           	caption: 'Robynne Lewis, *Peep Branding*. 2021. Logo Animation.',
           	price: '',
            isVideo: true
       	},
       	{
           	photoUrl: 'VCD_Lewis_17 - Rob Lewis.jpg',
           	caption: 'Robynne Lewis, *A microscopic scan of emotional well- being 2*. 2021. Information Design.',
           	price: ''
       	},
        {
           	photoUrl: 'https://player.vimeo.com/video/653278116?h=0fab9a6783',
           	caption: 'Robynne Lewis, *Surveillance of the mind and self*. 2021. Visual Excavation video.',
           	price: '',
            isVideo: true
       	}
]
   },

   {
   	name: 'Ankia Lloyd',
   	type: 'fine', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Liminality',
   	description: `*Calon y blaidd* is a body of work inspired by liminality and meditative states found within the processes of my artistic practice. The artworks are like a map indicating the starting point and the finished outcome. Liminality is ‘in-between’ or ‘being on the threshold of’ spaces. This work refers to the steps of creating an artwork and the stages of life that influence these processes. The wolf imagery is a metaphor and representation of myself, it is further inspired by the Welsh mythology where wolves are seen as a ‘guide’ and ‘helper’.`, //max characters 620
   	email: 'ankiawolfie@gmail.com',
   	insta: 'https://www.instagram.com/wolfie_artpage/',
   	phone: '',
   	thesisLink: 'https://drive.google.com/file/d/1Lvj9AoOp_3UQ3j0eBeGrtjPzmfELEmiG/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Lloyd_Ankia_Trans.png', // file_name ( must start with  )
   	color: 'red', // color of the background of someones profile image
   	previewedUrl: 'FA_Lloyd_1.jpg', //file_name
   	fixHeight: true, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'FA_Lloyd_1.jpg',
           	caption: 'Ankia Lloyd, *Process work wolf portrait*. 2021. Watercolour monotype screenprint, 7,4 x 14,8 cm.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'FA_Lloyd_2.jpg',
           	caption: 'Ankia Lloyd, *wolf portrait*. Watercolour monotype screenprint, 7,4 x 14,8 cm (per print).',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Lloyd_3.jpg',
           	caption: 'Ankia Lloyd, *calon y blaidd*. 2021. Cement, hessian and steel.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Lloyd_4.jpg',
           	caption: `Ankia Lloyd, *'di s soc iat i on'*. 2021. Watercolour monotype screenprint, 14,8 x 21 cm (per image).`,
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Lloyd_5.jpg',
           	caption: 'Ankia Lloyd, *calon y blaidd*. 2021. Cement, hessian and steel.',
           	price: ''
       	},
{
           	photoUrl: 'FA_Lloyd_6.jpg',
           	caption: 'Ankia Lloyd, *liminal wolf*. 2021. Steel rods and chicken wire.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Lloyd_7.jpg',
           	caption: 'Ankia Lloyd, *wolf layer 1*. 2021. Watercolour monotype screenprint, 29,7 x 42 cm.',
           	price: ''
       	}
   	]
   },

   {
   	name: 'Chanel Lombard',
   	type: 'fine', // One of these three ONLY: jewelry, fine, visual
   	problem: 'The disabled body',
   	description: 'The body of work aims to project my own understanding of what form and formlessness are, through the exploration of human frailty and sensitive moments. I was born with physical impairments that altered my perception of form / formlessness, therefore this work becomes an extension of my own body. My work is a series of self-portraits that explore the ongoing changes within the body, from a molecular scale to physical changes. As the artist, I alter the physical form of the natural materials in my work, which then changes and adapts to its environment through time.', //max characters 620
   	email: 'chanel.lombard@icloud.com',
   	insta: 'https://www.instagram.com/chanel_lombard/',
   	phone: '079 712 7044',
   	thesisLink: 'https://drive.google.com/file/d/13_-qAqxW9ZZ3r1UrIkdE-6z11uqyvlMa/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Lombard_Chanel_2_Trans.png', // file_name ( must start with  )
   	color: 'red', // color of the background of someones profile image
   	previewedUrl: 'FA_LOMBARD_7 - Chanel Lombard.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'FA_LOMBARD_1 - Chanel Lombard.jpg',
           	caption: 'Chanel Lombard, *Cellular Complexity*. 2021. SCOBY, 10x10 cm', //max characters 280
           	price: 'Rx',
       	},
       	{
           	photoUrl: 'FA_LOMBARD_2 - Chanel Lombard.jpg',
           	caption: 'Chanel Lombard, *Cellular Complexity*. 2021. SCOBY, dimensions vary',
           	price: 'Rx'
       	},
       	{
           	photoUrl: 'FA_LOMBARD_3 - Chanel Lombard.jpg',
           	caption: 'Chanel Lombard, *Untitled*. 2021. Clay piece, 7x8x5 cm',
           	price: 'Rx'
       	},
       	{
           	photoUrl: 'FA_LOMBARD_4 - Chanel Lombard.jpg',
           	caption: 'Chanel Lombard, *Untitled*. 2021. Clay piece, 8x10x6 cm',
           	price: 'Rx'
       	},
       	{
           	photoUrl: 'FA_LOMBARD_5 - Chanel Lombard.jpg',
           	caption: 'Chanel Lombard, *Untitled*. 2021. Clay piece, dimensions vary',
           	price: 'R150 per clay piece'
       	},
       	{
           	photoUrl: 'FA_LOMBARD_6 - Chanel Lombard.jpg',
           	caption: 'Chanel Lombard, *Self-Portrait*. 2021. Bread, mould & saliva, 3x3x2 cm',
           	price: 'Rx'
       	},
       	{
           	photoUrl: 'FA_LOMBARD_7 - Chanel Lombard.jpg',
           	caption: 'Chanel Lombard, *Self-Portrait*. 2021. Bread, mould & saliva, 3x2x1,5 cm',
           	price: 'Rx'
       	},
       	{
           	photoUrl: 'FA_LOMBARD_8 - Chanel Lombard.jpg',
           	caption: 'Chanel Lombard, *Listen to me*. 2021. Plaster, 10x10x3 cm each',
           	price: 'R200 per plaster tile'
       	},
  	{
           	photoUrl: 'FA_LOMBARD_9 - Chanel Lombard.jpg',
           	caption: 'Chanel Lombard, *Microcosmos*. 2021. Monoprint on Fabriano, 10x10 cm each',
           	price: 'R200 per print'
       	},
       	{
           	photoUrl: 'https://player.vimeo.com/video/653284262?h=eb0db360f8',
           	caption: 'Chanel Lombard, *F O R M - L E S S - N E S S*. 2021. Video, 00:01:42',
           	price: '', 
	isVideo: true
       	}
   	]
   },

   {
   	name: 'Kelsey Lee Manners Dickson',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'body water connection',
   	description: 'My brand initiative *Surge* encourages curiosity and imagination. It values the space between absorbing visuals and interpreting an outcome, then going beyond the visual & into a physical underwater experience. My work is not focused on being the Anthropocene ‘solution’ to the multitudes of oceanic crises, but rather a proposition to encourage thought and awareness on perspectives of self and the sea. *Surge* celebrates the underwater world and human bodies as one. I am able to tackle the self-centred anthropogenic way of thinking in this epoch and bring awareness to marine conservation.', //max characters 620
   	email: 'kelsdickson.kd@gmail.com',
   	insta: 'https://www.instagram.com/kels_dickson/',
   	phone: '',
   	thesisLink: 'https://drive.google.com/file/d/1KiD3ezfYFed7PnKV1HG5ENrGZ8fA0jPz/view?usp=sharing',
   	portfolio: 'https://www.behance.net/kelseydickson',
   	profileUrl: 'Manners_Dickson_Kelsey_Trans.png', // file_name ( must start with  )
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'VCD_MannersDickson_3.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than wider, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'VCD_MannersDickson_1.jpg',
           	caption: 'Kels D, *Poster Series*. 2021. Illustrated Posters.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'VCD_MannersDickson_2.jpg',
           	caption: 'Kels D,  *Book Design*. 2021. Book.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_MannersDickson_4.jpg',
           	caption: 'Kels D, *Book Design*. 2021. Book.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_MannersDickson_5.jpg',
           	caption: 'Kels D, *Wearable sculptures*. 2021. Foam and Clay. Sculpture.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_MannersDickson_6.jpg',
           	caption: 'Kels D, *Wearable sculpture portrait*. 2021. Foam and Clay. Sculpture.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_MannersDickson_7.jpg',
           	caption: 'Kels D, *Free-diving Physiology*. 2021.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_MannersDickson_8.jpg',
           	caption: 'Kels D, *I Wish You Water*. 2021. Card game design.',
           	price: ''
       	}
   	]
   },

   {
   	name: 'Michaela Miller',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Sustainable Fashion',
   	description: `This project focuses on slowing down and making the most of what we consume. It aims to frame mending in an altruistic light; to challenge the outdated perception of visibly repaired clothes by increasing the visibility of repairs while redefining their significance; to suggest the importance of well-being in the pursuit of sustainability. My initiative *Darn!* creates spaces to disrupt consumer culture and embrace the imperfect; to make repair, the act of repair and the stories behind them more visible. I was inspired by Yoko Ono’s *Mend Piece*. She provides a quote that encapsulates the core of my initiative.`, //max characters 620
   	email: '',
   	insta: 'https://www.instagram.com/michaelamiller8',
   	phone: '0743691623',
   	thesisLink: 'https://drive.google.com/file/d/1jgs95NTAsFkDRImW-MBDvmnPrGQs9Km7/view?usp=sharing',
   	portfolio: 'https://www.behance.net/michaelamiller7',
   	profileUrl: 'Miller_Michaela_2_Trans.png', // file_name ( must start with  )
   	color: 'red', // color of the background of someones profile image
   	previewedUrl: 'VCD_Miller_1 - michaela miller.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than wider, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'VCD_Miller_1 - michaela miller.jpg',
           	caption: 'Michaela Miller, *Remnants*. 2021. Digital collage. 14,8 x 21 cm.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'https://player.vimeo.com/video/653276856?h=e08310d6d9',
           	caption: 'Michaela Miller, *Make and Mend*. 2021. Poster series. 42 x 59,4 cm.',
           	price: '',
	isVideo: true
       	},
       	{
           	photoUrl: 'VCD_Miller_3 - michaela miller.jpg',
           	caption: 'Michaela Miller, *Creative Repair Kit*. 2021. Experience kit. 14,8 x 21 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Miller_4 - michaela miller.jpg',
           	caption: 'Michaela Miller, *Participants Makings*. 2021. Collage poster. 29,7 x 42 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Miller_5 - michaela miller.jpg',
           	caption: 'Michaela Miller, *Making a Mends*. 2021. Book. 14,8 x 21 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Miller_6 - michaela miller.jpg',
           	caption: 'Michaela Miller, *Darn Branding*. 2021. Image of collateral.',
           	price: ''
       	},
       	{
           	photoUrl: 'https://player.vimeo.com/video/653276874?h=0d4d7e955d',
           	caption: 'Michaela Miller, *Making a Mends*. 2021. Title sequence video. 67,73 x 38,1 cm.',
           	price: '',
	isVideo: true
       	}
   	]
   },

   {
   	name: 'Tayla Milne',
   	type: 'fine', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Strenuous labour',
   	description: '"Hanging on by a Thread" transpired through the use of the medium. My inspiration came directly from my grandmother and how she was taught to use a sewing machine. As I became more experienced working with garments, I became aware of just how labour intensive and strenuous the process can be. It speaks about how the process of working within the fabric and textile industry can become so exhaustive that it consumes the soul and leaves fragments of the person behind. Yet people still hang on and stay connected through the disconnectedness, leaving many hanging onto their livelihoods by a thread.', //max characters 620
   	email: 'tayla.milne@yahoo.com',
   	insta: 'https://www.instagram.com/tayla_milne_art/',
   	phone: '0824708788',
   	thesisLink: '',
   	portfolio: '',
   	profileUrl: 'Milne_Tayla_Trans.png', // file_name ( must start with  )
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'FA_Milne_1 - Tayla Milne.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'FA_Milne_1 - Tayla Milne.jpg',
           	caption: 'Tayla Milne, *Hanging on by a Thread*. 2021. Water soluble fabric, cotton thread and steel rod, 182 x 67 x 69 cm.', //max characters 280
           	price: 'R20 000',
       	},
       	{
           	photoUrl: 'FA_Milne_4 - Tayla Milne.jpg',
           	caption: 'Tayla Milne, *Hanging on by a Thread*. 2021. Water soluble fabric, cotton thread and steel rod, 182 x 67 x 69 cm.',
           	price: 'R20 000'
       	},
{
           	photoUrl: 'FA_Milne_5 - Tayla Milne.jpg',
           	caption: 'Tayla Milne, *Hanging on by a Thread*. 2021. Water soluble fabric, cotton thread and steel rod, 182 x 67 x 69 cm.',
           	price: 'R20 000'
       	},
{
           	photoUrl: 'FA_Milne_6 - Tayla Milne.jpg',
           	caption: 'Tayla Milne, *Hanging on by a Thread*. 2021. Water soluble fabric, cotton thread and steel rod, 182 x 67 x 69 cm.',
           	price: 'R20 000'
       	},
{
           	photoUrl: 'FA_Milne_7 - Tayla Milne.jpg',
           	caption: 'Tayla Milne, *Hanging on by a Thread*. 2021. Water soluble fabric, cotton thread and steel rod, 182 x 67 x 69 cm.',
           	price: 'R20 000'
       	},
{
           	photoUrl: 'FA_Milne_8 - Tayla Milne.jpg',
           	caption: 'Tayla Milne, *Hanging on by a Thread*. 2021. Water soluble fabric, cotton thread and steel rod, 182 x 67 x 69 cm.',
           	price: 'R20 000'
       	},
       	{
           	photoUrl: 'FA_Milne_9- Tayla Milne.jpg',
           	caption: 'Tayla Milne, *Hanging on by a Thread*. 2021. Water soluble fabric, cotton thread and steel rod, 182 x 67 x 69 cm.',
           	price: 'R20 000'
       	}
   	]
   },

   {
   	name: 'Edward Musakwa',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Classism',
   	description: 'I investigate how design for social innovation and design activism can be applied to foster productive social experiences. I position xenophobia as an example of destructive social practices in South Africa. I share my personal experience of xenophobia at Stellenbosch University as an international student. I then explore classism with the initiative *Funga*. In this audience driven direction I position classism as another form of destructive practices on campus prevalent in many societies worldwide. I discovered that classism is similar to xenophobia on the basis of othering, oppression and social injustice. ', //max characters 620
   	email: '',
   	insta: '',
   	phone: '',
   	thesisLink: 'https://drive.google.com/file/d/1d-miogkgOdPYOBT0I8lY6KjYMbpvz1to/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Musakwa_Edward_Trans.png', // file_name ( must start with  )
   	color: 'red', // color of the background of someones profile image
   	previewedUrl: 'VCD_Musakwa_4 - Eddie 07.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'VCD_Musakwa_1 - Eddie 07.jpg',
           	caption: 'Edward Musakwa, *My pain in a poem*. 2021. Digital design.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'VCD_Musakwa_2 - Eddie 07.jpg',
           	caption: 'Edward Musakwa, *Activism*. 2021. Crafted design.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Musakwa_3 - Eddie 07.jpg',
           	caption: 'Edward Musakwa, *Innovation*. 2021. Crafted design.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Musakwa_4 - Eddie 07.jpg',
           	caption: 'Edward Musakwa, *Funga branding*. 2021. Digital design.',
           	price: ''
       	},
{
           	photoUrl: 'VCD_Musakwa_5 - Eddie 07.jpg',
           	caption: 'Edward Musakwa, *Funga*. 2021. Photograph by Author - Eddie 07',
           	price: ''
       	},
{
           	photoUrl: 'https://player.vimeo.com/video/653281794?h=cc28959fd7',
           	caption: 'Edward Musakwa, *Funga logo animation*. 2021. ',
           	price: '',
	isVideo: true
       	},
   	]
   },

   {
   	name: 'Bianca Persson',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Prosthetic Realm',
   	description: 'My body of work explores the prosthetic realm. My intention throughout the process has been to educate, create awareness, help amputees reshape their identities, celebrate beauty in diversity and to break stigmas associated with amputation. Its central focus is to help amputees navigate through the inequalities in the prosthetic realm seeing as many disparities exist in the prosthetic realm within the South African context. My work gives a voice to the voiceless and brings acknowledgement and recognition of the amputee community.', //max characters 620
   	email: 'persbianx@gmail.com',
   	insta: 'https://www.instagram.com/redefineline/',
   	phone: '',
   	thesisLink: 'https://drive.google.com/file/d/13IwUueLP4My-0WAgbYsETPJ_M7WsRdoZ/view?usp=sharing',
   	portfolio: 'https://persbianx.wixsite.com/biancablog',
   	profileUrl: 'Persson_Bianca_Trans.png', // file_name ( must start with  )
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'VCD_Persson_1 - Bianca Persson.jpg', //file_name
   	fixHeight: true, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'https://player.vimeo.com/video/653280767?h=c7347f06e5',
           	caption: 'Bianca Persson, *Life Over Limb Infographic Poster*. 2021.', //max characters 280
           	price: '',
	isVideo: true
       	},
       	{
           	photoUrl: 'VCD_Persson_2 - Bianca Persson.jpg',
           	caption: 'Bianca Persson, *Life Over Limb Excerpt 1*. 2021. Digital Book.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Persson_3 - Bianca Persson.jpg',
           	caption: 'Bianca Persson, *Asymmetrical Harmony*. 2021. Digital Poster.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Persson_4 - Bianca Persson.jpg',
           	caption: 'Bianca Persson, *Merged Identities: Beauty in Diversity*. 2021. Digital Poster.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Persson_5 - Bianca Persson.jpg',
           	caption: 'Bianca Persson, *POP prosthesis*. 2021. Plaster of Paris.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Persson_6 - Bianca Persson.jpg',
           	caption: 'Bianca Persson, *Life Over Limb Book*. 2021. Digital Book.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Persson_7 - Bianca Persson.jpg',
           	caption: 'Bianca Persson, *I am able 2*. 2021. Gold leaf, resin and photography.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Persson_8 - Bianca Persson.jpg',
           	caption: 'Bianca Persson, *I am able 1*. 2021. Gold leaf, resin and photography.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Persson_9 - Bianca Persson.jpg',
           	caption: 'Bianca Persson, *Learn from Us*. 2021. Resin and gold leaf.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Persson_10 - Bianca Persson.jpg',
           	caption: 'Bianca Persson, *Life Over Limb Book Illustration*. 2021. Digital Illustration.',
           	price: ''
       	},
   	]
   },

   {
   	name: 'Ashley Petersen',
   	type: 'fine', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Communication',
   	description: 'In between the dark marks and dusty shadows are the traces of raw emotion. Each foot drawing is like a diary entry, leaving behind visual evidence of every transaction. I was in constant interaction with the truth, but the further I went the more abstract it became, what has started out as words are now re-presentations. The not knowing had a chance to present itself and allowed me to communicate authentically on a large scale.', //max characters 620
   	email: '',
   	insta: 'https://www.instagram.com/ashleynessvibes/?hl=en',
   	phone: '0737710353',
   	thesisLink: 'https://drive.google.com/file/d/17NUh-1bhjIYMSvxtiskRLJ04W9ep7P2w/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Petersen_Ashley_Trans.png', // file_name ( must start with  )
   	color: 'red', // color of the background of someones profile image
   	previewedUrl: 'FA_Petersen_8 - Ashley Petersen.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'FA_Petersen_1 - Ashley Petersen.jpg',
           	caption: 'Ashley Petersen, *Untitled #1*. 2021. Installation.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'FA_Petersen_2 - Ashley Petersen.jpg',
           	caption: 'Ashley Petersen, *Untitled #2*. 2021. Installation.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Petersen_3 - Ashley Petersen.jpg',
           	caption: 'Ashley Petersen, *Untitled #3*. 2021. Installation.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Petersen_4 - Ashley Petersen.jpg',
           	caption: 'Ashley Petersen, *Untitled #4*. 2021. Charcoal on Paper.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Petersen_5 - Ashley Petersen.jpg',
           	caption: 'Ashley Petersen, *Untitled #5*. 2021. Charcoal on Paper.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Petersen_6 - Ashley Petersen.jpg',
           	caption: 'Ashley Petersen, *Untitled #6*. 2021. Charcoal on Paper.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Petersen_7 - Ashley Petersen.jpg',
           	caption: 'Ashley Petersen, *Untitled #7*. 2021. Charcoal on Paper.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Petersen_8 - Ashley Petersen.jpg',
           	caption: 'Ashley Petersen, *Untitled #8*. 2021. Charcoal on Paper.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Petersen_9 - Ashley Petersen.jpg',
           	caption: 'Ashley Petersen, *Untitled #9*. 2021. Charcoal on Paper.',
           	price: ''
       	},
       	{
           	photoUrl: 'https://player.vimeo.com/video/653285020?h=1843f11be2',
           	caption: 'Ashley Petersen, *Untitled #10*. 2021. Charcoal on Paper.',
           	price: '',
	isVideo: true
       	},
   	]
   },

   {
   	name: 'Mia Marié Pieters',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Multi-Sensory Design',
   	description: 'My work is based on the investigation of perception through Multisensory Design. Information overload is prevalent in contemporary society. This leads to desensitisation and passive interaction with visual content. The initiative *FEEL* explored how multisensory design can be used to facilitate a more active negotiation of information. It aimed to create a multi-dimensional and multisensory place to feel.', //max characters 620
   	email: 'miapieters1@gmail.com',
   	insta: '',
   	phone: '',
   	thesisLink: 'https://drive.google.com/file/d/1jWk_b4kg-FV-D9hAbTalQWhO6OCbTW7-/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Pieters_Mia_Trans.png', // file_name ( must start with  )
   	color: 'red', // color of the background of someones profile image
   	previewedUrl: 'vcd_Pieters_1.jpg', //file_name
   	fixHeight: true, //if preview photo is taller than wider, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'vcd_Pieters_1.jpg',
           	caption: 'Mia Pieters, *Fēl research article*. 2021. Book, 26 x 19,5 cm.', //max characters 280
           	price: '',
       	}, 
{
           	photoUrl: 'vcd_Pieters_2.jpg',
           	caption: 'Mia Pieters, *Your skin can speak*. 2021. Poster series, 29,7 x 42 cm.', //max characters 280
           	price: '',
       	}, 
{
           	photoUrl: 'vcd_Pieters_3.jpg',
           	caption: 'Mia Pieters, *Look at the sound around you*.2021. Poster series, 29,7 x 42 cm.', //max characters 280
           	price: '',
       	}, 
{
           	photoUrl: 'vcd_Pieters_4.jpg',
           	caption: 'Mia Pieters, *Process and perception of touch*. 2021. Infographic series, 29,7 x 42 cm.', //max characters 280
           	price: '',
       	}, 
{
           	photoUrl: 'vcd_Pieters_5.jpg',
           	caption: 'Mia Pieters, *Spectrum of colour perception*. 2021. Infographic series, 29,7 x 42 cm.', //max characters 280
           	price: '',
       	}, 
{
           	photoUrl: 'vcd_Pieters_6.jpg',
           	caption: 'Mia Pieters, *Touching perception: Part 2*. 2021 Photography series, 14,8 x 21 cm.', //max characters 280
           	price: '',
       	}, 
{
           	photoUrl: 'vcd_Pieters_7.jpg',
           	caption: 'Mia Pieters, *Touching perception: Part 2*. 2021 Photography series, 14,8 x 21 cm.', //max characters 280
           	price: '',
       	}, 
{
           	photoUrl: 'vcd_Pieters_8.jpg',
           	caption: 'Mia Pieters, *Touching perception: Part 2*. 2021 Photography series, 14,8 x 21 cm.', //max characters 280
           	price: '',
       	}, 
{
           	photoUrl: 'vcd_Pieters_9.jpg',
           	caption: 'Mia Pieters, *Borders of perception*. 2021. Visual excavation photography series, 14,8 x 21 cm.', //max characters 280
           	price: '',
       	}, 

   	]
   },

   {
   	name: 'Sián Posthumus',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Stress/Anxiety',
   	description: '2021 is about exploring my own feelings of stress and anxiety, as well as that of others around me. The Covid-19 pandemic has proved itself to be a completely unpredictable, gloomy, and unfortunate time in the lives of many people. I developed the brand ‘breathe’ in reaction to these feelings in an attempt to ease them in the most pure way possible: through nature. My initiative focuses on applying biophilic design and its principles to modern design disciplines in order to simulate a calm, natural experience, as if immersed in nature.', //max characters 620
   	email: 'sianposthumus99@gmail.com',
   	insta: 'https://www.instagram.com/artsi.an/?hl=en',
   	phone: '',
   	thesisLink: 'https://drive.google.com/file/d/1RKtDcxZvndPTVwErz6RKHbXUcptNZweR/view?usp=sharing',
   	portfolio: 'https://sianposthumus99.wixsite.com/sianposthumus',
   	profileUrl: 'Posthumus_Sian_Trans.png', // file_name ( must start with  )
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'VCD_Posthumus_6 - Sian Posthumus.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'VCD_Posthumus_1 - Sian Posthumus.jpg',
           	caption: 'Sian Posthumus, *Marks&Traces*. 2021. Digital photograph. ', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'VCD_Posthumus_2 - Sian Posthumus.jpg',
           	caption: 'Sian Posthumus, *Breathe Mobile App*. 2021. ',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Posthumus_3 - Sian Posthumus.jpg',
           	caption: 'Sian Posthumus, *Stress Relief Image Generation*. 2021. Digital photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Posthumus_4 - Sian Posthumus.jpg',
           	caption: 'Sian Posthumus, *Research Article Cover*. 2021. ',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Posthumus_5 - Sian Posthumus.jpg',
           	caption: 'Sian Posthumus, *Stress Relief Image Generation*. 2021. Digital Photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Posthumus_6 - Sian Posthumus.jpg',
           	caption: 'Sian Posthumus, *Breathe Viewfinder*. 2021. Print on paper, 10cm x 10cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Posthumus_7 - Sian Posthumus.jpg',
           	caption: 'Sian Posthumus, *Breathe Brand Guide*. 2021.',
           	price: ''
       	},
{
           	photoUrl: 'VCD_Posthumus_8 - Sian Posthumus.jpg',
           	caption: 'Sian Posthumus, *Breathe "Anxiety" infographics*. 2021. Print on paper, 59cm x 42 cm.',
           	price: ''
       	},
{
           	photoUrl: 'https://player.vimeo.com/video/653286447?h=8551eb7f24',
           	caption: 'Sian Posthumus, *Motion Design*. 2021.',
           	price: '',
	isVideo: true
       	},
       	{
           	photoUrl: 'https://player.vimeo.com/video/653286481?h=59ed674e8e',
           	caption: 'Sian Posthumus, *Breathe logo animation*. 2021.',
           	price: '',
	isVideo: true
       	}
   	]
   },

   {
   	name: 'Alexandra Randell',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Anthropocentrism',
   	description: 'My work is centered around deconstructing ideas of human centrality and anthropocentrism and gaining a posthumanist perspective by visually moving through space (outer space) and time (deep time). The vastness of both space and time provides a realm in which human existence is rendered infinitesimally small. These selected images allude to this feeling of littleness as I attempt to capture the essence of time and space and the movement between these separated realms.', //max characters 620
   	email: '',
   	insta: 'https://www.instagram.com/abby_randell/',
   	phone: '0793683745',
   	thesisLink: 'https://drive.google.com/file/d/12v-RgN2HkBtLQCTsNyi2mFzDAf2WGMG1/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Randell_Abby_2_Trans.png', // file_name ( must start with  )
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'VCD_Randell_5 - Alexandra Randell.jpg', //file_name
   	fixHeight: true, //if preview photo is taller than wider, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'VCD_Randell_1 - Alexandra Randell.jpg',
           	caption: 'Alexandra Randell, *Zoom In 1*. 2021. Digital image, 22x30 cm.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'VCD_Randell_2 - Alexandra Randell.jpg',
           	caption: 'Alexandra Randell, *Zoom Out 1*. 2021. Digital image, 22x30 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Randell_3 - Alexandra Randell.jpg',
           	caption: 'Alexandra Randell, *Zoom In 2*. 2021. Digital image, 22x30 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Randell_4 - Alexandra Randell.jpg',
           	caption: 'Alexandra Randell, *Zoom In 3*. 2021. Digital collage, 22x30 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Randell_5 - Alexandra Randell.jpg',
           	caption: 'Alexandra Randell, *Zoom Out 2*. 2021. Digital image, 22x30 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Randell_6 - Alexandra Randell.jpg',
           	caption: 'Alexandra Randell, *Zoom In 4*. 2021. Digital image, 22x30 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Randell_7 - Alexandra Randell.jpg',
           	caption: 'Alexandra Randell, *Zoom In 5*. 2021. Digital image, 22x30 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Randell_8 - Alexandra Randell.jpg',
           	caption: 'Alexandra Randell, *Zoom Out 3*. 2021. Digital image, 22x30 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Randell_9 - Alexandra Randell.jpg',
           	caption: 'Alexandra Randell, *Zoom In 6*. 2021. Digital collage, 22x30 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Randell_10 - Alexandra Randell.jpg',
           	caption: 'Alexandra Randell, *Zoom Out 4*. 2021. Digital image, 22x30 cm.',
           	price: ''
       	},
   	]
   },

   {
   	name: 'Paige Joy Reynolds',
   	type: 'jewelry', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Identity',
   	description: '‘Prosthetic jewellery’ is defined as jewellery that interacts with the body in a way that comments on the human-technology interaction, by way of showcasing what is on the inside of the body, on the outside, and thus exposing my true identity through my personal and cathartic process of creating jewellery. Jewellery is an extension of the body, in which relationships between humans and technologies are embodied in particular ways, shaping identities. Prosthetic jewellery can also be seen as something that accommodates for one’s emotional and physical comfort, and a feeling of wholeness can be achieved.', //max characters 620
   	email: '',
   	insta: 'https://www.instagram.com/rey.jewellery/',
   	phone: '0796625529',
   	thesisLink: 'https://drive.google.com/file/d/1M557yVI5Cub8EE0KMcB2qY4BZhVhIgTG/view?usp=sharing',
   	portfolio: 'xxxxxxxx.com',
   	profileUrl: 'Reynolds_Paige_Trans.png', // file_name ( must start with  )
   	color: 'red', // color of the background of someones profile image
   	previewedUrl: 'JD_PaigeReynolds_10 - Paige Reynolds.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than wider, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'JD_PaigeReynolds_1 - Paige Reynolds.jpg',
           	caption: 'Paige Reynolds, *Techno-hybrid 1*. 2021. Brooch.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'JD_PaigeReynolds_2 - Paige Reynolds.jpg',
           	caption: ' Paige Reynolds, *Capacitance 1 and 2*. 2021. Pendants.',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_PaigeReynolds_3 - Paige Reynolds.jpg',
           	caption: 'Paige Reynolds, *Skin of the Future*. 2021. Neckpiece.',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_PaigeReynolds_4 - Paige Reynolds.jpg',
           	caption: 'Paige Reynolds, *Digitus Minimus Manus*. 2021. Prosthetic finger.',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_PaigeReynolds_5 - Paige Reynolds.jpg',
           	caption: 'Paige Reynolds, *Flex-tech*. 2021. Brooch.',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_PaigeReynolds_6 - Paige Reynolds.jpg',
           	caption: 'Paige Reynolds, *Human-technology*. 2021. Lapel pin.',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_PaigeReynolds_7 - Paige Reynolds.jpg',
           	caption: 'Paige Reynolds, *Whole*. 2021. Brooch.',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_PaigeReynolds_8 - Paige Reynolds.jpg',
           	caption: 'Paige Reynolds, *Spine*. 2021. Backpiece.',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_PaigeReynolds_9 - Paige Reynolds.jpg',
           	caption: 'Paige Reynolds, *Techno-hybrid 2*. 2021. Brooch.',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_PaigeReynolds_10 - Paige Reynolds.jpg',
           	caption: 'Paige Reynolds, *Becoming One*. 2021. Neckpiece.',
           	price: ''
       	},
   	]
   },

   {
   	name: 'Elisabetta Ricoveri',
   	type: 'fine', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Dimensions',
   	description: '*Gateway* is an exhibition that explores the notion of reality and alternative realities. Focusing on the link between my dreams and my current reality. I explore the logic of the unconscious and the idea of entering in a dreamworld placed within our own existence. I created multiple portals to represent multiple dimensions as well as keys to enter these portals. In *Gateway*, the frames are presented as metaphorical portals to my virtual realities', //max characters 620
   	email: '',
   	insta: 'https://www.instagram.com/bety_boop27/',
   	phone: '0768822629',
   	thesisLink: '',
   	portfolio: '',
   	profileUrl: 'Ricoveri_Elisabetta_Trans.png', // file_name ( must start with  )
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'FA_Ricoveri_1 - Elisabetta Ricoveri.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than wider, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'FA_Ricoveri_1 - Elisabetta Ricoveri.jpg',
           	caption: 'Elisabetta Ricoveri, *Dark World*. 2021. Installation. PVC pipes, copper, PU foam, Nails, Screws, Paint. 100 cm x 300 cm.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'FA_Ricoveri_2 - Elisabetta Ricoveri.jpg',
           	caption: 'Elisabetta Ricoveri, *Dark World*. 2021. Installation. PVC pipes, Copper, PU foam, Nails, Screws, Paint. 100 cm x 300 cm.',
           	price: ''
       	},
{
           	photoUrl: 'FA_Ricoveri_3 - Elisabetta Ricoveri.jpg',
           	caption: 'Elisabetta Ricoveri, *Rabbit Door*. 2021. Installation. PVC pipes. Copper,Paint. 100 cm x 300 cm.',
           	price: ''
       	},
{
           	photoUrl: 'FA_Ricoveri_4 - Elisabetta Ricoveri.jpg',
           	caption: 'Elisabetta Ricoveri, *Rabbit Door*. 2021. Installation. PVC pipes. Copper,Paint. 100 cm x 300 cm.',
           	price: ''
       	},
{
           	photoUrl: 'FA_Ricoveri_5 - Elisabetta Ricoveri.jpg',
           	caption: 'Elisabetta Ricoveri, *Peaking Through*. 2021. Installation. Cyanotype, PVC pipes, Copper. 210 cm x 297 cm.',
           	price: ''
       	},
{
           	photoUrl: 'FA_Ricoveri_6 - Elisabetta Ricoveri.jpg',
           	caption: 'Elisabetta Ricoveri, *Totem 1*. 2021. Installation. 3D print, Z-glass. 10 cm x 20 cm.',
           	price: ''
       	},
{
           	photoUrl: 'FA_Ricoveri_7 - Elisabetta Ricoveri.jpg',
           	caption: 'Elisabetta Ricoveri, *Totem 2* .2021. Installation. Tin. 60 cm x 80 cm.',
           	price: ''
       	},
{
           	photoUrl: 'FA_Ricoveri_8 - Elisabetta Ricoveri.jpg',
           	caption: 'Elisabetta Ricoveri, *Wonderland*. Installation, animation. Collage. 420 cm x 594 cm.',
           	price: ''
       	},
{
           	photoUrl: 'FA_Ricoveri_12 - Elisabetta Ricoveri.jpg',
           	caption: 'Elisabetta Ricoveri, *Dark World*. 2021. Installation. PVC pipes, Copper, PU foam, Nails, Screws, Paint. 100 cm x 300 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Ricoveri_13 - Elisabetta Ricoveri.jpg',
           	caption: 'Elisabetta Ricoveri, *Rabbit Door*. 2021. Installation. PVC pipes. Copper,Paint. 100 cm x 300 cm.',
           	price: ''
       	}
   	]
   },

   {
   	name: 'Jenna Riva',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Trauma Management',
   	description: 'My work surrounds the theme of trauma and the short-term and long-term effects of trauma. I deal with PTSD, mental illness and an overarching theme of mental health within my work. In my work I aim to create open conversations surrounding the unseen effects of trauma, for example generational trauma, and allow for our individual vulnerabilities come through, in hopes to create a safe space where emotional baggage can be discussed. ', //max characters 620 
   	email: '',
   	insta: '',
   	phone: '0827714463',
   	thesisLink: 'https://drive.google.com/file/d/1Nfhuh1rT_UKpTmExPJOXDujR7hqaX_ul/view?usp=sharing',
   	portfolio: 'https://indd.adobe.com/view/1106a28d-177b-4607-b191-7c1197dc4ad7',
   	profileUrl: 'Riva_Jenna_2_Trans.png', // file_name ( must start with  )
   	color: 'red', // color of the background of someones profile image
   	previewedUrl: 'VCD_RIVA_1 - Jenna Riva.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than wider, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'VCD_RIVA_1 - Jenna Riva.jpg',
           	caption: 'Jenna Riva, *Tainted*. 2021. Photography.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'VCD_RIVA_2 - Jenna Riva.jpg',
           	caption: 'Jenna Riva, *Tainted*. 2021. Photography.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_RIVA_3 - Jenna Riva.jpg',
           	caption: 'Jenna Riva, *Tainted*. 2021. Photography.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_RIVA_4 - Jenna Riva.jpg',
           	caption: 'Jenna Riva, *Tainted*. 2021. Photography.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_RIVA_5 - Jenna Riva.jpg',
           	caption: 'Jenna Riva, *all the things i could never say out loud*. 2021. Print book.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_RIVA_6 - Jenna Riva.jpg',
           	caption: 'Jenna Riva, *memento*. 2021. Postcard.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_RIVA_7 - Jenna Riva.jpg',
           	caption: 'Jenna Riva, *memento*. 2021. Postcard.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_RIVA_8 - Jenna Riva.jpg',
           	caption: 'Jenna Riva, *generational trauma*. 2021. Poster.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_RIVA_9 - Jenna Riva.jpg',
           	caption: 'Jenna Riva, *memento*. 2021. Postcard.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_RIVA_10 - Jenna Riva.jpg',
           	caption: 'Jenna Riva, *memento*. 2021. Postcard.',
           	price: ''
       	},
   	]
   },

   {
   	name: 'Nina Scholtz',
   	type: 'fine', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Worldbuilding',
   	description: 'Since childhood, I have found my eyes drawn to exposed earth to catch on fragments of white bone, broken pieces of crockery, and rusted metal. These half-submerged artefacts, which I excavated and investigated, played a crucial role in the real and imagined worlds of my childhood. Each remnant came with a language and story of its own that only I could realise, and it was through this early process of collecting and interacting with these artefacts that I started a cyclical practice of play, story-telling and world-building.', //max characters 620
   	email: 'ninachristine11SA@gmail.com',
   	insta: 'https://www.instagram.com/the_chronicles_of_mars/?hl=en',
   	phone: '',
   	thesisLink: 'https://drive.google.com/file/d/1EYQ6XP6Hmt4tgVGq1kwCkosBum64XdbE/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Scholtz_Nina_Trans.png', // file_name ( must start with  )
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'FA_Scholtz_2 - Nina Scholtz.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'FA_Scholtz_1 - Nina Scholtz.jpg',
           	caption: 'Nina Scholtz, *Untitled*. 2021. Digital photograph. ', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'FA_Scholtz_2 - Nina Scholtz.jpg',
           	caption: 'Nina Scholtz, *Untitled*. 2021. Still from video. ',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Scholtz_3 - Nina Scholtz.jpg',
           	caption: 'Nina Scholtz, *Untitled*. 2021. Still from video.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Scholtz_4 - Nina Scholtz.jpg',
           	caption: 'Nina Scholtz, *Untitled (chalk unicorn)*. 2021. White chalk on concrete, 230 x 210 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Scholtz_5 - Nina Scholtz.jpg',
           	caption: 'Nina Scholtz, *The Collector*. 2021. Bones on black cloth, 80 x 80 cm. Photograph by Jacques Bruwer.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Scholtz_6 - Nina Scholtz.jpg',
           	caption: 'Nina Scholtz, *Celestial Body I*. 2021. Digital inverted image.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Scholtz_7 - Nina Scholtz.jpg',
           	caption: 'Nina Scholtz, *The Collector and her Hoard*. 2021. Digital bitmapped imaged.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Scholtz_8 - Nina Scholtz.jpg',
           	caption: 'Nina Scholtz, *Star Map*. 2021. Bones on concrete.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Scholtz_9 - Nina Scholtz.jpg',
           	caption: 'Nina Scholtz, *Unicorn*. 2021. Film photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Scholtz_10 - Nina Scholtz.jpg',
           	caption: 'Nina Scholtz, *Body Story*. 2021. Embroidery on sewn and fused fabric, 123 x 207 cm.',
           	price: ''
       	},
   	]
   },

   {
   	name: 'Sherika Singh',
   	type: 'fine', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Racial Discrimination',
   	description: `Within a post-colonial theoretical framework and South African Coloured context, my work examines the relationship between hair, race, Coloured identity, culture, and beauty myths. I examine how hair became a signifier of race and the consequences of this notion on people of colour's hair. My work aims to demonstrate how these racialized and classist ideologies placed on Coloured women’s hair stem from the oppressive frameworks of Apartheid, colonialism, and White Supremacy to use the unconventional medium of hair as a form of symbolic resistance against this oppression.`, //max characters 620
   	email: 'sherikasingh8@gmail.com',
   	insta: '',
   	phone: '',
   	thesisLink: 'https://drive.google.com/file/d/1RaH8arjxbmc7fmax1AiPlste8xqei4-n/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Singh_Sherika_Trans.png', // file_name ( must start with  )
   	color: 'red', // color of the background of someones profile image
   	previewedUrl: 'FA_Singh_7 - Sherika Singh.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than wider, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'FA_Singh_1 - Sherika Singh.jpg',
           	caption: 'Sherika Singh, *Dichotomy |*. 2021. Hair & Glue on Paper, 29,7cm x 21cm.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'FA_Singh_2 - Sherika Singh.jpg',
           	caption: 'Sherika Singh, *Dichotomy |*. 2021. Hair & Glue on Paper, 29,7cm x 21cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Singh_3 - Sherika Singh.jpg',
           	caption: 'Sherika Singh, *Bricolage*.2021. Human Hair, Hemp, Wooden planks, Metal rods, Mahogony stools, 39cm x 103,5cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Singh_4 - Sherika Singh.jpg',
           	caption: 'Sherika Singh, *Bricolage*.2021. Human Hair, Hemp, Wooden planks, Metal rods, Mahogony stools, 39cm x 103,5cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Singh_5 - Sherika Singh.jpg',
           	caption: 'Sherika Singh, *`The Kitchen` : Tension | (Series)*. 2021. Human hair, Synthetic hair, Stockings, Thread, Wooden frame, Metal Nails, 60,5cm x 50,5cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Singh_6 - Sherika Singh.jpg',
           	caption: 'Sherika Singh, *`The Kitchen` : Tension | (Series)*.2021. Human hair, Synthetic hair, Stockings, Thread, Wooden frame, 60,5cm x 50,5cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Singh_7 - Sherika Singh.jpg',
           	caption: 'Sherika Singh, *Hair Labyrinth*.2021.Digital Photograph, Human hair, Hemp, White sheet.',
           	price: '’'
       	},
       	{
           	photoUrl: 'FA_Singh_8 - Sherika Singh.jpg',
           	caption: 'Sherika Singh, *Hair Labyrinth*.2021.Digital Photograph, Human hair, Hemp, White sheet.',
           	price: '’'
       	},
       	{
           	photoUrl: 'FA_Singh_9 - Sherika Singh.jpg',
           	caption: 'Sherika Singh, *`Jou hare gaan huistoe` : Tension || (Series)*, Human hair, Synthetic hair, Stockings, Wooden frame, Metal Nails, 58cm x 38cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Singh_10 - Sherika Singh.jpg',
           	caption: 'Sherika Singh, *`Jou hare gaan huistoe` : Tension || (Series)*, Human hair, Synthetic hair, Stockings, Wooden frame, Metal Nails, 58cm x 38cm.',
           	price: ''
       	},
   	]
   },

   {
   	name: 'Sarah Southern',
   	type: 'fine', // One of these three ONLY: jewelry, fine, visual
   	problem: 'human connection',
   	description: 'The fist size objects balancing throughout the stilt installation were formed when plaster was poured between two people held hands and pressed feet. *MAKING CONTACT* is a collaborative project involving many people I know on different familial scales, from strangers to close friends and family. Each collaborative mould singularly maps, within the surface of the plaster, the impressed labyrinthine of lines unique to both people. Beeswax is grafted to the objects of touch in an assemblage of thin yellow folds, the form of which was created also through the medium of touch and impression of skin.', //max characters 620
   	email: '',
   	insta: 'https://www.instagram.com/sarahsouthern777/',
   	phone: '0827725173',
   	thesisLink: '',
   	portfolio: '',
   	profileUrl: 'Southern_Sarah_Trans.png', // file_name ( must start with  )
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'FA_Southern_1.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than wider, then true. Otherwise false
 
    	photos: [
       	{
           	photoUrl: 'FA_Southern_1.jpg',
           	caption: 'Sarah Southern, "Making Contact*. 2021. (Installation). Pine Wood, Plaster, Smoke, Beeswax.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'FA_Southern_2 - Sarah Southern.jpg',
           	caption: 'Sarah Southern, *Making Contact*. 2021. (Installation). Pine Wood, Plaster, Smoke, Beeswax.',
           	price: '',
       	},
       	{
           	photoUrl: 'FA_Southern_3.jpg',
           	caption: 'Sarah Southern, *Object of Contact 001*. 2021. Plaster, Smoke, Beeswax.',
           	price: 'R500'
       	},
       	{
           	photoUrl: 'FA_Southern_4 - Sarah Southern.jpg',
           	caption: 'Sarah Southern, *Object of Contact 002*. 2021. Plaster, Smoke, Beeswax.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Southern_5 - Sarah Southern.jpg',
           	caption: 'Sarah Southern, *Object of Contact 003*. 2021. Plaster, Smoke, Beeswax.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Southern_6 - Sarah Southern.jpg',
           	caption: 'Sarah Southern, *Object of Contact 004*. 2021. Plaster, Smoke, Beeswax.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Southern_7 - Sarah Southern.jpg',
           	caption: 'Sarah Southern, *Object of Contact 005.* 2021. Plaster, Smoke, Beeswax.',
           	price: ''
       	},
       	{
           	photoUrl: 'FA_Southern_8 - Sarah Southern.jpg',
           	caption: 'Sarah Southern, *Object of Contact 004.* 2021. Plaster, Smoke, Beeswax.',
           	price: 'Sarah Southern, *Object of Contact 004.* 2021. Plaster, Smoke, Beeswax.'
       	},
       	{
           	photoUrl: 'FA_Southern_9 - Sarah Southern.jpg',
           	caption: 'Sarah Southern, *Process Work Hands*. 2021. Photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'https://player.vimeo.com/video/653279963?h=414e76fed5',
           	caption: 'Sarah Southern, *Making Contact*. 2021. Film.',
           	price: '',
	isVideo: true
       	},
   	]
   },

   {
   	name: 'Esther Rhodé Stander',
   	type: 'jewelry', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Becoming Water',
   	description: `My body of work is based on a new materialist study of water in which the shapes found in water are uniquely translated and expressed through different materials. I question the concept of agency and how I can co-create with each material based on its own properties and behaviours without steering the creative process towards a final outcome. I approach each material with an open mind in order to allow the 'becoming' of the material to teach me about my own process of becoming. My body of work is the physical outcome of my own process of becoming-water which is embarking on its own process of becoming-jewellery.`, //max characters 620
   	email: '',
   	insta: 'https://instagram.com/youngcaesarea',
   	phone: '0794953800',
   	thesisLink: '',
   	portfolio: '',
   	profileUrl: 'Stander_Esther_Trans.png', // file_name ( must start with  )
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'JD_Stander_5 - YC.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'JD_Stander_1 - YC.jpg',
           	caption: 'Esther Rhodé Stander, *Aqua*. 2021. Rendered image on Rhino7.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'JD_Stander_2 - YC.jpg',
           	caption: 'Esther Rhodé Stander, *Ozzie*. 2021. Rendered image on Rhino7.',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_Stander_3 - YC.jpg',
           	caption: 'Esther Rhodé Stander, *Suspended*. 2021. Polymer clay on sterling silver, brooch, 5x10cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_Stander_4 - YC.jpg',
           	caption: 'Esther Rhodé Stander, *Puddle*. 2021. Resin and sterling silver brooch, 3x5cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_Stander_5 - YC.jpg',
           	caption: 'Esther Rhodé Stander, *River*. 2021. Enamelled fine silver brooch, 3x10cm. ',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_Stander_6 - YC.jpg',
           	caption: 'Esther Rhodé Stander, *Current*. 2021. Enamelled fine silver pendant, 1x5cm.',
           	price: ''
       	}
   	]
   },

   {
   	name: 'Anine Toüa',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Sustainability',
   	description: 'My year’s praxis is based on the problematic sense of disenchantment toward the natural world that the Gen Z designer is situated within. Disenchantment is both a symptom and cause of the Anthropocene. My work aims to explore how renewed awareness about the vitality and enchanting potential of natural matter can facilitate ethics and a change in human behaviour toward the natural world. I argue for enchantment through the field of design, as emerging designers have the responsibility to remedy past injustices toward the natural world by rethinking design. In my work, I explore ways to work toward enchantment. ', //max characters 620
   	email: '',
   	insta: 'https://www.instagram.com/aninetoua/ /',
   	phone: '0728034505',
   	thesisLink: 'https://drive.google.com/file/d/1rDJkghc6gnPvS7q3f9uHX0WKZE_0QWQ8/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Toua_Anine_Trans.png', // file_name ( must start with  )
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'VCD_Toua_4 - Anine Toua.jpg', //file_name
   	fixHeight: true, //if preview photo is taller than wider, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'VCD_Toua_1 - Anine Toua.jpg',
           	caption: 'Anine Toua, *Infographic poster series*. 2021. Digital posters.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'VCD_Toua_2 - Anine Toua.jpg',
           	caption: 'Anine Toua, *Alkimi Brand Guide*. 2021. Book, 210 x 297 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Toua_3 - Anine Toua.jpg',
           	caption: 'Anine Toua, *Alkimi`s A Collab Book: Human Designer x Natural Matter*. 2021. Book, 210 x 297 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Toua_4 - Anine Toua.jpg',
           	caption: 'Anine Toua, *Alkimi poster 1*. 2021. Digital poster.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Toua_5 - Anine Toua.jpg',
           	caption: 'Anine Toua, *Alkimi poster 2*. 2021. Digital poster.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Toua_6 - Anine Toua.jpg',
           	caption:  'Anine Toua, *Development of different participants` Agar plates*. 2021. Photograph.',
           	price: ''
       	},
{
           	photoUrl: 'VCD_Toua_7 - Anine Toua.jpg',
           	caption: 'Anine Toua, * Alkimi`s A Colab Book: Human Designer x Natural Matter*. 2021. Book, 210x297.',
           	price: ''
       	},
{
           	photoUrl: 'https://player.vimeo.com/video/653284025?h=461ada5800',
           	caption:  'Anine Toua, * Visual exploration video documentation*. 2021. Video.',
           	price: '',
	isVideo: true
       	},
{
           	photoUrl: 'https://player.vimeo.com/video/653284061?h=32605f9dd0',
           	caption: 'Anine Toua, *Animated Logo *. 2021. Animation.',
           	price: '',
	isVideo: true
       	},
       	{
           	photoUrl: 'https://player.vimeo.com/video/653284085?h=b315d73b17',
           	caption: 'Anine Toua, * Alkimi x Natural Matter: Looking Closer*. 2021. Video.',
           	price: '',
	isVideo: true
       	}
   	]
   },

   {
   	name: 'Bryony Treagus',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Malnutrition',
   	description: 'Throughout this year, I have embarked on a theoretical and practical exploration of a more feasible means of dietary behaviour change amongst primary school children, namely the addition of Nudge Theory and participatory design to accentuate and enhance nutrition education schemes. My practice applied in this research is activated under the platform *Omnom*. The strategy that was employed in the brand is playful, colourful and childlike, to create a relationship of joy and affection within children. *Omnom* is participatory in its nature, inviting all members to engage in the explorative, educational process.', //max characters 620
   	email: 'bryonytreagus19@gmail.com',
   	insta: '',
   	phone: '0834920774',
   	thesisLink: 'https://drive.google.com/file/d/1AznEb6OnmQPL0F969ijtrDT3pc1WgCGU/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Treagus_Bryony_Trans.png', // file_name ( must start with  )
   	color: 'red', // color of the background of someones profile image
   	previewedUrl: 'VCD_Treagus_1.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'VCD_Treagus_1.jpg',
           	caption: 'Bryony Treagus, *Omnom Board Game*. 2021. Computer Graphics.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'VCD_Treagus_2.jpg',
           	caption: 'Bryony Treagus, *Omnom Board Game*. 2021. Computer Graphics.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Treagus_3.jpg',
           	caption: 'Bryony Treagus, *Omnom Activation Poster: Join the Team*. 2021. Poster.',
           	price: ''
       	},
       	{
           	photoUrl: 'https://player.vimeo.com/video/653276745?h=05336c2d34',
           	caption: 'Bryony Treagus *Omnom Brand Identity*. 2021.',
           	price: '',
	isVideo: true
       	},
       	{
           	photoUrl: 'VCD_Treagus_5.jpg',
           	caption: 'Bryony Treagus, *Participatory Design Workshop: Fruit & Vegetable Stamps*. 2021. Photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Treagus_6.jpg',
           	caption: 'Bryony Treagus, *Participatory Design Cushion*. 2021. Fabric Design.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Treagus_7.jpg',
           	caption: 'Bryony Treagus, *Participatory Design Game: Children’s Drawings*. 2021. Digital Illustration.',
           	price: ''
       	},
{
           	photoUrl: 'VCD_Treagus_8.jpg',
           	caption: 'Bryony Treagus, *Thesis Book*. 2021. Book.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Treagus_9.jpg',
           	caption: 'Bryony Treagus, *Nudge Tactics Tuck shop*. 2021 Poster',
           	price: ''
       	}
   	]
   },

   {
   	name: 'Cornelia van der Merwe',
   	type: 'jewelry', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Erotic Education',
   	description: 'My body of work aims to consider how jewellery can be used as a tool for erotic expression and exploration. My artworks, which include jewellery, drawing and sculpture, attempts to publicise eroticism in a way that does not glorify shame or concealment, thereby starting discussions about pleasure, consent, and fulfilment. I believe that jewellery can be a helpful conversation starter in this regard, as an expression of the self that speaks in symbolism and association. A channel of communication between the wearer and the viewer is opened by an unassuming object.', //max characters 620
   	email: 'cmvdmerwe14@gmail.com',
   	insta: 'https://www.instagram.com/leetchilli/',
   	phone: '0726596703',
   	thesisLink: 'https://drive.google.com/file/d/1oUP1mhR2icv8pErp2UHu2qxff9VvQcTb/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'Van Der Merwe_Cornelia_Trans.png', // file_name ( must start with  )
   	color: 'red', // color of the background of someones profile image
   	previewedUrl: 'JD_vanderMerwe_5 - Lee van der Merwe.jpg', //file_name
   	fixHeight: true, //if preview photo is taller than wider, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'JD_vanderMerwe_1 - Lee van der Merwe.jpg',
           	caption: 'CM van der Merwe, *Bond*. 2021. Neckpiece. Ceramic and leather. 100x8cm.', //max characters 280
           	price: 'R600',
       	},
       	{
           	photoUrl: 'JD_vanderMerwe_2 - Lee van der Merwe.jpg',
           	caption: 'CM van der Merwe, *Stroke*. 2021. Neckpiece . Ceramic, sterling silver and cord. 50x10cm.',
           	price: 'R800'
       	},
       	{
           	photoUrl: 'JD_vanderMerwe_3 - Lee van der Merwe.jpg',
           	caption: 'CM van der Merwe, *Red fold*. 2021. Brooch. Leather, fabric, sterling silver and spinel. 8cm.',
           	price: 'R1000'
       	},
       	{
           	photoUrl: 'JD_vanderMerwe_4 - Lee van der Merwe.jpg',
           	caption: 'CM van der Merwe, *Blue folds*. 2021.Brooch. Leather and sterling silver. 10cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_vanderMerwe_5 - Lee van der Merwe.jpg',
           	caption: 'CM van der Merwe, *Tactilllis 1*. 2021. Pendants. Slip-cast porcelain. 12x5cm.',
           	price: 'R400'
       	},
       	{
           	photoUrl: 'JD_vanderMerwe_6 - Lee van der Merwe.jpg',
           	caption: 'SCM van der Merwe, *Delight or Shame*. 2021. Painting. Ink.',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_vanderMerwe_7 - Lee van der Merwe.jpg',
           	caption: 'CM van der Merwe, *Tactilllis 2*. 2021. Pendant. Slip-cast porcelain. 15x5cm.',
           	price: 'R400'
       	},
       	{
           	photoUrl: 'JD_vanderMerwe_8 - Lee van der Merwe.jpg',
           	caption: 'CM van der Merwe, *Opened up*. 2021. Set of pendant and earrings. 3D modelled and printed.',
           	price: 'R900'
       	},
       	{
           	photoUrl: 'JD_vanderMerwe_9 - Lee van der Merwe.jpg',
           	caption: 'CM van der Merwe, *Discipline*. 2021. Bamboo sticks. 3D modelled and printed.',
           	price: ''
       	},
       	{
           	photoUrl: 'JD_vanderMerwe_10 - Lee van der Merwe.jpg',
           	caption: 'JD_vandermerwe_10: CM van der Merwe, *Eggrogenous*. 2021. Sculptures. 3D modelled and printed.',
           	price: ''
       	},
   	]
   },

   {
   	name: 'Lisa van der Walt',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Human consciousness',
   	description: 'In the broadest sense my work this year is focused on exploring the intersection between art, science, philosophy, spirituality and the mystic. Through re-amplifying ancient indigenous wisdom and uncovering the secret promises of fungi, I aim to explore the role of art to transform individual as well as collective consciousness.', //max characters 620
   	email: 'lisavdw@me.com',
   	insta: 'https://www.instagram.com/projectmagik/',
   	phone: '0764224153',
   	thesisLink: 'https://drive.google.com/file/d/12BPg9sHNe2hfF9X-VSnXNyflblpINyYr/view?usp=sharing',
   	portfolio: 'https://www.behance.net/lisavanderwalt',
   	profileUrl: 'van der Walt_Lisa_Trans.png', // file_name ( must start with  )
   	color: 'red', // color of the background of someones profile image
   	previewedUrl: 'VCD_VdWalt_5 - Lisa Van der walt.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'VCD_VdWalt_1 - Lisa Van der walt.jpg',
           	caption: 'Lisa van der Walt, *As We Are, So We See*. Poster Series. 2021. Digital photography, water, ink.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'VCD_VdWalt_2 - Lisa Van der walt.jpg',
           	caption: 'Lisa van der Walt, “*As We Are, So We See*” Poster Series. 2021. Digital photography, water, ink.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_VdWalt_3 - Lisa Van der walt.jpg',
           	caption: 'Lisa van der Walt, *Synergy Series: Re-Harmonizing the Self*. 2021. Analogue photography, 35mm film.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_VdWalt_4 - Lisa Van der walt.jpg',
           	caption: 'Lisa van der Walt, *Animism as Normative Consciousness*. 2021. Infographic Poster Design.',
           	price: ''
       	},
       	{
           	photoUrl: 'https://player.vimeo.com/video/653281392?h=a459da3d99',
           	caption: 'Lisa van der Walt, *Magik Logo Animation*. 2021. Visual Identity Design.',
           	price: '',
	isVideo: true
       	},
       	{
           	photoUrl: 'VCD_VdWalt_7 - Lisa Van der walt.jpg',
           	caption: 'Lisa van der Walt, *Portraits Of Matter*. 2021. Digital Photography.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_VdWalt_5 - Lisa Van der walt.jpg',
           	caption: 'Lisa van der Walt, *Portraits Of Matter*. 2021. Digital Photography.',
           	price: ''
       	},
       	{
           	photoUrl: 'https://player.vimeo.com/video/653281275?h=d026371cb4',
           	caption: 'Lisa van der Walt, “*As We Are, So We See*” poster series. 2021. Animated Poster.',
           	price: '',
	isVideo: true
       	},
       	{
           	photoUrl: 'VCD_VdWalt_6 - Lisa Van der walt.jpg',
           	caption: 'Lisa van der Walt, *Organically Grown Mushroom Material Alphabet*. 2021. Oyster Mushroom, 30 x 42 cm.',
           	price: '',
       	},
       	{
           	photoUrl: 'https://player.vimeo.com/video/653281350?h=d8a37edf96',
           	caption: 'Lisa van der Walt, *Magik & Mushrooms*. 2021. Short documentary film.',
           	price: '', 
	isVideo: true
       	},
   	]
   },

   {
   	name: 'Alicia van Greuning',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Queer Theory',
   	description: `My work aims to explore queerness in its multidimensionality. Popular culture often moulds our understanding of queerness as it only accepts queer in its extreme forms. However, in my work, I aim to formulate an understanding between queer performance and queer identity. In addition to this, there is a sense of commodification to gender and sexual identity as LGBT experiences are packaged as a lifestyle and a trend rather than a social or political cause. Queer can be what someone ‘does’ and can exist separate from who one ‘is’. I also explore gender performativity as a social construct of our identities.`, //max characters 620
   	email: 'aliciaa.avg@gmail.com',
   	insta: 'https://www.instagram.com/aliciavgr_/',
   	phone: '',
   	thesisLink: 'https://drive.google.com/file/d/1RznKfRhqChKpXADubygd6XlUGwv7kblb/view?usp=sharing',
   	portfolio: 'https://indd.adobe.com/view/620cbd7a-48e9-4239-b572-047cafedae30',
   	profileUrl: 'Van Greuning_Alicia_Trans.png', // file_name ( must start with  )
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'VCD_van Greuning_3 - Alicia van Greuning.jpg', //file_name
   	fixHeight: true, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'VCD_van Greuning_1 - Alicia van Greuning.jpg',
           	caption: 'Alicia Van Greuning, *Bodee on display*. 2021. Photography, Stellenbosch University.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'VCD_van Greuning_2 - Alicia van Greuning.jpg',
           	caption: 'Alicia Van Greuning, *Bodee on display*. 2021. Photography, Stellenbosch University..',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_van Greuning_3 - Alicia van Greuning.jpg',
           	caption: 'Alicia Van Greuning, *Bodee on display*. 2021. Photography, Stellenbosch University.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_van Greuning_4 - Alicia van Greuning.jpg',
           	caption: 'Alicia Van Greuning, *Bodee on display*. 2021. Photography, Stellenbosch University.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_van Greuning_5 - Alicia van Greuning.jpg',
           	caption: 'Alicia Van Greuning, *Bodee on display*. 2021. Photography, Stellenbosch University.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_van Greuning_6 - Alicia van Greuning.jpg',
           	caption: 'Alicia Van Greuning, *Queer Clock: The Game*. 2021. Package Design, 20 cm x 30 cm. Stellenbosch University.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_van Greuning_7 - Alicia van Greuning.jpg',
           	caption: 'Alicia Van Greuning, *Queer Clock: The Game*. 2021. Package Design, 20 cm x 30 cm. Stellenbosch University..',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_van Greuning_8 - Alicia van Greuning.jpg',
           	caption: 'Alicia Van Greuning, *Presenting queerness through gender blurring fashion*. 2021. Digital Illustration. Stellenbosch University.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_van Greuning_9 - Alicia van Greuning.jpg',
           	caption: 'Alicia Van Greuning, *Presenting queerness through gender blurring fashion*. 2021. Digital Illustration. Stellenbosch University.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_van Greuning_10 - Alicia van Greuning.jpg',
           	caption: 'Alicia Van Greuning, *Presenting queerness through gender blurring fashion*. 2021. Digital Illustration. Stellenbosch University.',
           	price: ''
       	},
   	]
   },

   {
   	name: 'Chantelle van Heerden',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Segregation',
   	description: 'My work is based on the unheard and untold stories of students who have been impacted by some form of segregation within the student community of Stellenbosch University. It focuses on how a collaborative platform could create a safe space for students to share these stories and experiences.', //max characters 620
   	email: '',
   	insta: 'https://www.instagram.com/chantellevh_/',
   	phone: '0828803013',
   	thesisLink: 'https://drive.google.com/file/d/1EuLW0Kvrgazdo-Jy4AnNN9mqev4danfZ/view?usp=sharing',
   	portfolio: '',
   	profileUrl: 'van Heerden_Chantelle_Trans.png', // file_name ( must start with  )
   	color: 'red', // color of the background of someones profile image
   	previewedUrl: 'VCD_vanHeerden_1 - Chantelle van Heerden.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than wider, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'VCD_vanHeerden_1 - Chantelle van Heerden.jpg',
           	caption: 'Chantelle van Heerden. *Trace exploration*. 2021. Photographic collage.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'VCD_vanHeerden_2 - Chantelle van Heerden.jpg',
           	caption: 'Chantelle van Heerden. *Liquid trace*. 2021. Photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_vanHeerde_3 - Chantelle van Heerdenjpg',
           	caption: 'Chantelle van Heerden. *Stellenbosch University 33.9328° S, 18.8644° E*. 2021. Digital collage.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_vanHeerden_4 - Chantelle van Heerden.jpg',
           	caption: 'Chantelle van Heerden. *Traces initiative*. 2021. Logo.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_vanHeerden_5 - Chantelle van Heerden.jpg',
           	caption: 'Chantelle van Heerden. *Venda*. 2021. Photographs.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_vanHeerden_6 - Chantelle van Heerden.jpg',
           	caption: 'Chantelle van Heerden. *Verblind*. 2021. Photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_vanHeerden_7 - Chantelle van Heerden.jpg',
           	caption: 'Chantelle van Heerden. *1913 building*. 2021. Photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_vanHeerden_8 - Chantelle van Heerden.jpg',
           	caption: 'Chantelle van Heerden. *The abuse hotline*. 2021. Photograph',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_vanHeerden_9 - Chantelle van Heerden.jpg',
           	caption: 'Chantelle van Heerden. *What we hear in the shadows*. 2021. Scanner painting.',
           	price: ''
       	}
   	]
   },
   {
   	name: 'Mieke Verster',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Consumer Culture',
   	description: 'Overconsumption lies at the root of our planet’s environmental crisis – most things that constitute our constructed world can be linked to some sort of plundering of natural resources or exploitation of other beings. I was initially concerned with highlighting the link between our unconsidered consumption and ecological destruction, but as my initiative developed my focus shifted to the pervasive effects of consumer culture on our way of understanding the world and ourselves. I explore *why we buy* - how consumption culture trains us to be dissatisfied with ourselves and fuel the need to accumulate at any cost.', //max characters 620
   	email: 'mixverster@gmail.com',
   	insta: 'https://www.instagram.com/tannie_mieke/',
   	phone: '0834143342',
   	thesisLink: 'https://drive.google.com/file/d/1qEuP4iG56H7iQgSq16-pPlBW2PQRwo9s/view?usp=sharing',
   	portfolio: 'https://www.behance.net/miekeverster1',
   	profileUrl: 'Verster_Mieke_Trans.png', // file_name ( must start with  )
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'VCD_Verster_7 - Mix Verster.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than wider, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'VCD_Verster_1 - Mix Verster.jpg',
           	caption: `Mieke Verster, *Consider-It*. 2021. Boardgame Mockup.`, //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'VCD_Verster_2 - Mix Verster.jpg',
           	caption: 'Mieke Verster, *Consider-It*. 2021. Boardtop, 40x50 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Verster_3 - Mix Verster.jpg',
           	caption: 'Mieke Verster, *Pop in to the Shops*. 2021. Poster Design, 42x 59,4 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Verster_4 - Mix Verster.jpg',
           	caption: 'Mieke Verster, *Mieke Verster, *Rich Bitch Mentality*. 2021. Poster Design, 42 x 59,4 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Verster_5 - Mix Verster.jpg',
           	caption: 'Mieke Verster, *Buy happenis*. 2021. Poster Design, 42 x 59,4 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Verster_6  - Mix Verster.jpg',
           	caption: 'Mieke Verster, *You suck, buy more stuff*. 2021. Poster Design, 42 x 59,4 cm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Verster_7 - Mix Verster.jpg',
           	caption: 'Mieke Verster, *Considering Consumption*. 2021. Film Still.',
           	price: ''
       	},
{
           	photoUrl: 'https://player.vimeo.com/video/653286618?h=c9a1fe649f',
           	caption: 'Mieke Verster, *Considering Consumption*. 2021.',
           	price: '',
	isVideo: true
       	},
{
           	photoUrl: 'https://player.vimeo.com/video/653288557?h=189bb9ac42',
           	caption: 'Mieke Verster, *See Between the Lines*. 2021. Animated poster',
           	price: '',
	isVideo: true
       	},
{
           	photoUrl: 'https://player.vimeo.com/video/653288589?h=d8567dc8c9',
           	caption: 'Mieke Verster, *Fast Flashes *. 2021.',
           	price: '',
	isVideo: true
       	}
   	]
   },
   {
   	name: 'Pia Walters',
   	type: 'visual', // One of these three ONLY: jewelry, fine, visual
   	problem: 'Sustainability',
   	description: `*Dirt* is an initiative that aims at providing a new, sustainable and more optimistic view towards something that is usually undesired and under-appreciated: weeds. Through delicious methods of consuming weeds such as pesto, ice-tea and sorbet, I look at harmoniously creating a new position for weeds in today's Anthropocentric society. As weeds, my design work will serve for a better future of providing for nature’s biodiversity.`, //max characters 620
   	email: 'pia.walters@hotmail.co.za',
   	insta: 'https://www.instagram.com/dirtdesign.sa/',
   	phone: '0769156913',
   	thesisLink: 'https://drive.google.com/file/d/1ri08_pRVSKnftXB48bbrYpQqbEUSQGPg/view?usp=sharing',
   	portfolio: 'https://www.nirvanart.art',
   	profileUrl: 'Walters_Pia_Trans.png', // file_name ( must start with  )
   	color: 'pink', // color of the background of someones profile image
   	previewedUrl: 'VCD_Walters_3 - Pia Walters.jpg', //file_name
   	fixHeight: false, //if preview photo is taller than widder, then true. Otherwise false
    	photos: [
       	{
           	photoUrl: 'VCD_Walters_1 - Pia Walters.jpg',
           	caption: 'Pia Walters, *Foraging weeds*. 2021. Photograph.', //max characters 280
           	price: '',
       	},
       	{
           	photoUrl: 'VCD_Walters_2 - Pia Walters.jpg',
           	caption: 'Pia Walters, *Weeds poster series*. 2021. 3 Posters, 279 x 420mm each.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Walters_3 - Pia Walters.jpg',
           	caption: 'Pia Walters, *Dirty Borage*. 2021. Photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Walters_4 - Pia Walters.jpg',
           	caption: 'Pia Walters, *Weeds as Food cookbook cover mockup*. 2021. Cookbook, 195 x 260mm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Walters_5 - Pia Walters.jpg',
           	caption: 'Pia Walters, *Borage pesto pasta recipe page mockup*. 2021. Recipe page, 195 x 260mm.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Walters_6 - Pia Walters.jpg',
           	caption: 'Pia Walters, *Dirty hands*. 2021. Photograph.',
           	price: ''
       	},
       	{
           	photoUrl: 'VCD_Walters_7 - Pia Walters.jpg',
           	caption: 'Pia Walters, *Bristly Ox-Tongue*. 2021. Photograph.',
           	price: ''
       	},
{
           	photoUrl: 'VCD_Walters_8 - Pia Walters.jpg',
           	caption: 'Pia Walters, *Bristly Ox-Tongue*. 2021. Photograph.',
           	price: ''
       	},
{
           	photoUrl: 'https://player.vimeo.com/video/653284577?h=bd8d7457b3',
           	caption: 'Pia Walters, *Terrarium Embodiment*. 2021. Video paging through photographic booklet.',
           	price: '',
	isVideo: true
       	},
       	{
           	photoUrl: 'https://player.vimeo.com/video/653284605?h=08d86cf924',
           	caption: 'Pia Walters, *How to Identify Borage*. 2021. Video.',
           	price: '',
	isVideo: true
       	}
   	]
   },
]


export default artists