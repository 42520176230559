import React, { useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components'
import elem1 from '../elements/elem1.svg'
import elem2 from '../elements/elem2.svg'
import elem3 from '../elements/elem3.svg'
import elem4 from '../elements/elem4.svg'
import elem5 from '../elements/elem5.svg'
import elem6 from '../elements/elem6.svg'
import elem7 from '../elements/elem7.svg'
import elem8 from '../elements/elem8.svg'
import elem9 from '../elements/elem9.svg'
import elem10 from '../elements/elem10.svg'
import elem11 from '../elements/elem11.svg'
import elem12 from '../elements/elem12.svg'
import elem13 from '../elements/elem13.svg'
import elem14 from '../elements/elem14.svg'
import elem15 from '../elements/elem15.svg'
import styles from '../constants';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
`

const Box = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;
`

interface propTypes {
    clicked?: any
    index: number
    color: string
    hidden: boolean
    element: any
    shown: number
    secondShown: number
    found: any
    mobileOpen: boolean
}
function MatchBox(props: propTypes) {

    return (
       
                    <Box
                        id={'box'}
                        onClick={props.clicked ? () => props.clicked(props.index) : () => {} }
                        style={{
                            background: props.color, 
                            display: props.hidden ? 'none' : 'inherit',
                            width: props.mobileOpen ? 'calc(100% / 3)' : 'calc(100% / 7)',
                            height: props.mobileOpen ? 'calc(100% / 7 )' : 'calc(100% / 3 )',
                            cursor: props.element ? 'pointer' : 'default'
                        }}
                    >
                        {
                            props.element &&
                                <img style={{height: '50%', opacity: props.shown === props.index || props.secondShown === props.index || props.found.indexOf(props.index) !== -1 ? 1 : 0}} src={props.element} />
                        }
                    </Box>
          
    );
}

export default MatchBox;