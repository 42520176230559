import React, { useEffect, useRef, useContext } from 'react';
import styled from 'styled-components'
import { mainStore } from '../store/MainStore';
import styles from '../constants';
import CustomButton from '../components/CustomButton';

const Container = styled.div`
    width: 100%;
    scroll-snap-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 30px;
`

const HeadingContainer = styled.div`
    width: 100%;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
`

const ButtonsContainer = styled.div`
    width: 100%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
`

const ButtonHolder = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
`

const FooterContainer = styled.h2`
    width: 100%;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: ${styles.pink};
    font-size: 90px;
    font-weight: bold;
`


interface propTypes {
    setArtist: any
}
function AboutPage(props: propTypes) {
    const {state, dispatch} = useContext(mainStore)


    return (
        <Container
            style={{
                height: state.mobileOpen ? 'auto' : state.height,
            }}
        >
            <div style={{height: '100px'}} />

            <HeadingContainer
                style={{
                    flexDirection: state.mobileOpen ? 'column' : 'row'
                }}
            >

                <h2 style={{display: 'flex', flexShrink: 0,  justifyContent: 'center', width: '40%', fontSize: state.mobileOpen ? '1.5em' : '2em'}}>
                    <div>
                        <div style={{color: styles.headingColor, fontWeight: 'bold',}}>what is</div>
                        <div style={{color: styles.pink, fontWeight: 'bold',}}>GradeX</div>
                    </div>
                </h2>

                <div style={{display: 'flex', flexGrow: 0, justifyContent: 'center', width: '60%'}}>
                    <div 
                        style={{
                            width: state.mobileOpen ? '100%' : 'calc(100% - 100px)', 
                            color: styles.headingColor, 
                            fontSize: state.mobileOpen ? '0.4em' : '0.5em',
                            fontFamily: 'Montserrat'
                        }}>
                        Gradex is an annual exhibition curated and created by the graduating class of the Stellenbosch Visual Arts department. It allows the students from each of the three streams to showcase and celebrate their work.
                        
                        <div style={{height: '10px'}}></div>

                        The class of 2021 is a particularly special class for the Visual Art Department. Aside from completing their degrees in a non-traditional manner - a mix of online and physical works, the class also represents the first group of students who started their academic journey together - rather than immediately divided by specialisation. Each student chose their own issue to address and explore through their work, thus allowing a wide variety of subject matter in the exhibition.
                    </div>

                </div>
            </HeadingContainer>

            <ButtonsContainer
                style={{
                    flexDirection: state.mobileOpen ? 'column' : 'row',
                    height: state.mobileOpen ? 'auto' : '200px',
                    marginTop: state.mobileOpen ? '20px' : '0px',
                }}
            >
                <ButtonHolder
                    style={{
                        width: state.mobileOpen ? '100%' : 'calc(100% / 3)',
                        marginBottom: state.mobileOpen ? '20px' : '0px'
                    }}
                >
                    <CustomButton clicked={() => props.setArtist('', 'jewelry')}>JEWELLRY DESIGN</CustomButton>
                </ButtonHolder>

                <ButtonHolder
                    style={{
                        width: state.mobileOpen ? '100%' : 'calc(100% / 3)',
                        marginBottom: state.mobileOpen ? '20px' : '0px'
                    }}
                >
                    <CustomButton clicked={() => props.setArtist('', 'fine')}>FINE ARTS</CustomButton>
                </ButtonHolder>

                <ButtonHolder
                    style={{
                        width: state.mobileOpen ? '100%' : 'calc(100% / 3)',
                        marginBottom: state.mobileOpen ? '20px' : '0px'
                    }}
                >
                    <CustomButton clicked={() => props.setArtist('', 'visual')}>VISUAL COMMUNICATION DESIGN</CustomButton>
                </ButtonHolder>

            </ButtonsContainer>

            <FooterContainer
                style={{
                    height: 'auto',
                    marginBottom: state.mobileOpen ? '10px' : '0px',
                
                    fontSize: state.mobileOpen ? '1em' : '2em'
                }}
            >
                We push boundaries
            </FooterContainer>
            
        </Container>
    );
}

export default AboutPage;