import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components'
import styles from '../constants';
import test1 from '../resources/test1.jpg'
import { mainStore } from '../store/MainStore';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


const GridItemCover = styled.div`
    z-index: 2;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    cursor: pointer;
    display: flex;
    flex-grow: 0;
    flex-direction: column;
    align-items: center;
    text-align: center;
    &:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0px;
        left: 0px;
        background: ${styles.red};
        opacity: 0.7;
        z-index: 2;
    }
`

const Item = styled.div`
    position: relative;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    overflow: hidden;
    &:hover ${GridItemCover} {
        opacity: 1;
    };
`



const CoverHeading = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: flex-end;
    height: calc(50% - 11.5px);
    margin-bottom: 10px;
    font-weight: bold;
    color: white;
    z-index: 3;
`

const CoverContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: calc(50% - 11.5px);
    margin-top: 10px;
    font-weight: bold;
    color: white;
    z-index: 3;
    text-align: center;
`


const GridPhotoContainer = styled.div`
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-grow: 0;
    overflow: hidden;
    justify-content: center;
    align-items: center;
`

const GridPhoto = styled.img`
    position: relative;

    padding: 0px;
    display: inline-block;
   
`

interface propTypes {
    photo?:string
    clicked?: any
    index?: number
    children?: any
    name?: string
    type?: string
    fixHeight?: boolean
    noDisplay? : boolean
    setPhotoHeight? :any
    photoHeight: number
    temp?: string
    color?: string
    profile?: boolean
    problem?: string
}

function GridItem(props: propTypes) {

    return (
        
            props.photo ?

            <Item 
                id="itemContainer"
                style={{
                    //opacity: props.noDisplay ? 0 : 1,
                    height: '100%',
                    background: props.profile ? props.color : 'transparent',
                    width:'100%',
                }}
                onClick={props.clicked ? props.clicked : undefined}
            >
                
                        <GridItemCover
                            style={{
                            }}
                        >
                            <CoverHeading
                                style={{
                                    fontSize: '0.8em',

                                }}
                            >{props.problem}</CoverHeading>
                            <div style={{width: 'calc(100% - 50px)', zIndex: 3, height: '3px', background: 'white',}}></div>
                            <CoverContent
                                style={{
                                    fontSize: '0.7em',
                                    fontFamily: 'Montserrat'
                                }}
                            >
                                {props.name}
                                <div style={{fontWeight: 'normal', fontSize: '0.7em', marginTop: '5px', fontFamily: 'Montserrat'}}>
                                    {
                                        props.type === 'jewelry' ?
                                            "JD"
                                            :
                                            props.type === 'fine' ?
                                                "FA"
                                                :
                                                props.type === 'visual' ?
                                                "VCD"
                                                :
                                                ''
                                    }
                                </div>
                            </CoverContent>

                        </GridItemCover>   
                                      
                   
                            {/*
                            <GridPhoto

                                crossOrigin="" 
                                
                                style={{
                                    width: props.fixHeight ? 'auto' : '100%',
                                    height: props.fixHeight ? '100%' : 'auto',
                                }}
                                src={props.photo} 

                            />*/}
    
                <img
                    //crossOrigin="" 
                    //effect="blur"
                    src={props.photo} 
                    height={props.fixHeight ? '100%' : '100%'}
                    style={{
                        height: '100%',
                        width: '100%',
                        objectFit: props.profile ? 'cover' : 'cover',
                    }}
                />
                        
                            
                                    
            </Item>
            :
            <Item 
                id="itemContainer"
                style={{
                    gridColumnStart: 1,
                    gridColumnEnd: 3,
                    height: props.photoHeight,
                }}
            >
                {
                    props.children
                }
            </Item>

        
               
    );

    
}

export default GridItem;