import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components'
import styles from '../constants';
import { mainStore } from '../store/MainStore';
import GridItem from "./GridItem";

const Container = styled.div`
    top: 0px;
    width: 100%;
    flex-shrink: 0;
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
`
//    height: 59px;


interface propTypes {
    elements:any
    columns: number
    clicked?: any
    customStart?: any
    setPhotoHeight?: any
    width: number
    profile: boolean
}
function Grid(props: propTypes) {
    const {state, dispatch} = useContext(mainStore)

    return (
        <Container
            id="gridContainer"
            style={{
                //gridTemplateColumns: `repeat(${state.mobileOpen ? 2 : 4}, calc(100% / ${state.mobileOpen ? 2 : 4}) )`,
            }}
        >

            {
                props.customStart &&
                    <div style={{
                        height:  state.mobileOpen ? props.width/2 : props.width/4,
                        width: state.mobileOpen ? '100%' : '50%',
                    }}>
                
                        <GridItem
                            photoHeight={state.mobileOpen ? props.width/2 : props.width/4}
                            
                        >

                            {props.customStart}
                        </GridItem>
                    </div>
            }
            {
                
                props.elements.map((prop: any, key: number) =>
                    prop &&
                        <div style={{
                           // height: prop.noDisplay ? '0px' : (state.mobileOpen ? props.width/2 : props.width/4),
                            opacity: prop.noDisplay ? 0 : 1,
                            height:  prop.noDisplay ? '0px' : (state.mobileOpen ? props.width/2 : props.width/4),
                            width: prop.noDisplay ? '0px' : state.mobileOpen ? '50%' : '25%',
                            transition: 'opacity 0.3s ease, width 0s ease 0.3s, height 0s ease 0.3s' ,
                        }}>
                            <GridItem 
                                name={prop.name}
                                problem={prop.problem}
                                type={prop.type}
                                color={prop.color}
                                photo={props.profile ? prop.profileUrl : prop.previewedUrl}
                                temp={props.profile ? prop.tempProfileUrl : prop.tempPreviewedUrl}
                                clicked={() => props.clicked(prop)}
                                index={key}
                                key={key}
                                profile={props.profile}
                                fixHeight={'fixHeight' in prop ? prop.fixHeight : false}
                                noDisplay={'noDisplay' in prop ? prop.noDisplay : false}
                                photoHeight={state.mobileOpen ? props.width/2 : props.width/4}
                            />
                        </div>

                )
                
            } 

        </Container>
    );
    
}

export default Grid;