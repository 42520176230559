import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components'
import Slide from './Slide'
const Container = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    top: 0px;
    zIndex: 1;
`


interface propTypes {
    slides: Array<any>
    index: number
    open: boolean
    menuOpen?: boolean
    browser: string
}
function SlideContainer(props: propTypes) {
    const [open, setOpen] = useState(false)
    const [index, setIndex] = useState(0)
    const [previousIndex, setPreviousIndex] = useState(0)

    useEffect(() => {

        let timer1:any = null

        if(open && !props.open ) {
            timer1 = setTimeout(() => {
                
                setOpen(false); 
                setPreviousIndex(index)
                setIndex(props.index)
            }, 500)
        }
        else {
            console.log(index)
            console.log(props.index)
            console.log(" ")
            setPreviousIndex(index)
            setIndex(props.index)
            timer1 = setTimeout(() => setOpen(props.open), 4)

        }
        

      
        return () => {
            clearTimeout(timer1)
        }

    }, [props.open, props.index])

    return (
        <Container
            style={{
                opacity: open ? 1 : 0
            }}
        >
            {
                
                props.slides.map((prop, key) => 
                    <Slide
                        key={key}
                        index={key}
                        openIndex={index}
                        slidesLength={props.slides.length}
                        open={open}
                        menuOpen={props.menuOpen || false}
                        browser={props.browser}
                        previousIndex={previousIndex}
                    >
                        {prop}
                    </Slide>
                )
            }

        </Container>
    );
}

export default SlideContainer;