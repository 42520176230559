import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components'
import { mainStore, MainStateProvider } from '../store/MainStore'
import styles from '../constants'
import SlideContainer from './SlideContainer';
import ArtistInfo from '../pages/ArtistInfoPage';

const Container = styled.div`
    position: absolute;
    z-index: 13;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    will-change: transform;

`



interface propTypes {
}


function ArtistContainer(props: propTypes) {
    const {state, dispatch} = useContext(mainStore)
    const [open, setOpen] = useState(true)


    const [artistTypeIndex, setArtistTypeIndex] = useState(0)


    useEffect(() => {
            if(state.artistOpen !== '') {
                //console.log('hello'+state.artistOpen)

                for(let i = 0 ; i < state.allArtists.length; i++) {
                    if(state.allArtists[i].name === state.artistOpen) {
                        setArtistTypeIndex(i)
                        setOpen(true)
                        return
                    }
                }

                //console.log(artistTypeIndex)
            }
            setOpen(false)
            

        

    }, [state.artistOpen])


    return (
        <Container
            style={{
                transform: open ? 'translateY(0%)' : 'translateY(100%)',
                transition: !state.menuOpen ?  'transform 0.5s ease' : 'transform 0s',
                willChange: 'transform'
            }}
        >
                
                <SlideContainer
                    slides={
                        state.allArtists.map((prop:any, key: number) => { 
                            return <ArtistInfo key={key} artist={prop} open={open} index={key} />
                        })
                    }
                    open={open}
                    index={artistTypeIndex}
                    menuOpen={state.menuOpen}
                    browser={state.browser}

                />
                
            
        </Container>
    );
}

export default ArtistContainer;